export const isCharNumeric = (charStr: any, separatorDecimal?: string) => {
  if (separatorDecimal === '.') {
    const regex = /^-?\d*\.?\d*$/
    return regex.test(charStr)
  }
  else {
    const regex = /^-?\d*[.,]?\d*$/
    return regex.test(charStr)
  }
}

export const containsAlreadySeparator = (charStr: string, valueInput: string) => {
  if (charStr === '.' || charStr === ',') {
    if (valueInput.includes(',') || valueInput.includes('.'))
      return true
    else
      return false
  }
  return false
}

export const isFirstCharAuthorized = (charStr, valueInput) => {
  if (valueInput.length === 0) {
    if (charStr === ',' || charStr === '.')
      return false
    else
      return true
  }
  return true
}

const isKeyPressedNumeric = (event: any, valueInput: string) => {
  const charStr = event.key
  return isCharNumeric(charStr) && !containsAlreadySeparator(charStr, valueInput) && isFirstCharAuthorized(charStr, valueInput)
}

// Implementing ICellEditorComp
export default class NumericCellEditor {
  focusAfterAttached: any
  eInput: any
  params: any
  cancelBeforeStart: any
  // gets called once before the renderer is used
  init(params: any) {
    // we only want to highlight this cell if it started the edit, it is possible
    // another cell in this row started the edit
    this.params = params

    params.value = params.value || ''

    this.focusAfterAttached = params.cellStartedEdit
    // create the cell
    this.eInput = document.createElement('input')
    this.eInput.setAttribute('data-cy', this.getStepType())
    // this.eInput.setAttribute('data-cy-row', this.getStepType())
    // this.eInput.setAttribute('data-cy-column', this.getStepType())
    this.eInput.type = 'number'
    this.eInput.style.width = '100%'
    this.eInput.style.height = '100%'
    this.eInput.value
      = params.charPress && isCharNumeric(params.charPress) && !containsAlreadySeparator(params.charPress, params.value)
        ? params.charPress
        : params.value

    this.eInput.id = `cell_input_${params?.colDef?.colId}_${params?.rowIndex}`
    this.eInput.addEventListener('keypress', (event: any) => {
      if (isKeyPressedNumeric(event, this.eInput.value))
        this.eInput.focus()

      else
        event.preventDefault()
    })
  }

  private getStepType() {
    const { type } = this.params?.data
    return type?.toLowerCase() || 'none'
  }

  // gets called once when grid ready to insert the element
  getGui() {
    return this.eInput
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached() {
    // only focus after attached if this cell started the edit
    if (this.focusAfterAttached)
      this.eInput.focus()
    // this.eInput.select()
  }

  // returns the new value after editing
  isCancelBeforeStart() {
    return this.cancelBeforeStart
  }

  // example - will reject the number if it contains the value 007
  // - not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    if (this.eInput.value[0] === '0' && this.eInput.value.length > 1) {
      if (this.eInput.value[1] !== '.' && this.eInput.value[1] !== ',')
        this.eInput.value = this.eInput.value.slice(1)
    }
    if (this.eInput.value[0] === '.' || this.eInput.value[0] === ',')
      this.eInput.value = this.eInput.value.slice(1)
    if (this.eInput.value.length > 0 && this.eInput.value !== '' && this.params?.data?.isDecimalsEnabled)
      this.eInput.value = this.eInput.value.replace(',', '.')
    else if (this.eInput.value.length > 0)
      this.eInput.value = Number(this.eInput.value.replace(',', '.'))
    return false
  }

  // returns the new value after editing
  getValue() {
    return this.eInput.value.replace(',', '.')
  }

  // when we tab onto this editor, we want to focus the contents
  focusIn() {
    const eInput = this.getGui()
    eInput.focus()
    eInput.select()
  }

  // when we tab out of the editor, this gets called
  focusOut() {
    // but we don't care, we just want to print it for demo purposes
  }
}
