import DbHelper from '~/helpers/dbHelper'
import { NotificationState } from '~/models/notifications/jNotification'
import type { JNotification, NotificationType } from '~/models/notifications/jNotification'
import { siteStore } from '~/store/site'
import type { JUser } from '~/models/users/JUser'
import api from '~/helpers/ApiHelper'
import type { NotificationApiModel, NotificationReportInfo, NotificationViewModel, NotificationViewRawModel } from '~/common/models/notification'
import AlertController from '~/controllers/alerts'
import { formatDate, getRelativeTime } from '~/utils/formatter'
import { i18n } from '~/modules/i18n'
import { usersStore } from '~/store/users'
import { NOTIFICATIONS_COLLECTION_NAME } from '~/common/config/firebase/storage'
import { notificationStore } from '~/store/notifications'

const dbHelper = new DbHelper()
const { t } = i18n.global

interface updateNotificationForm {
  notificationId: string
  state: NotificationState
}

class NotificationViewModelMapper {
  static async apiModelToViewModel(notificationModel: NotificationApiModel): Promise<NotificationViewModel> {
    const assignees = usersStore().filterUsers(notificationModel.assignee_ids)
    const users = usersStore().users
    const createdBy = users.find(e => e.id === notificationModel.created_by)!
    const updatedBy = users.find(e => e.id === notificationModel.updated_by)!
    const { unit: createdAtRelativeUnit, value: createdAtRelativeValue } = getRelativeTime(notificationModel.created_at as number)
    const { unit: updatedAtRelativeUnit, value: updatedAtRelativeValue } = getRelativeTime(notificationModel.updated_at as number)

    const notification: NotificationViewModel = {
      id: notificationModel.id,
      type: notificationModel.type,
      date: notificationModel.date,
      reportInfo: notificationModel.report_info as NotificationReportInfo,
      clientId: notificationModel.client_id,
      siteId: notificationModel.site_id,
      createdBy: `${createdBy?.first_name} ${createdBy?.last_name}`,
      updatedBy: `${updatedBy?.first_name} ${updatedBy?.last_name}`,
      createdAt: formatDate(notificationModel.created_at),
      createdAtRelative: t(`dates.${createdAtRelativeUnit}`, { amount: createdAtRelativeValue }, createdAtRelativeValue),
      updatedAt: formatDate(notificationModel.updated_at),
      updatedAtRelative: t(`dates.${updatedAtRelativeUnit}`, { amount: updatedAtRelativeValue }, updatedAtRelativeValue),
      assignees: assignees.map(({ first_name, last_name }) => `${first_name} ${last_name}`),
      status: notificationModel.status,
      cellInfo: notificationModel.cell_info,
      path: notificationModel.path,
      cells: notificationModel.cells,
    }

    if (notificationModel.alert_id) {
      try {
        notification.alert = await AlertController.getAlertById(notificationModel.alert_id)
      }
      catch (e) {
        notification.alert = undefined
      }
    }

    return notification
  }
}

class NotificationController {
  isNotificationTypeEnabled = (notificationType: NotificationType) => {
    return siteStore().hasFlag({
      flagField: 'notifications',
      flagType: notificationType,
    })
  }

  createNotification = async (notification: any /* NotificationViewRawModel */) => {
    try {
      if (!this.isNotificationTypeEnabled(notification.type))
        return

      const createdNotification = await api.createNotification(notification)

      return createdNotification.id
    }
    catch (e) {
      console.error('Cannot create notification', e)
    }
  }

  updateNotificationState = async (notification: NotificationViewRawModel, updateNotificationForm: updateNotificationForm) => {
    const { notificationId, state } = updateNotificationForm

    try {
      await api.updateNotificationById(notificationId, {
        status: {
          ...notification.status,
          state,
        },
      })
    }
    catch (e) {
      console.error(`Cannot update notification ${notificationId}`)
    }
  }

  bulkUpdateNotificationState = async (notifications: JNotification[]) => {
    try {
      await api.updateNotificationByIds({
        notificationsIds: notifications.map(e => e.id),
      })
    }
    catch (e) {
      console.error('Cannot update notifications')
    }
  }

  flushNotificationsOnReport = async (reportId: string) => {
    try {
      await api.deleteNotification({ id: reportId, onReport: true })
    }
    catch (e) {
      console.error(`Cannot delete notification on report ${reportId}`)
    }
  }

  setupNotification = (user: JUser) => {
    dbHelper.setupCollectionSnapshot({
      collectionName: NOTIFICATIONS_COLLECTION_NAME,
      checks: [
        {
          field: 'assignee_id',
          compare: '==',
          value: user.id,
        },
        {
          field: 'client_id',
          compare: '==',
          value: user.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: user.site_id,
        },
        {
          field: 'status.state',
          compare: 'in',
          value: [
            NotificationState.TO_SEND,
            NotificationState.SENT,
          ],
        },
      ],
      callback: async (notifications: JNotification[]) => {
        notifications.forEach((notification: JNotification) => {
          if (!notification.cells)
            return

          notification.cells.forEach((item) => {
            if (item.cellInfo.status === NotificationState.TO_SEND)
              item.cellInfo.status = NotificationState.SENT
          })

          if (notification.cells?.some(item => item.cellInfo.status == NotificationState.SENT))
            notification.status.state = NotificationState.SENT
          else if (notification.cells.every(item => item.cellInfo.readAt || item.cellInfo.status === NotificationState.READ))
            notification.status.state = NotificationState.READ
        })

        await dbHelper.batchUpdate(NOTIFICATIONS_COLLECTION_NAME, notifications)
        const constraints: any = {
          where_constraints: [
            { field: 'assignee_id', compare: '==', value: user.id },
            { field: 'client_id', compare: '==', value: user.client_id },
            { field: 'site_id', compare: '==', value: user.site_id },
            { field: 'status.state', compare: '==', value: NotificationState.SENT },
            /* { field: 'alert.status', compare: '!=', value: 'finished' }, */
          ],
        }

        const updatedNotifications = await dbHelper.getAllDataFromCollectionWithAll(NOTIFICATIONS_COLLECTION_NAME, constraints)

        try {
          const updatedParsedNotifications = await Promise.all(updatedNotifications.map(async (notification: NotificationApiModel) => {
            const parsedNotification = await NotificationViewModelMapper.apiModelToViewModel(notification)

            return parsedNotification
          }))

          notificationStore().updateNotifications(updatedParsedNotifications)
        }
        catch (e) {
          console.error(e)
        }
      },
    })
  }
}

const notificationController = new NotificationController()

export default notificationController
