export enum CustomInput {
  DatePicker = -8,
}

export enum inputType {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum TableIconsType {
  LEFT = 'left_icon',
  RIGHT = 'right_icon',
  END = 'extreme_right_icon',
}

export enum ModalType {
  success = 'success',
  error = 'error',
}
export type ModalTheme = {
  [key in ModalType]: themeModalDynamic
}

export interface themeModalDynamic {
  button: string
  text: string
  icon: string
  displayConfirm: boolean
}

export const NO_GROUPING = 'no_grouping'
