import StateMachine from 'javascript-state-machine'
import StateMachineHistory from 'javascript-state-machine/lib/history'
import { getUserById } from './authentication/index'
import { WORKFLOW_COLLECTION_NAME } from '~/config/storage'
import DbHelper from '~/helpers/dbHelper'
import { usersStore } from '~/store/users'
import { formatDate } from '~/utils/formatter'
import { JWorkflow } from '~/models/workflows/jWorkflow'
import { removeInstance } from '~/utils/object'

const dbHelper = new DbHelper()

export const getWorkflowById = async (workflowId: string): Promise<JWorkflow | null> => {
  const workflow = await dbHelper.getDocFromCollection(WORKFLOW_COLLECTION_NAME, workflowId)

  return workflow
}

export const getWorkflows = async () => {
  const currentUser = usersStore().user

  let workflows = await dbHelper.getAllDataFromCollectionWithAll(WORKFLOW_COLLECTION_NAME, {
    where_constraints: [
      {
        field: 'client_id',
        compare: '==',
        value: currentUser.client_id,
      },
      {
        field: 'site_id',
        compare: '==',
        value: currentUser.site_id,
      },
    ],
  })

  workflows = workflows.map((workflow) => {
    const user = getUserById(workflow.created_by)
    workflow.created_at = formatDate(workflow.created_at)
    workflow.created_by = `${user?.first_name} ${user?.last_name}`

    return workflow
  })

  return workflows
}

export const createWorkflow = async (options) => {
  const user = usersStore().user
  const workflow = new JWorkflow({
    name: options.name,
    client_id: user.client_id,
    site_id: user.site_id,
    created_by: user.id,
    created_at: new Date().getTime(),
    transitions: options.transitions,
    steps: options.steps,
    type: options.type,
  })
  const rawWorkflow = removeInstance(workflow)
  const data = await dbHelper.addDataToCollection(WORKFLOW_COLLECTION_NAME, rawWorkflow)
  return data
}

export const editWorkflow = async (workflowId, options) => {
  const user = usersStore().user
  const workflow = {
    name: options.name,
    updated_at: new Date().getTime(),
    updated_by: user.id,
    transitions: options.transitions,
    steps: options.steps,
  }

  await dbHelper.updateDataToCollection(WORKFLOW_COLLECTION_NAME, workflowId, workflow)
}

export const getStateMachineFromWorkflow = ({ workflow, initState }) => {
  if (!workflow)
    return

  const WorkflowStateMachine = StateMachine.factory({
    init: initState || 1,
    transitions: workflow?.transitions,
    plugins: [
      new StateMachineHistory(),
    ],
  })

  const wsm = new WorkflowStateMachine()

  return wsm
}

export const getSuperiorStatuses = (workflow: JWorkflow, status: number) => {
  const transitionIndex = workflow?.transitions.findIndex(t => Number(t.to) === status)
  if (transitionIndex === -1)
    return []
  const superiorStatuses = workflow?.transitions.slice(transitionIndex).map(e => Number(e.to))
  return superiorStatuses
}
