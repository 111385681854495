<script setup lang="ts">
import uniqid from 'uniqid'
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { getLastUpdatedByOnCell, isMultipleAnswers, onHistoryClick } from '~/helpers/UtilsHelper'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
}>()

const route = useRoute()

const id = uniqid('id-')

const state = reactive({
  valRadio: props.params?.value,
  isNotApplicable: props.params?.data?.details.is_not_applicable,
  isActivated: true,
  isOk: props.isCleared ? false : props.params?.value === true,
  isNok: props.isCleared ? false : props.params?.value === false,
  isNA: props.isCleared ? false : props.params?.value === 'NA',
  refName: `cell_inactive_div_${props?.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  colId: props?.params?.colDef?.colId,
})

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  const { data, colDef } = props.params

  if (data?.isDisabled || colDef?.disabledStepIds?.includes(data?.stepId))
    return false

  return colDef
    ? props.params?.colDef?.editable(props.params, false)
    : props.isCleared
})

const activateCell = () => {
  if (isHistory.value || !isEditable.value)
    return
  state.isActivated = true
}

const getColor = () => {
  if ((state.isOk || state.isNok || state.isNA) && isEditable.value)
    return 'bg-[#1890ff]'
  else if ((state.isOk || state.isNok || state.isNA) && !isEditable.value)
    return 'bg-grey-300'
}

let tempWord = null
if ([true, false, 'NA'].includes(props.params?.value))
  tempWord = 'word'
state.isActivated = props.params?.data?.details
  ? setValueOrNA(tempWord, props.params)
  : state.isActivated

const onclick = (value: string) => {
  if (isHistory.value || !isEditable.value)
    return
  const remainingEvents = ['isOk', 'isNok', 'isNA'].filter(e => e !== value)
  state[value] = !state[value]
  if (state[value] === false) {
    state[value] = false
  }
  else {
    state[value] = true
    remainingEvents.forEach((e: any) => (state[e] = false))
  }

  let fixValue: null | boolean | string = null

  if (state.isOk)
    fixValue = true
  else if (state.isNok)
    fixValue = false
  else if (state.isNA)
    fixValue = 'NA'

  state.valRadio = fixValue
  props.params?.node?.setDataValue(props.params?.column?.colId, fixValue)
  emit('setNewValue', fixValue)
}
</script>

<template>
  <div v-if="!props.params.data.hidden">
    <div
      v-if="state.isActivated"
      class="flex gap-2"
      :class="`${$props.params?.details && 'justify-center items-center'}`"
    >
      <div
        v-if="isMultipleAnswers(props.params) && props.params?.data?.isHistoryEnabled"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click="onHistoryClick(props.params)"
      >
        <component
          :is="Icons.DATA_BACKUP"
          class="text-grey-500 w-5 h-5 cursor-pointer"
        />
      </div>
      <div
        v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 bottom-1"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          hide-full-name
          display-amount
          is-enable-modal
        />
      </div>
      <div
        class="multi-toggle-container"
        :class="{
          error: props.isError,
        }"
        data-cy="cell-radio"
        :data-cy-column="
          props.params?.colDef?.index
            - props.params?.data?.details?.initialShiftIndex
        "
      >
        <div
          data-cy="cell-radio-button"
          :class="`Content ${state.isOk && getColor()}`"
          @click.stop="onclick('isOk')"
        >
          <label
            :id="id"
            :class="`${
              state.isOk ? 'text-white' : 'text-primary labelRadio'
            } text-base`"
          >Ok
          </label>
        </div>
        <div class="Line" />
        <div
          data-cy="cell-radio-button"
          :class="`Content ${state.isNok && getColor()}`"
          @click.stop="onclick('isNok')"
        >
          <label
            :id="id"
            :class="`${
              state.isNok ? 'text-white' : 'text-primary labelRadio'
            } text-base`"
          >NOk</label>
        </div>
        <div
          v-if="state.isNotApplicable"
          data-cy="cell-radio-button"
          class="Line"
        />
        <div
          v-if="state.isNotApplicable"
          :class="`Content ${state.isNA && getColor()}`"
          @click.stop="onclick('isNA')"
        >
          <label
            v-if="state.isNotApplicable"
            :id="id"
            :class="`${
              state.isNA ? 'text-white' : 'text-primary labelRadio'
            } text-base`"
          >N/A</label>
        </div>
      </div>
    </div>
    <div
      v-else
      :id="state.refName"
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss">
.checkbox-round {
  width: 0.9em;
  height: 0.9em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  box-shadow:
    0px 0px 0px 0.5px #116bd2,
    inset 0px 0px 0px 0.14em white;
  background-color: #116bd2;
}

.multi-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  border: solid 1.5px #dee2ea;
  background-color: #fff;

  &.error {
    @apply border-solid border border-red-500;
  }
}

.Line {
  width: 1px;
  height: 1.5rem;
  margin: 0 1.5px;
  transform: rotate(-360deg);
  background-color: #dee2ea;
}

.Content {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  border-radius: 3px;
}

.labelRadio {
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}
</style>
