import { defineStore } from 'pinia'
import _ from 'lodash'
import { apiStore } from './api'
import { ApplicationFieldType } from '~/models/documents/jDocument'

export const productsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'products',
  // a function that returns a fresh state
  state: () => ({
    products: [] as any[],
  }),

  // optional getters
  getters: {
    getProducts(): any {
      return this.products
    },
  },
  // optional actions
  actions: {
    async loadProducts(forceUpdate = false): Promise<any> {
      const api = apiStore().getApiClient
      let products = await api.getProducts(forceUpdate, null)

      products = products.map((p: any) => {
        p.label = `${p.id} - ${p.name}`
        p.type = ApplicationFieldType.product
        return p
      })

      products = _.uniqBy(products, (e: any) => e.id)

      this.products = products
    },
    findProductById(id: string) {
      return this.products.find((product: any) => product?.id === id)
    },
    findProductByName(name: string) {
      return this.products.find((product: any) => product?.name === name)
    },
  },
})
