import { defineStore } from 'pinia'
import _ from 'lodash'
import { usersStore } from './users'
import { apiStore } from '~/store/api'
import { ApplicationFieldType } from '~/models/documents/jDocument'

export const workplaceStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'workplaces',
  // a function that returns a fresh state
  state: () => ({
    currentWorkplace: null,
    workplaces: [] as any[],
    workplacesHistory: [] as any[],
  }),

  // optional getters
  getters: {
    getWorkplaces(): any {
      return this.workplaces
    },

    getWorkplacesHistory(): any {
      return this.workplacesHistory
    },
  },
  // optional actions
  actions: {
    async loadWorkplaces(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      let workplaces = await api.getWorkplaces(forceFetch)

      workplaces.map((p: any) => {
        p.label = `${p.id} - ${p.name}`
        p.type = ApplicationFieldType.workplace
        return p
      })

      workplaces = _.uniqBy(workplaces, (e: any) => e.id)

      let workplacesHistory = usersStore().user?.configuration?.visited_workplaces ?? []

      if (workplacesHistory.length) {
        workplacesHistory = workplacesHistory.map((e) => {
          const findWorkplace = workplaces.find(x => e === x.id)
          e = { ...findWorkplace }
          return e
        })
      }

      this.workplaces = workplaces
      this.workplacesHistory = workplacesHistory
    },

    findWorkplace(id: string) {
      return this.workplaces.find((wp: any) => wp?.id === id)
    },
    findWorkplaceByName(name: string) {
      return this.workplaces.find((wp: any) => wp?.name === name)
    },
    filterWorkplaces(ids: any) {
      const workplaces: any = this.workplaces.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return workplaces
    },
    setCurrentWorkplace(workplace: any) {
      this.currentWorkplace = workplace
    },
    unshiftWorkplaceHistory(workplace: any) {
      this.workplacesHistory = this.workplacesHistory.filter(wp => wp.id !== workplace.id)
      this.workplacesHistory.unshift(workplace)
    },
  },
})
