<script setup lang="ts">
import Camera from 'simple-vue-camera'
import { usePermission } from '@vueuse/core'
import StorageHelper from '~/helpers/storageHelper'
import { Icons } from '~/models/Icon'
import { usersStore } from '~/store/users'
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { RoleGrid } from '~/models/documents/jReport'
import { getLastUpdatedByOnCell } from '~/helpers/UtilsHelper'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const storageHelper = new StorageHelper()
const route = useRoute()
const camera = ref<InstanceType<typeof Camera>>()
const cameraAccess = usePermission('camera')

const state = reactive({
  displayCameraModal: false,
  isActivated: true,
  isPreview: false,
  isPhotoUploading: false,
  hasVideoDevices: false,
  cameraStopped: false,
})

const openModal = (event) => {
  state.displayCameraModal = true
}

const closeModal = (event) => {
  state.displayCameraModal = false
}

watch(() => state.displayCameraModal, async (val: boolean) => {
  if (val) {
    if (cameraAccess.value !== 'granted')
      await navigator.mediaDevices.getUserMedia({ video: true })

    state.cameraStopped = false
    camera.value?.start()
  }
})

const modalInfos = computed(() => {
  if (isHistory.value) {
    return {
      title: 'document.step_photo_preview_picture',
      saveBtnText: 'document.step_photo_take_picture_again',
    }
  }

  return {
    title: props.params.value ? 'document.step_photo_update_picture' : 'document.step_photo_take_picture',
    description: props.params.value ? 'document.step_photo_update_picture_description' : 'document.step_photo_take_picture_description',
    saveBtnText: props.params.value ? 'document.step_photo_take_picture_again' : 'document.step_photo_take_picture',
  }
})

const noCameraAccess = computed(() => {
  return cameraAccess.value !== 'granted' || !state.hasVideoDevices
})

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  const { data, colDef } = props.params

  if (data?.isDisabled || colDef?.disabledStepIds?.includes(data?.stepId))
    return false

  return props.params?.colDef?.editable(props.params, false)
})

const activateCell = () => {
  if (isHistory.value || !isEditable)
    return

  state.isActivated = true
}

const uploadImage = async () => {
  const blob = await camera.value?.snapshot()
  const blobId = URL.createObjectURL(blob).split('/').at(-1)
  const fileName = `Screenshot_${props.params.data.stepId}_${blobId}`
  const path = await storageHelper.uploadFile(`clients/${usersStore().user.client_id}/${fileName}`, blob, fileName)

  props.params?.node?.setDataValue(props.params?.column?.colId, path)
  state.isPhotoUploading = false
}

const savePhoto = async () => {
  state.isPhotoUploading = true
  camera.value?.pause()

  // @ts-expect-error
  window.navigator.userAgentData.platform === 'macOS'
    ? await uploadImage()
    : setTimeout(uploadImage, 2000)
}

const checkVideoDevices = async () => {
  const devices = await camera.value?.devices(['videoinput']) || []

  state.hasVideoDevices = devices && devices.length > 0
  if (!state.hasVideoDevices)
    camera.value?.stop()
}

const stopCamera = () => {
  state.cameraStopped = true
}

const removeAttachment = () => {
  props.params?.node?.setDataValue(props.params?.column?.colId, null)
}

const init = () => {
  if (state.isActivated && props.params.value === '')
    props.params.node.setDataValue(props.params.column.colId, null)

  state.isPreview = props.params.colDef.gridType === RoleGrid.preview
}

state.isActivated = setValueOrNA(null, props.params)

init()
</script>

<template>
  <div v-if="!props.params.data.hidden">
    <div
      v-if="props.params.value"
      class="group"
    >
      <div
        v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-2.5 bottom-2 z-50"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          hide-full-name
          display-amount
          is-enable-modal
        />
      </div>
      <div
        style="relative"
        class="inline-block"
      >
        <img
          :src="props.params.value"
          class="mx-auto object-cover rounded-md"
          :class="`${props.params.data.description ? 'h-32 w-40' : 'h-fit w-fit'}`"
        >
        <component
          :is="Icons.CLOSE"
          v-if="!isHistory && isEditable"
          class="absolute p-0.5 top-2 right-2 z-50 h-6 w-6 cursor-pointer text-black bg-white rounded-full border border-black"
          @click="removeAttachment"
        />
      </div>
      <o-button
        alternate
        class="hidden group-hover:block absolute left-1/2 -translate-x-2/4 translate-y-0"
        :class="`${props.params.data.description ? '-mt-20' : '-mt-16'}`"
        @click="openModal($event)"
      >
        <component
          :is="isHistory ? Icons.VIEW : Icons.EDIT"
          class="h-4 w-4"
        />
      </o-button>
    </div>

    <div v-else-if="!props.params.value && state.isActivated">
      <o-button
        :is-disabled="state.isPreview || isHistory || !isEditable"
        alternate
        @click="openModal($event)"
      >
        <component
          :is="Icons.CAMERA"
          class="self-center"
        />
        {{ $t('document.step_photo_take_picture') }}
      </o-button>
    </div>

    <div
      v-else
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
  <ModalDynamic
    v-model="state.displayCameraModal"
    :width="{ outer: 'w-[900px]', inner: 'w-full' }"
    :modal-title="modalInfos.title"
    :modal-description="modalInfos.description"
    :save-text="modalInfos.saveBtnText"
    :icon="Icons.CAMERA"
    :disable-save-btn="!state.hasVideoDevices || isHistory || state.isPhotoUploading"
    @save="savePhoto"
    @action-on-close="closeModal"
  >
    <template #body>
      <div :class="{ 'pt-8': modalInfos.description?.length }">
        <Camera
          v-if="!isHistory && !state.cameraStopped && cameraAccess === 'granted'"
          ref="camera"
          class="rounded-md"
          :resolution="{ width: 375, height: 200 }"
          autoplay
          @loading="checkVideoDevices"
          @stopped="stopCamera"
        />
        <img
          v-if="isHistory && props.params.value"
          :src="props.params.value"
          class="mx-auto h-full w-full object-cover relative rounded-md"
          alt=""
        >
        <component
          :is="Icons.VIDEO_OFF"
          v-if="noCameraAccess && !isHistory"
          class="w-16 h-16 mx-auto"
        />
        <!-- Weird check because I have to start => stop the camera before displaying any error -->
        <div
          v-if="state.cameraStopped && !state.hasVideoDevices"
          class="text-sm text-gray-600 text-center p-6"
        >
          {{ $t('document.step_photo_no_devices_found') }}
        </div>
        <div
          v-if="!isHistory && noCameraAccess"
          class="text-sm text-gray-600 text-center p-6"
        >
          {{ $t('document.step_photo_no_camera_access') }}
        </div>
      </div>
    </template>
  </ModalDynamic>
</template>
