import { i18n } from '~/modules/i18n'

const { t } = i18n.global

export enum DocumentSettingsType {
  repetition = 'repetition',
  units_of_measure = 'units_of_measure',
  means_of_measure = 'means_of_measure',
  step_tag = 'step_tag',
  grid_header = 'grid_header',
  formula = 'formula',
  list_options = 'list_options',
}

export enum RepetitionType {
  default = 'default',
  formula = 'formula',
  sampling = 'sampling',
}

export enum InfiniteValue {
  MAX = 999999,
  MIN = -999999,
}

export class DocumentSetting {
  static addedAttributes: Array<string> = ['_type']
  static hiddenAttributes: Array<string> = ['id', 'client_id', 'site_id', 'deleted_at', 'values']

  public id?: string = ''
  public type = ''
  public client_id?: string
  public site_id?: string
  public value?: any = null
  public deleted_at = null
  public values = []

  // type: 'repetition'

  constructor(data: object) {
    for (const key in data)
      this[key] = data[key]
  }

  get _type() {
    return t(`settings.${this.type}`)
  }

  public toJson(): object {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
      deleted_at: this.deleted_at,
    }
  }

  public static fromCollection(data: Array<object>, uniqueValuesKey = 'value') {
    return data.map((item) => {
      return new this({ ...item, values: data.map(row => row?.[uniqueValuesKey]).filter(value => value !== item[uniqueValuesKey]) })
    })
  }

  public editable() {
    return [
      { attribute: 'value', type: 'text', required: true, unique: true },
    ]
  }

  public validate() {
    if (!this.value || this.value.trim() === '')
      return false

    return true
  }

  public static getHeader(): object {
    const removedAttributes = this.addedAttributes
      .map(item => item.replace('_', ''))
      .concat(this.hiddenAttributes)

    // !value.includes('type')
    return [...this.addedAttributes, ...Object.keys(new this())]
      .filter(value => !removedAttributes.includes(value) && value !== '_type' && value !== 'list_value')
      .map((item) => {
        const formattedItem: string = item.charAt(0) === '_' ? item.substring(1) : item
        return {
          key: item,
          value: t(`settings.${formattedItem}`),
        }
      })
  }
}

export class ListOptions extends DocumentSetting {
  static hiddenAttributes: Array<string> = ['id', 'client_id', 'site_id', 'value', 'deleted_at', 'values']

  public list_type!: string
  public list_value!: ListOptionValues[]
  type = DocumentSettingsType.list_options

  constructor(data: ListOptions) {
    super(data)
    this.list_type = data?.list_type
    this.list_value = data?.list_value?.filter(e => e.deleted_at === null) ?? []
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type || null,
      list_type: this.list_type || null,
      list_value: this.list_value || null,
    }
  }

  public validate() {
    if (!this.list_type || this.list_type.trim() === '' || !this.list_value || this.list_value == [])
      return false

    return true
  }

  public editable() {
    return [
      { attribute: 'list_type', type: 'text', required: true, unique: true },
      { attribute: 'list_value', type: 'vSelect', required: true },
    ]
  }
}

export class ListOptionValues {
  option_key = ''
  option_value = ''
  deleted_at = null
}

export class JRepetition extends DocumentSetting {
  static addedAttributes: Array<string> = ['_type', '_is_selected', '_repetition_type']

  public type = DocumentSettingsType.repetition
  public repetition_type!: RepetitionType.event
  public unit!: string
  public sample!: number
  public is_selected = false // Not to be displayed when creating settings, should be cleaned later
  public beforeOrAfter = ''
  public selected_teams = ''

  constructor(data: object) {
    super(data)
    this.repetition_type = data?.repetition_type || RepetitionType.event
    this.unit = data?.unit || ''
    this.sample = data?.sample || 0
  }

  get _is_selected() {
    return this.is_selected ? t('global.yes') : t('global.no')
  }

  get _repetition_type() {
    return t(`settings.${this.repetition_type}`, '-')
  }

  public toJson() {
    return {
      ...super.toJson(),
      repetition_type: this.repetition_type || null,
      unit: this.unit || null,
      sample: this.sample || null,
      is_selected: this.is_selected,
    }
  }

  public editable() {
    const repetitionTypeOptions = Object.values(RepetitionType).map((item) => {
      return {
        value: t(`settings.${item}`),
        key: item,
      }
    })

    const isSelectedOptions = [
      { text: t('global.yes'), val: true },
      { text: t('global.no'), val: false },
    ]

    return [
      ...super.editable(),
      { attribute: 'repetition_type', type: 'select', options: repetitionTypeOptions },
      { attribute: 'unit', type: 'text' },
      { attribute: 'sample', type: 'number' },
      { attribute: 'is_selected', type: 'boolean', options: isSelectedOptions },
    ]
  }
}

export class UnitsOfMeasure extends DocumentSetting {
  type = DocumentSettingsType.units_of_measure

  constructor(data: object) {
    super(data)
  }
}

export class MeansOfMeasure extends DocumentSetting {
  type = DocumentSettingsType.means_of_measure

  constructor(data: object) {
    super(data)
  }
}

export class StepTag extends DocumentSetting {
  type = DocumentSettingsType.step_tag

  constructor(data: object) {
    super(data)
  }
}

export class GridHeader extends DocumentSetting {
  type = DocumentSettingsType.grid_header
  public value = ''
  public header_list = []

  constructor(data: object) {
    super(data)
    this.value = data?.value || ''
    this.header_list = data?.header_list?.filter(e => e.deleted_at === null) ?? []
  }

  public toJson() {
    return {
      ...super.toJson(),
      value: this.value,
      header_list: this.header_list,
    }
  }

  public validate() {
    if (!this.value || this.value.trim() === '')
      return false

    return true
  }

  public editable() {
    return [
      { attribute: 'value', type: 'text', required: true },
      { attribute: 'header_list', type: 'vSelect' },
    ]
  }
}

export class Formula extends DocumentSetting {
  type: DocumentSettingsType = DocumentSettingsType.formula
  formula = ''

  constructor(data: object) {
    super(data)
    this.formula = data?.formula
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      formula: this.formula,
    }
  }

  public editable() {
    return [...super.editable(), { attribute: 'formula', type: 'text' }]
  }
}

export const getDocumentClassType = (type: string) => {
  switch (type) {
    case 'repetition':
      return JRepetition
    case 'units_of_measure':
      return UnitsOfMeasure
    case 'means_of_measure':
      return MeansOfMeasure
    case 'step_tag':
      return StepTag
    case 'grid_header':
      return GridHeader
    case 'formula':
      return Formula
    case 'list_options':
      return ListOptions
    default:
      return DocumentSetting
  }
}
