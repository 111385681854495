import _ from 'lodash'
import { addHours, fromUnixTime, isFriday, isMonday, isWednesday, parseISO } from 'date-fns'
import localforage from 'localforage'
import { getUserById } from '../authentication'
import { StepModel } from './../../viewModels/reportAnswerViewModel'
import { getWeekAnswers } from './../../helpers/DateHelper'
import { JReport, RoleGrid } from './../../models/documents/jReport'
import { areAllRowsFilled, computeColumnsInsights, computeStepsInsights } from './../../services/grid/index'
import type { JStep } from './../../models/documents/jStep'
import { castInputDataValue } from './../../helpers/UtilsHelper'
import type { JSession, JSessionContext } from '~/models/sessions/JSession'
import { JStatusHistory, ReportStatus } from '~/models/documents/jReport'
import { STEPS_COLLECTION_NAME } from '~/config/storage'
import DbHelper from '~/helpers/dbHelper'
import type { JDocument } from '~/models/documents/jDocument'
import { DocumentFormat, DocumentTrigger, DocumentType } from '~/models/documents/jDocument'
import { getCurrentWorkingTeam } from '~/controllers/teams'
import {
  RepetitionType,
} from '~/models/documents/documentSettings'
import api from '~/helpers/ApiHelper'
import { removeInstance } from '~/utils/object'
import { ReportInputDataViewModel } from '~/viewModels/reportAnswerViewModel'
import type { ReportAnswerViewModel } from '~/viewModels/reportAnswerViewModel'
import { ColumnState } from '~/utils/report'
import { ContextScopeViewModel } from '~/viewModels/session/contextScopeViewModel'
import loggerHelper from '~/helpers/LoggerHelper'
import { CACHED_OBJECTS } from '~/config/local-forage'
import { usersStore } from '~/store/users'
import { OfflineHandler } from '~/services/offline'

// const analyticsHelper = new AnalyticsHelper()
const dbHelper = new DbHelper()

const GRID_SIZE_PREVIEW_QUANTITY = 20
const GRID_SIZE_UNLIMITED = 99

// Reports
export const createReport = async (document: JDocument, session_id: string, quantity: number, last_team: string, sessionContext: JSessionContext): Promise<JReport> => {
  // const formulas = await getDocumentSettings(DocumentSettingsType.formula)
  // const documentFormula = formulas.find(
  //   (x: DocumentSetting) => x.id === document.format.value,
  // )
  const gridSize: number = await computeGridSize(document.format, quantity)

  return api.createReport({
    session_id,
    document_id: document.id,
    document_category: document.category,
    document_name: document.name,
    document_trigger: document.trigger,
    workflow_id: document.workflow_id || null,
    last_team,
    status: ReportStatus.new,
    steps: document?.steps?.map(element => ({
      id: element,
      answers: [],
    })),
    gridSize,
    context: sessionContext,
  })
}

export const createReportWithContext = async (document: JDocument, last_team: string, workplaceId: string): Promise<JReport> => {
  const gridSize: number = await computeGridSize(document.format)

  const context = {
    workplace_id: workplaceId,
    product_id: 'ALL_PRODUCTS',
    operation_id: '',
    production_order_id: '',
  }

  return api.createReport({
    context,
    document_id: document.id,
    document_category: document.category,
    document_name: document.name,
    document_trigger: document.trigger,
    workflow_id: document.workflow_id || null,
    last_team,
    status: ReportStatus.new,
    steps: document?.steps?.map(element => ({
      id: element,
      answers: [],
    })),
    gridSize,
  })
}

const parseDate = (date) => {
  if (typeof date === 'number') {
    // If date is a timestamp (in milliseconds)
    return fromUnixTime(date / 1000)
  }
  else if (typeof date === 'string' && date.includes('T')) {
    // If date is an ISO string
    return parseISO(date)
  }
  else if (typeof date === 'string') {
    // If date is in another string format
    return new Date(date)
  }
  // Fallback for other types or formats
  return new Date(date)
}

const computeLastInputsValidity = (allInputData: any[]) => {
  // Step 1: Group by a combination of col_id and row_id
  const grouped = _.groupBy(allInputData, (item: any) => `${item.col_id}_${item.row_id}`)

  // Step 2: Find the most recent entry for each group
  const mostRecentEntries = _.map(grouped, (group: any) =>
    _.maxBy(group, (item: any) => parseDate(item.update_date).getTime()),
  )

  // Step 3: Check if all most recent entries are valid
  return _.every(mostRecentEntries, ['is_valid', true])
}

export const computeReportInsights = (report: JReport): any => {
  let controlTotal = 0
  let validCells = 0
  let controlSuccess = 0
  let processSuccess = 0
  let usedStep = 0
  let validSteps = report?.steps?.length

  const allInputData = _.orderBy(report.inputData ?? [], 'update_date', 'asc')
  const maxIndexUsed = Math.max(...allInputData.map(data => data.col_id))

  for (let indexCol = 0; indexCol <= maxIndexUsed; indexCol++) {
    const { columnState, colValidCells, colControlTotal } = computeColumnsInsights(allInputData, indexCol, report.steps, indexCol === maxIndexUsed)
    validCells += colValidCells
    controlTotal += colControlTotal
    if (columnState === ColumnState.ok)
      controlSuccess++
    usedStep++
  }

  validSteps = computeStepsInsights(allInputData, maxIndexUsed, report.steps)

  processSuccess = controlTotal === 0 ? 0 : validSteps
  const totalSteps = controlTotal === 0 ? 0 : report.steps.length

  const allInputsValid = computeLastInputsValidity(allInputData)

  return {
    validCells,
    controlTotal,
    controlSuccess,
    processSuccess,
    usedStep,
    totalSteps,
    allInputsValid,
  }
}

export const createReportPreview = async (document: JDocument): Promise<any> => {
  const currentUser = usersStore().user
  const report = removeInstance(new JReport(document))
  report.creation_date = new Date().getTime()
  report.client_id = currentUser.client_id
  report.site_id = currentUser.site_id
  report.document_id = document.id
  report.document_category = document.category
  report.document_name = document.name
  report.document_trigger = document.trigger
  report.last_team = ''
  report.format = document.format

  // audit data
  report.created_by = currentUser.id
  report.updated_by = currentUser.id
  report.creation_date = new Date().getTime()
  report.update_date = new Date().getTime()

  report.status = ReportStatus.new
  document?.steps?.forEach((element) => {
    report.steps.push({
      id: element,
      answers: [],
    })
  })

  report.gridSize = computeGridSize(document?.format, GRID_SIZE_PREVIEW_QUANTITY)
  const createdReport = report

  report.id = createdReport.id

  return report
}

export const createContextPreview = (gridSize: number, step: JStep, session: JSession): any => {
  let contextScope = {}
  let contextTags = {}
  const tags = session.session_context?.tags || session.tags

  if (!tags) {
    contextScope = {
      tags: {
        moule: 7920,
        nbSacs: 210,
        nbEmpreintes: 24,
        numeroDePlan: 3,
        lastAnswer: _.last(step.answers),
        isMonday: isMonday(new Date()),
        isWednesday: isWednesday(new Date()),
        weekAnswers: getWeekAnswers(step),
        isFriday: isFriday(new Date()),
        add24HTolastAnswerDate: addHours(new Date(_.last(step.answers)?.update_date),
          24),
        gridSize,
      },

      quantity: 792000,
    }
  }
  else {
    contextTags = { ...tags, workplaceId: session?.workplaceId, lotName: session?.value, lastAnswer: _.last(step.answers), isMonday: isMonday(new Date()), isWednesday: isWednesday(new Date()), isFriday: isFriday(new Date()), add24HTolastAnswerDate: addHours(new Date(_.last(step.answers)?.update_date), 24), gridSize, weekAnswers: getWeekAnswers(step) }

    contextScope = {
      tags: _.clone(contextTags),
      quantity: session.quantity,
      productId: session?.productId,
    }
  }

  return contextScope
}

export const getFormulaContext = (role: string, step: JStep, gridSize: number, session: JSession): any => {
  const currentUser = usersStore().user

  let contextTags = {} as any
  let contextScope = {} as any

  if (role === RoleGrid.preview) {
    const contextPreview = createContextPreview(gridSize, step, session)
    // contextScope = { ...contextPreview, step, currentUser }
    contextScope = new ContextScopeViewModel({ ...contextPreview, step, currentUser })
  }
  else {
    contextTags = { ...session?.session_context?.tags, workplaceId: session?.session_context?.workplace_id, lotName: session?.session_context?.production_order_id, lastAnswer: _.last(step.answers), isMonday: isMonday(new Date()), isWednesday: isWednesday(new Date()), isFriday: isFriday(new Date()), add24HTolastAnswerDate: addHours(new Date(_.last(step.answers)?.update_date), 24), gridSize, weekAnswers: getWeekAnswers(step) }
    contextScope = new ContextScopeViewModel({ quantity: session?.session_context?.quantity, productId: session?.session_context?.product_id, tags: contextTags, step, currentUser })
  }
  return contextScope
}

const computeGridSize = (format: any, quantity?: number) => {
  if (format?.type) {
    switch (format.type) {
      case DocumentFormat.Unique:
        return 1
      case DocumentFormat.Quantity:
        return quantity
      case DocumentFormat.Fixed:
        return format.value
      // case DocumentFormat.Formula:
      //   return engine.parseAndRender(formula, scope)
      case DocumentFormat.Unlimited:
        return GRID_SIZE_UNLIMITED
      default:
        return GRID_SIZE_UNLIMITED
    }
  }
  else {
    throw new Error('Document format type is not defined')
  }
}

export const getOrCreateReport = async (document: JDocument, session_id: string, reports: JReport[], quantity: number, last_team: string, sessionContext: JSessionContext): Promise<JReport> => {
  const report = reports?.find(e => e.document_id === document.id)

  return report || await createReport(document, session_id, quantity, last_team, sessionContext)
}

export const gerNirReportIfExists = async (document: JDocument, session_id: string, reports: JReport[], quantity: number, last_team: string, sessionContext: JSessionContext): Promise<JReport> => {
  const report = reports?.find(e => e.document_id === document.id)

  return report || await api.getNirReport({
    product_id: sessionContext.product_id,
    production_order_id: sessionContext.production_order_id,
    workplace_id: sessionContext.workplace_id,
    document_id: document.id,
  })
}

export const getOrCreateReports = async (docs: any[], sessionId: string, quantity: number, sessionContext: JSessionContext, ids?: string[]): Promise<any[]> => {
  const createdReports = []
  const read_write_documents = docs?.filter(p => p.type === DocumentType.read_write)
  const automatic_documents = docs?.filter(p => p.type === DocumentType.automatic)
  const reports = await api.getReports({
    sessionId,
    status: 'All',
    ids,
    skipPagination: true,
  })
  const currentWorkingTeam = await getCurrentWorkingTeam()

  for (let i = 0; i < read_write_documents.length; i++) {
    const currentDocument = read_write_documents[i]

    const newReport = await getOrCreateReport(currentDocument, sessionId, reports, quantity, currentWorkingTeam?.id || '', sessionContext)
    if (newReport)
      newReport.last_team = currentWorkingTeam?.id || ''
    createdReports.push(newReport)
  }

  for (let i = 0; i < automatic_documents.length; i++) {
    const currentDocument = automatic_documents[i]

    const report = await gerNirReportIfExists(currentDocument, sessionId, reports, quantity, currentWorkingTeam?.id || '', sessionContext)
    if (report) {
      report.last_team = currentWorkingTeam?.id || ''
      createdReports.push(report)
    }
  }

  return createdReports
}

export const updateReport = async (report: JReport, document: JDocument, steps: JStep) => {
  const currentWorkingTeam = await getCurrentWorkingTeam()

  await api.updateReport(report.id, {
    last_team: currentWorkingTeam?.id || '',
    is_multiple: document.is_multiple,
    steps,
    status: report.status,
    gridSize: report.gridSize,
    is_valid: report.is_valid,
    last_col_index: report.last_col_index,
  })
}

export const getReportsByIds = async (ids: number[]): Promise<any> => {
  if (ids?.length === 0)
    return []

  return api.getReports({
    ids,
    skipPagination: true,
    withSteps: false,
  })
}

export const getReportsByContext = async (reportContext: any): Promise<any> => {
  if (!reportContext)
    return []

  const context = {
    skipPagination: true,
    product_id: reportContext.product_id,
    workplace_id: reportContext.workplace_id,
    operation_id: reportContext.operation_id,
  }

  return api.getReports(context)
}

export const getFullReportById = async (idReport: string, withHistory = false): Promise<any> => {
  return api.getReport(idReport, { withSteps: true, withHistory })
}

export const getRichReportPdf = async (idReport: string): Promise<any> => {
  return api.getRichReportPdf(idReport)
}

export const getSimpleReportPdf = async (idReport: string): Promise<any> => {
  return api.getSimpleReportPdf(idReport)
}

export const getRichExport = async (idReport: string, target: string): Promise<any> => {
  return api.getRichExport(idReport, target)
}
export const getMultipleRichExport = async (reportIds: string[], target: string): Promise<any> => {
  return api.getMultipleRichExport(reportIds, target)
}

export const updateInputData = async (event: any, report: JReport, steps: JStep, context: any): Promise<any> => {
  try {
    const currentUser = usersStore().user
    const inputData = new ReportInputDataViewModel({
      id: event?.data?.id,
      row_id: event?.data?.row_id,
      col_id: event?.data?.col_id,
      update_date: event?.update_date,
      updated_by: currentUser?.id,
      report_id: report?.id,
      step_id: report?.steps[event?.rowIndex]?.id,
      value: event?.data?.value,
      type: steps[event?.rowIndex]?.type,
      is_valid: event.data?.is_valid,
      reason: event.data?.reason,
      context,
    })

    OfflineHandler.setInputData(inputData)
    loggerHelper.logInfo(`[input data] Frontend Controller : Preparing to call api user_id: ${currentUser?.id} row_id : ${inputData.row_id}, cold_id : ${inputData?.col_id}, value : ${inputData?.value}, report_id : ${inputData?.report_id}`)

    return await api.createInputData({ inputData })
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError(`Error saving input data for report ${report?.id} with payload ${JSON.stringify(event)} error : ${e}`, e.message)
  }
}

export const retryUpdateInputData = async () => {
  const keys = await localforage.keys()
  const filteredKeys = keys.filter(key => key.startsWith(CACHED_OBJECTS.INPUT_DATA))

  if (filteredKeys && filteredKeys.length > 0) {
    for (const key in filteredKeys) {
      const inputData = await localforage.getItem(filteredKeys[key])
      if (inputData && navigator.onLine) {
        try {
          await localforage.removeItem(filteredKeys[key])
          const resultAxios = await api.createInputData({ inputData })
          loggerHelper.logEvent(`Saving input data from cache for report ${inputData?.report_id} with payload ${JSON.stringify(inputData)} returned : ${resultAxios?.status}`)
        }
        catch (e) {
          loggerHelper.logError(`Error saving input data from cache for report ${inputData?.report_id} with payload ${JSON.stringify(inputData)} error : ${e}`, e.message)
          console.error(e)
        }
      }
    }
  }
}

export const updateReportSteps = async (report: JReport): Promise<any> => {
  try {
    const reportModel = Object.assign({}, report)
    const stepsModel = [] as StepModel[]
    reportModel?.steps.forEach((step) => {
      const stepModel = new StepModel({
        id: step?.id,
        last_sampling_areas: step?.last_sampling_areas,
        last_targets: step?.last_targets,
        hidden: step?.hidden,
        disabled: step?.disabled,
        col_ids_to_disable: step?.col_ids_to_disable,
      })
      stepsModel.push(stepModel)
    })
    reportModel.steps = stepsModel

    await api.updateReport(report.id, { ...reportModel })
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError(`Saving report steps for report ${report?.id} with steps ${report.steps} report ${report} error : ${e}`, e.message)
  }
}

export const setReportStatus = async (params: { report: JReport; status: ReportStatus | string }): Promise<any> => {
  try {
    const { report, status } = params
    const currentUser = usersStore().user

    const newHistory = removeInstance(new JStatusHistory({
      updated_by: currentUser?.id,
      update_date: new Date(),
      status,
    }))

    report.status_history
      ? report?.status_history?.unshift(newHistory)
      : report.status_history = [newHistory]

    OfflineHandler.setReportStatus(report, status)

    await api.updateReport(report.id, { status })
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError(`setReportStatus for report ${params?.report?.id} with status ${params?.status} report ${params?.report} error : ${e}`, e.message)
  }
}

export const updateReportContent = async (report_id: string, report: JReport): Promise<any> => {
  try {
    await api.updateReport(report_id, report)
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError(`updateReportContent for report ${report_id} report ${report} error : ${e}`, e.message)
  }
}

export const getReportsWhileRangeTime = async (startDate: any, endDate: any): Promise<JReport[]> => {
  if (!Number.isInteger(startDate) || !Number.isInteger(endDate))
    return Promise.resolve([])

  return api.getReports({
    startDate,
    endDate,
  })
}

export const getStepsFromIds = async (documentIds: any[]): Promise<any[]> => {
  return await dbHelper.getAllDataFromCollectionFromIds(STEPS_COLLECTION_NAME, documentIds)
}

export const getStepFromId = async (stepId: string): Promise<any[]> => {
  return await dbHelper.getDocFromCollection(STEPS_COLLECTION_NAME, stepId)
}

/// Business logic

const isFillable = (startEditIndex: any, params: any) => {
  const { frequency, sample } = params.data
  const currentCol = params.colDef.index
  const forcedCol = startEditIndex

  const rest = (currentCol - forcedCol) % frequency

  return rest >= 0 && rest <= Number(sample) - 1
}

export const setValueOrNA = (valCell: any, params: any) => {
  if (valCell !== null && valCell !== undefined && valCell !== '')
    return true
  const { startEditIndex, initialShiftIndex } = params.data.details
  if (!params.data.details.samplingAreas)
    return true
  const currentSamplingArea = params.data.details.samplingAreas[params.data.details.samplingAreas.length - 1]
  if (params.data.typeRepetition?.includes(RepetitionType.sampling)
    || params.data.typeRepetition?.includes(RepetitionType.formula)) {
    if (startEditIndex === initialShiftIndex) {
      return currentSamplingArea && currentSamplingArea[params.colDef.index - initialShiftIndex]
    }
    else {
      if (startEditIndex > 0 && params.colDef.index <= startEditIndex) {
        if (valCell !== null && valCell !== undefined && valCell !== '')
          return true

        return currentSamplingArea[params.colDef.index - initialShiftIndex]
      }
      if (currentSamplingArea[startEditIndex - initialShiftIndex])
        return currentSamplingArea[params.colDef.index - initialShiftIndex]
      else
        return isFillable(startEditIndex, params)
    }
  }
  else if (params.data.typeRepetition?.includes(RepetitionType.event) || params.data.typeRepetition.length === 0) {
    if (!!valCell || valCell === false) { return true }
    else {
      if (currentSamplingArea[params.colDef.index - initialShiftIndex])
        return true
    }
    return false
  }
}

export const mapViewModelToGrid = (report: ReportAnswerViewModel) => {
  const steps: any[] = []

  const answers = [] as any
  const allAnswers = [] as any

  report.inputData = _.orderBy(report.inputData, 'update_date', 'desc')

  for (const stepId of report.step_ids) {
    for (const inputData of report.inputData) {
      const isDuplicate = !!answers.find(answer => answer.col_id === inputData.col_id && answer.row_id === inputData.row_id)
      const value = castInputDataValue(inputData)
      const answer = {
        id: inputData.id,
        type: inputData.type,
        report_id: report.id,
        value,
        is_valid: inputData.is_valid,
        col_id: inputData.col_id,
        row_id: inputData.row_id,
        updated_by: inputData.updated_by,
        update_date: inputData.update_date,
        reason: inputData.reason,
        step_id: inputData.step_id,
      }
      if (inputData.step_id === stepId)
        allAnswers.push(answer)
      !isDuplicate && answers.push(answer)
    }
  }
  for (let i = 0; i < report.steps.length; i++) {
    const step = report.steps[i]
    step.answers = [] as any[]
    for (const answer of answers) {
      if (answer.step_id === step.id && answer.type === step.type)
        step.answers.push(answer)
    }
    if (step.client_id && step.site_id)
      steps.push(step)
  }
  report.steps = steps
  return { report, allAnswers }
}

export const isCreatedMoreThan8HoursAgo = (creation_date: Date) => {
  if (addHours(creation_date, 8) < new Date())
    return true
  return false
}

export const isReportCompleted = (report: JReport): boolean => {
  let rowCompleted = true
  for (let i = 0; i < report.gridSize; i++) {
    if (report.document_trigger === DocumentTrigger.production_order)
      rowCompleted = rowCompleted && areAllRowsFilled(report.steps, i)
  }
  const reportCompleted = !!rowCompleted
  return reportCompleted
}

export const getOperatorInitials = (id: string) => {
  const user = getUserById(id) as any
  return user ? `${user.first_name?.charAt(0).toUpperCase()}${user.last_name?.charAt(0).toUpperCase()}` : ''
}

export const backupInputDataReport = (backupData: object) => {
  try {
    dbHelper.addDataToCollection('REPORT_INPUTS_BACKUP', backupData).catch(_ => console.log('input backup fails!'))
  }
  catch (e) {
    console.log('backup input_data failed!')
  }
}
