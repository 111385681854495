<script setup lang="ts">
import type { Controle } from '~/models/operator'
import { StepMeasureOptions, StepType } from '~/models/documents/jStep'

const props = defineProps({
  params: {
    type: Object,
    required: true,
    default: {} as any,
  },
})

const isMeasure = props.params?.data?.type === StepType.Measure
const isBoolean = props.params?.data?.type === StepType.Boolean

// TODO :  Change with chartjs interface

const state = reactive({
  details: props.params?.data?.details,
  tags: props.params?.data?.tags,
  aqlTags: props.params?.data?.aqlTags,
  isHistory: props.params?.data?.isHistory,
  unit: props.params?.data?.details?.unit,
  allControles: [] as Controle[],
  gridHasFileAttachement: props.params?.columnApi.columnModel.columnDefs.find(e => e.field === 'file'),
})

// TODO LUC -> Remove hard coded
const tagName = (tag: any, key: string) => {
  if (key === 'quantity')
    return `Quantité : ${tag?.toString()}`
  else if (key === 'nbPieceByControl')
    return `Pièce / contrôle : ${tag?.toString()}`
  else if (key === 'nbPieces')
    return `Nb pièce mini : ${tag?.toString()}`
}
</script>

<template>
  <div
    v-if="!props.params?.data?.hidden"
    class="flex flex-col justify-center h-full text-left py-2 w-full overflow-visible"
    data-cy="cell-renderer-description"
  >
    <div class="flex flex-row gap-2 leading-normal align-center justify-between w-full items-center whitespace-normal">
      <span
        class="font-bold"
      > {{ props.params?.data?.name }}
      </span>

      <span
        v-if="!props.params?.data?.isMandatory"
        class="bg-tab-primary font-medium text-grey-900 p-1.5 text-xs rounded"
      >
        {{ $t('global.optional') }}
      </span>
      <div
        v-if="state.aqlTags"
        class="flex flex-col gap-2"
      >
        <div
          v-for="tag, key in state.aqlTags"
          :key="tag"
          class="flex flex-col gap-2"
        >
          <span
            v-if="props.params?.data?.isMandatory && tag"
            class="bg-[#FEF0CD] font-medium text-primary p-1.5 text-xs rounded"
          >
            {{ `${tagName(tag, key)}` }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="state.tags?.length"
      class="flex flex-wrap gap-2 py-1"
    >
      <div
        v-for="(tag, index) in state.tags"
        :key="`${tag}-${index}`"
        class="h-6 text-sm leading-6 bg-purple-200 pt-0 px-2 ml-0 rounded-md"
      >
        <span> {{ tag }} </span>
      </div>
    </div>
    <div
      v-if="isBoolean"
      class="leading-normal flex items-center gap-4 text-sm"
    >
      <span
        v-if="state.details?.means_of_measure"
        class="font-medium"
      >
        {{ state.details?.means_of_measure }}
      </span>
    </div>
    <div
      v-if="isMeasure"
      class="leading-normal flex items-center gap-4 text-sm"
    >
      <span
        v-if="state.details?.means_of_measure"
        class="font-medium"
      >
        {{ state.details?.means_of_measure }}
      </span>
      <span
        v-if="props.params?.data?.type === StepType.Measure"
        :class="{ 'pt-2': !state.details?.means_of_measure }"
      >
        <span
          v-if="state.details?.goal !== undefined && !state.details?.last_targets?.length"
          class="font-semibold"
        >
          {{ `${state.details?.goal} ` }}
        </span>
        <span
          v-if="state.details?.unit"
          class="font-medium"
        >
          {{ `${state.details?.unit} ` }}
        </span>
        <span class="ml-2 gap-2 font-medium">
          <span class="font-light mr-2">
            {{ $t('step.measure_min') }}:
            <span class="font-semibold">
              {{ `${state.details.minType === StepMeasureOptions.Margin ? '-' : ''}${state.details?.range[0]} ` }}
            </span>
          </span>
          <span class="font-light">
            {{ $t('step.measure_max') }}:
            <span class="font-semibold">
              {{ `${state.details.maxType === StepMeasureOptions.Margin ? '+' : ''}${state.details?.range[1]} ` }}
            </span>
          </span>
        </span>
        <span
          v-if="state.details?.is_dynamic"
          :class="`gap-2 font-medium ${state.details?.goal && 'ml-2'}`"
        >
          {{ ` ${$t('report.dynamic')}` }}
        </span>
      </span>
    </div>
    <div
      v-if="props.params?.data?.description"
      id="rich-editor-description"
      class="leading-normal font-light whitespace-normal mt-4"
    >
      <div
        v-html="props.params?.data?.description"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import '~/../../../styles/_richeditor.scss';

.truncate-modified {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ag-pinned-left-cols-container  {
  .ag-row {
    @apply flex items-baseline;
    .ag-cell-value:first-child{
      @apply ml-4 top-2;
      width: 32px !important;
    }
    .ag-cell-last-left-pinned{
      @apply h-auto overflow-visible;
    }
  }
}

.ag-pinned-left-cols-container :has(.gridHasFileAttachement) {
  .ag-row {
    @apply flex items-baseline;
    .ag-cell-value:first-child{
      @apply bg-cloudygray-100 rounded-full h-8 p-0 text-base font-semibold self-center ml-4 top-2;
    }
    .ag-cell-last-left-pinned{
      @apply h-auto overflow-visible;
    }
  }
}
</style>
