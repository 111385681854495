alerts:
  alert_center: Centro de alertas
  comment: Comentario
  justification: Justificación
  information_about_alerts: Más información sobre las alertas...
  no_saved_answers: No hay respuestas guardadas
  to_signal: 'A señalar:'
  select_users_to_alert: Seleccione la(s) persona(s) a alertar
  people_to_alert: 'Personas o grupos a alertar:'
  new_alert: 'Nueva alerta'
  no_justification_provided: No se ha proporcionado justificación
  minimum_one_selected_people: Se debe asignar al menos una persona a la alerta
  minimum_one_justification: Debe proporcionar al menos una justificación
  raised_by_me: Levantadas por mí
  my_interventions: Mis intervenciones
  assigned_to: asignó la alerta a
  removed_from: desasignó
  status_history: Historial del estado de la alerta
  get_more: Cargar + { count } alerta | Cargar + { count } alertas
  load_more: Más alertas
  add_comment: Comentar la alerta o añadir detalles...
analysis:
  analysis: Análisis
application_fields:
  all_products: Todos los productos
  all_products_search: 'Todos los productos que contienen: { entry }'
  all_operations: Todas las operaciones
  all_operations_search: 'Todas las operaciones que contienen: { entry }'
  all_workplaces: Todos los lugares de trabajo
  all_workplaces_search: 'Todos los lugares de trabajo que contienen: { entry }'
  application_fields: Campos de aplicación
  charging-station: Estación de carga
  operations: Operaciones
  products: Productos
  reference: Referencias
  select_product: Seleccionar un producto...
  select_workplace: Seleccionar un puesto de carga...
  select_operation: Seleccionar una operación...
  select_all_products: Seleccionar todos los productos
  select_all_workplaces: Seleccionar todos los lugares de trabajo
  select_all_operations: Seleccionar todas las operaciones
  workplaces: Lugares de trabajo
button:
  about: Acerca de
  back: Regresar
  go: IR
  home: Inicio
  toggle_dark: Alternar modo oscuro
  toggle_langs: Cambiar idioma
dashboard:
  last_30_days: Los últimos 30 días.
  select: Seleccione un panel
  select_dashboard_placeholder: Seleccione
  empty_dashboard: No hay ningún panel disponible.
dates:
  month: hace un mes | hace {amount} meses
  day: hace un día | hace {amount} días
  hour: hace una hora | hace {amount} horas
  minute: hace un minuto | hace {amount} minutos
  second: ' hace un segundo | hace {amount} segundos'
days:
  daily: Todos los días
  friday: Viernes
  monday: Lunes
  saturday: Sábado
  sunday: Domingo
  thursday: Jueves
  tuesday: Martes
  wednesday: Miércoles
monthes:
  january: Enero
  february: Febrero
  march: Marzo
  april: Abril
  may: Mayo
  june: Junio
  july: Julio
  august: Agosto
  september: Septiembre
  october: Octubre
  november: Noviembre
  december: Diciembre
display:
  all_columns: Todas las columnas
  editable_columns: Columnas editables
document:
  notify_operator: Notificar al operador
  simulate: Simular
  production_order: Orden de producción
  display: Visualización
  columns: Columna
  column_number: Número de columna
  status: Estado
  create: Nuevo documento
  get_more_revisions: Cargar + { count } revisión | Cargar + { count } revisiones
  load_more_revisions: Más revisiones
  add_new_block: Agregar un nuevo bloque
  application_field: Campo de aplicación
  ask_admin: Por favor, póngase en contacto con el administrador de la aplicación
  category: Categoría
  document_to_model_success: Documento convertido con éxito en modelo
  unsaved_changes_description: Cualquier modificación se perderá si no guarda este documento
  unsaved_changes_title: Este documento no está guardado
  steps_linked_to_template: Algunos bloques ya están vinculados a un modelo
  unlink_steps: Desvincule primero los bloques que ya han sido importados de un modelo.
  exit_without_saving: Salir sin guardar
  version: Versión
  version_reason_add: Agregar una nota sobre la nueva versión del documento
  version_reason_error: Debe justificar la nueva versión del documento.
  note: Nota
  created_by: Creado por
  use_as_template: Usar como modelo
  created_on: Creado el
  cycle_of_document: Ciclo del documento
  delete: Eliminar el documento
  create_new_version: ¿Crear una nueva versión?
  publish_document: Publicar el documento
  create_new_document: ¿Confirmar el guardado?
  create_new_version_message: Está a punto de cambiar de versión al guardar este documento
  create_new_document_message: Está a punto de crear la primera versión de este nuevo documento.
  archive: Archivar el documento
  delete_confirmation_message: >-
    ¿Está seguro de que desea eliminar el documento? Esta acción es
    irreversible.
  archive_confirmation_message: >-
    ¿Está seguro de que desea archivar el documento?
  document_creation_message: El documento ha sido creado con éxito
  document_delete_message: El documento ha sido eliminado con éxito
  document_archive_message: El documento ha sido archivado con éxito
  document_archive_title: Documento archivado
  document_update_error_message: Se produjo un error al guardar el documento
  document_update_message: El documento se ha guardado con éxito
  expiration_date: Fecha de vencimiento
  file: Enlace
  file_extension_error: La extensión no está permitida
  finish: Finalizar la edición del documento
  finish_confirmation_message: ¿Está seguro de que ha terminado de completar este formulario?
  format: Formato
  frequency: Frecuencia
  input_frequency: Frecuencia de entrada
  input_frequency_activate: Activar frecuencia de entrada
  get_more: Cargar + { count } documento | Cargar + { count } documentos
  load_more: Más documentos
  last_updated_on: Última modificación el
  lifecycle: Ciclo de vida del documento
  measures: Medida
  name: Nombre
  new_document: Nuevo documento
  no_files: No hay archivos disponibles
  not_found: No se encontraron registros
  number_of_columns: Número de columnas
  order: Orden
  cant_finish_document: Todavía faltan entradas por completar
  cant_finish_document_description: No puedes finalizar este documento sin completar las celdas obligatorias (no opcionales). Sin embargo, puedes ingresar "N/A" en las celdas pertinentes o informarlas.
  cant_finish_document_description_mandatory_lines: 'Las siguientes líneas son obligatorias:'
  cells_missing: '{number} celda(s) incompleta(s)'
  schedule:
    minute: minuto | minutos
    hour: hora | horas
    day: día | días
    end: Fin
    month: Mes
    no_end: Sin fecha de finalización
    no_schedule: Sin repetición
    no_start: Sin fecha de inicio
    publication_date: Fecha de publicación
    as_soon_as_the_publication: Tan pronto como se publique
    repeat_each: Repetir cada
    repeat_each_feminine: Repetir cada
    repeat: Repeticiones
    recurrence: Recurrencia
    specific_recurrence: Recurrencia específica
    set_hour: Establecer hora
    set_hour_error: La hora de finalización debe ser mayor que la de inicio.
    recurrence_from: Desde
    recurrence_to: a
    document_is_accessible: El documento será accesible
    start: Inicio
    available_from: Disponible desde
    availablity: Disponibilidad
    start_at: Inicia el
    end_at: Termina el
    week: Semana | Semanas
    year: Año | Años
    every_m: Todos los
    every_f: Toda | Todas
    every_with_article: Todos los
    every_day: Cada {nb} días
    from_date: desde el {date}
    until_date: hasta el {date}
    every_week: Cada {nb} semanas
    every_week_article: Cada
    every_week_days: Cada {days}
    every_month: Cada {nb} meses
    every_year: Cada {nb} años
    the_m: el | los
    the_f: la | las
    permanently_available: Disponible permanentemente
    as_soon_as: Tan pronto como
    no_starting_date: Sin fecha de inicio
    not_recognized: Recurrencia no reconocida
  enter: Ingresar
  scheduling: Programación
  scheduling_subtitle: Planifique la visualización del documento en el espacio de operaciones
  search: Buscar un documento
  select_application_fields: Seleccionar campos de aplicación
  start_date: Fecha de inicio
  start_to_create_a_new_document: Comenzar a crear un nuevo documento
  state: Estado
  status_archived: Archivado
  status_in_review: En revisión
  status_deleted: Eliminado
  status_draft: Borrador
  status_finished: Finalizado
  status_in_progress: En progreso
  status_production_validée: Producción validada
  status_revu_quali_en_cours: Revista de calidad en curso
  status_lot_libéré: Lote liberado
  status_new: Nuevo
  status_published: Publicado
  status_select: Seleccionar
  step_check: OK/NOK
  step_checkbox: Casilla de verificación
  step_link: Enlace
  step_measure: Medida
  step_options_list: Lista de opciones
  step_text: Texto
  step_time: Hora
  step_file: Archivo
  step_number: Número
  step_photo: Foto
  step_photo_take_picture: Tomar una foto
  step_photo_take_picture_description: Utilice su cámara para tomar una foto. Haga clic en "Tomar" para guardar la foto y prévisualícela durante 2 segundos antes de que se cierre automáticamente la ventana modal.
  step_photo_take_picture_again: Tomar la foto de nuevo
  step_photo_update_picture: Actualizar la foto
  step_photo_update_picture_description: Puede actualizar su foto existente utilizando la cámara. Haga clic en "Tomar una foto" para guardar la nueva foto; se mostrará durante 2 segundos y la ventana emergente se cerrará automáticamente, reemplazando la foto anterior.
  step_photo_preview_picture: Vista previa de la foto
  step_photo_no_devices_found: No se encontraron dispositivos. Conecte una cámara USB, por favor.
  step_photo_no_camera_access: El acceso a la cámara está desactivado. Autorice el acceso en la configuración de su navegador.
  multiple_selections: Selección múltiple
  title: Mis documentos
  to_fill: Por completar
  trigger: Desencadenante
  trigger_element: Elemento desencadenante
  starting_at: Comienza en
  update_research: Modificar mi búsqueda
  updated_by: Actualizado por
  updated_on: Actualizado el
  you_have_completed: Ha completado
  workflow: Ciclo de vida
  confirm_archive_in_document:
    title: Confirmar archivo
    description: Está a punto de archivar este documento. Una vez archivado, este documento ya no estará disponible para su uso en el OF. ¿Está seguro de que desea continuar?
  confirm_archive_many_documents:
    title: Confirmar archivo
    description: Está a punto de archivar 1 documento. Este documento ya no podrá utilizarse en los OF. | Está a punto de archivar {count} documentos. Estos documentos ya no podrán utilizarse en los OF.
export:
  attachments_of_document: Archivos_adjuntos_del_documento
  change_target: Cambio de objetivo
  columns_count: Número de columnas
  download_is_starting: Su descarga comenzará en breve...
  download_error: Se produjo un error al conectar al servidor.
  document_export_error: Se produjo un error al preparar su documento.
  export_document_as_pdf: Exportar el documento como PDF
  historic_value: Valor histórico
  justification: Justificación
  report_title: Título del informe
  export_type: Tipo de exportación
  status: Estado
  tags: Etiquetas
  type: Tipo
  modified_by: Modificado por
  modified_at: Modificado el
  name: Nombre
  step_type: Tipo de paso
  description: Descripción
  tool: Herramienta de medición
  goal: Objetivo
  min: Mínimo
  max: Máximo
  unit: Unidad
  at: en
  operator: Operador
  update_date: Fecha de actualización
  value: Valor
format:
  fixed: Fijo
  formula: Fórmula
  quantity: Cantidad
  unique: Único
  unlimited: Ilimitado
filters:
  activities: Actividades
  assignee: Asignado a
  assignation: Asignación
  blocker: Bloqueante
  blockers: Bloqueantes
  raised_by: Generado por
  add: Agregar filtros
  choose_filter: 'Elegir un filtro'
  categories: Categoría del documento
  go_back: Volver a las opciones de filtros
  display: Mostrar
  display_columns: Columnas mostradas...
  at_least_one_display_column: Debe seleccionar al menos una opción
  group_by: Agrupar por
  date: Fecha
  documents: Documentos
  models: Plantillas
  products: Producto
  production_orders: Órdenes de producción
  role: Rol de usuario
  title: Filtros
  teams: Equipos
  search: Buscar un filtro...
  status: Estado
  today: Hoy
  yesterday: Ayer
  no_grouping: Ninguno
  last_24_hours: Últimas 24 horas
  last_3_days: Últimos 3 días
  last_7_days: Últimos 7 días
  last_14_days: Últimos 14 días
  last_month: Último mes
  users: Operadores
  workplaces: Puestos de trabajo
  filter_by: Filtrar por
  filter: Filtrar
  no_product: Sin producto
  no_workplace: Sin puesto de trabajo
  select_a_date: Seleccionar una fecha
  templates: Plantillas
  remove_all: Eliminar todo
  operations: Operaciones
  operation: Operación
  product: Producto
  workplace: Puesto de trabajo
  work_order: Orden de trabajo
global:
  bread_crumb: 
    documentos: documents
    plantillas: templates
    simulacion: simulation
  element_not_found: Elemento no encontrado
  if: Si
  then: Entonces
  group: grupo | grupos
  roles: Roles
  change_language_to_apply: Se recomienda encarecidamente actualizar la página para aplicar los cambios
  unknown: Desconocido
  audit_logs: Registros de auditoría
  all_the_of: Todos los OF
  people: '{amount} personas'
  simulation: Simulación
  choice: elección
  element: Ningún elemento | {number} elemento | {number} elementos
  current_language: es
  additional_informations: Información adicional
  no_value: Sin valor
  account_and_access: Usuarios
  permissions: Roles y permisos
  integration_parameters: Integración
  import_export_parameters: Importar/Exportar
  select_type: Seleccionar un tipo
  please_select_type: Seleccione un tipo, por favor
  confirm_import: Confirmar la importación de datos
  confirm_import_desc: La importación de este archivo actualizará los datos de clientes previamente importados en JUNO
  workflow_parameters: Parámetros del ciclo de vida
  status_management: Gestión de estados
  site_management: Gestión de sitios
  add: Agregar
  add_user: Agregar un usuario
  network_error: Sin conexión a Internet
  add_a_attachment_file: Agregar un archivo adjunto
  alert: Alerta | Alertas
  alerts: Alertas
  alerts_success: ¡Alerta creada con éxito!
  all: Todo
  analyses: Análisis
  application_fields: Campos de aplicación
  archive: Archivar
  are_you_sure_you_want_to_logout: ¿Está seguro de que desea cerrar la sesión?
  dashboard: Tablero de control
  disconnected: Ha sido desconectado
  disconnected_for_inactivity: Debido a una inactividad prolongada, ha sido desconectado
  understood: Entendido
  are_you_sure_you_want_to_delete_user: ¿Está seguro de que desea eliminar a este usuario?
  at: en
  and: y
  attachment: Archivo adjunto
  attachments: Archivos adjuntos
  attention_any_unsaved_document_will_be_lost: Atención, cualquier documento no guardado se perderá
  back_home: Volver a la página de inicio
  cancel: Cancelar
  click_button_reload: Haga clic en "Recargar" para acceder a ella.
  client: Cliente
  clients: Clientes
  close: Cerrar
  complete: Completar
  content: Contenido
  copy_of: Copia de
  create: Crear
  created_by: Creado por
  created_on: Creado el
  created_at: Creado el
  define_as_a_template: Definir como plantilla
  description: Descripción
  display: Mostrar
  document: documento | documento | documentos
  integrations: Integraciones
  document_parameters: Parámetros del documento
  operator_interface: Interfaz del operador
  steps_parameters: Parámetros de los pasos
  notification_parameters: Parámetros de notificación
  ftp_parameters: Parámetros de FTP
  filter: Filtro | Filtros
  documents: Documentos
  download: Descargar
  download_as_pdf: Descargar como PDF
  duplicate: Duplicar
  edit: Editar
  email: Correo electrónico
  empty_list: No se encontraron registros
  enriched_export: Exportación enriquecida
  enriched: Enriquecido
  error: Error
  export: Exportar
  loading: Cargando...
  first_name: Nombre
  files: Archivos del puesto
  finished: Finalizado
  forbidden: Acceso prohibido a esta página
  forbidden_message: Póngase en contacto con su administrador para verificar el acceso
  from: desde
  hello: Hola
  history: Historial
  actions: Acciones
  import: Importar
  inProgress: En progreso
  in_progress: En progreso
  resolved: Resuelto
  justification: Justificación
  last_modification: Última modificación
  last_name: Apellido
  link: Enlace
  list: Lista
  login: Iniciar sesión
  logout: Cerrar sesión
  mandatory: Obligatorio
  mark_as_read: Marcar todo como leído
  media_library: Biblioteca de medios
  monitoring: Supervisión
  month: Mes
  my_dashboards: Mis tableros de control
  name: Nombre
  name_document: Nombre del documento
  need_reset_password: ¿Necesita restablecer su contraseña?
  force_reset_password: Está iniciando sesión por primera vez. Por favor, restablezca su contraseña.
  new: Nuevo
  new_version: Hay una nueva versión de Juno disponible
  no: No
  for: Para
  next_check_at: Próxima verificación en el informe {reportName}, en el paso {step}
  number_of_steps: >-
    {number} paso | {number} pasos
  ok: OK
  operation: Operación
  optional: Opcional
  out_of: de
  parameters: Parámetros
  password: Contraseña
  row: Fila | Filas
  new_password: Nueva contraseña
  current_password: Contraseña actual
  confirm_password: Confirmar contraseña
  confirm_password_validation_label: Ambas contraseñas
  confirmation_password: Confirmación de contraseña
  change_password: Cambiar contraseña
  both_passwords_dont_match: Ambas contraseñas no coinciden
  password_has_changed: Su contraseña ha sido cambiada
  password_update_error_message: Se produjo un error al cambiar la contraseña
  percentage: porcentaje de tolerancia
  picture: Imagen
  preview: Vista previa
  product: Producto
  publish: Publicar
  range: rango
  reason: Razón
  reload: Recargar
  refresh: Refrescar
  remove_attachment: Eliminar archivo adjunto
  report: Informe | Informes
  reports: Informes
  reset_email_sent: >-
    Se ha enviado un correo electrónico de restablecimiento de contraseña.
    Haga clic en el enlace cuando lo reciba
  restore: Restaurar
  revision: Revisión
  role: Rol
  save: Guardar
  search_means_of_measurement: Buscar...
  search_unit: Buscar una unidad...
  update: Actualizar
  delete: Eliminar
  finish_entry: Finalizar mi entrada
  finish_document: Finalizar el documento
  save_preferences: Guardar preferencias
  search: Buscar
  search_assignee: Asignar a
  seeDescription: Ver descripción
  seeAll: Ver todo
  select: Seleccionar
  select_tab: Seleccione una pestaña
  send_reset_email: Enviar correo electrónico de restablecimiento
  settings: Configuración
  sign_out: Cerrar sesión
  simple_export: Exportación simple
  sites: Sitios
  start: Comenzar
  startTask: Comenzar una tarea
  view: Ver
  success: Éxito
  summary: Resumen
  switch_to_operator: Espacio de operador
  switch_to_supervisor: Espacio de diseño
  tags: Etiqueta
  time: Tiempo
  title: Título
  to_consult: Para consultar
  other: Otro
  today: Hoy
  total_report: Informes totales
  user: Usuario
  users: Usuarios
  user_session_management: Gestión de la sesión de usuario
  validate: Validar
  workplace: Puesto de trabajo
  yes: Sí
  you_are_offline: Actualmente está sin conexión. Por favor, vuelva a conectarse.
  your_profile: Su perfil
  template: Plantilla
  templates: Plantillas
  template_messages_parameters: Parámetros de mensajes de plantilla
  confirm: Confirmar
  library: Biblioteca
  unlink_step: ¿Está seguro de que desea desvincular este bloque de su plantilla?
  confirm_modification: ¿Confirmar las modificaciones?
  unlink_step_message: "Editar este bloque implica que no se podrá actualizar si rompe el vínculo con la siguiente plantilla:"
  warning: Advertencia
  workflows: Flujos de documentos
  allTeams: Todos los equipos
  selectTeam: Seleccione un equipo
  beforeOrAfter: Antes o después
  minutes: minutos
  total_parts: Total de piezas
  total_of_controles: Total de controles
  nb_of_boxes: Número de cajas
  label: Etiqueta
  all_the_documents: Todos los documentos
  all_the_templates: Todas las plantillas
  modeles: plantillas
bread_crumb:
  documents: Todos los documentos
  templates: Todos los modelos
  views: Todas las vistas
  reports: Todos los informes
  new_view: Nueva vista
  simulation: Simular una orden de producción
global_fields:
  created_by: Creado por
  created_on: Creado el
history:
  history: Historial
  created: reportó la alerta
  assigned: asignó la alerta a
  commented: comentó
  updated: cambió el estado de la alerta
  and_more: y {amount} más...
intro:
  aka: También conocido como
  desc: Modelo de inicio de Vite Opinionated
  dynamic-route: Demo de ruta dinámica
  hi: "Hola, {nombre}\_!"
  whats-your-name: ¿Cuál es tu nombre?
insights:
  valid_control_points: Puntos de control válidos
  valid_controls: Controles válidos
  valid_blocks: Bloques válidos
jobs:
  choose_workplace: Elija su lugar de trabajo
  no-result: No hay resultados para esta búsqueda
  select-of: Por favor, seleccione una orden de producción
  select_product: Por favor, seleccione un producto
  choose_workplace_desc: >-
    Seleccione su lugar de trabajo para mostrar las órdenes de producción
  current_jobs: Panel de control
  last_30_days: Órdenes de producción de los últimos 30 días
  last_10_days: Órdenes de producción de los últimos 10 días
  not_found: No se encontró ninguna orden de producción abierta
  number: Número de orden de producción
  operation: Número de operación
  start_new: Iniciar una orden de producción
  start_new_job_desc_no_jobs: Abra una orden de producción haciendo clic en el botón en la parte superior derecha de la pantalla
  status_finished: Terminado
  status_finished_message: La orden de producción ha sido finalizada
  status_in_progress: En progreso
  status_new: Nueva
  no_product_order_selected: No se encontró ninguna orden de producción. Haga clic para elegir un producto
  create_production_order: Crear esta orden de producción
  production_order_doesnt_exist: ¡Esta orden de producción no existe!
languages:
  fr: Francés
  french: Francés
  en: Inglés
  english: Inglés
  de: Alemán
  german: Alemán
  es: Español
  spanish: Español
  it: Italiano
  italian: Italiano
media_library:
  add: Importar archivo
  archive_confirmation_message: ¿Estás seguro de que deseas archivar este archivo?
  delete_confirmation_message: ¿Estás seguro de que deseas eliminar este archivo?
  delete: Archivar archivo
  media_parameters: Biblioteca multimedia
  no_data: No se ha importado ningún archivo, haga clic en el botón de importar para agregar sus archivos
  no_search_result: No se encontró ningún archivo que coincida con su búsqueda
  no_file_yet: Agregue sus archivos aquí para importarlos a JUNO
  search_file: Buscar en su computadora
  staged_file: archivo seleccionado
  staged_files: '1 archivo seleccionado | %{count} archivos seleccionados '
  show: Mostrar
  tab_archived: Archivados
  tab_files: Archivos disponibles
  file_update_error_message: Se produjo un error al conectarse al servidor, inténtelo de nuevo.
  update_file: Reemplazar archivo
  upload: Importar
  upload_files: Importar archivos
  uploaded_files: '1 archivo importado con éxito | %{count} archivos importados con éxito '
  failed_upload: Algunos archivos no se pudieron cargar debido a un error
not-found: No encontrado
notifications:
  activate_feature: Activar o desactivar
  alert_type: Notificaciones de alerta
  reminder_type: Notificaciones de recordatorio
  view: Ver notificación
  go_to_task: Ir a la tarea
  controls_needed: control que necesita realizarse | controles que necesitan realizarse
  undefined_document: Documento sin nombre
pages:
  alerts: Todas las alertas
  alerts_list: Todas las alertas
  analytics: Análisis
  document: Documentos
  form: Formularios
  history: Historial
  media_library: Biblioteca multimedia
  monitoring: Supervisión
  reports: Todos los informes
  settings: Configuración
  template: Plantillas
  library: Biblioteca
  views: Vistas
simulation:
  new_simulation: Nueva simulación
  empty_simulation: Actualmente no tienes simulaciones. Puedes comenzar a crear una.
profil:
  my-profile: Mi perfil
  description: Administra toda la información personal relacionada con tu perfil de JUNO
  email: Correo electrónico
  language: Idioma
  security: Seguridad
  trigram: Identificación de la planta
  not_specified: No especificado
repetition:
  additional_filter_nature: Naturaleza
  select: Seleccionar repetición
  add: Crear repetición
  event: Evento
  formula: Fórmula
  frequency: Frecuencia
  no_repetition_available: No hay repetición disponible
  repetitions: Repeticiones
  repetition_exists_message: Esta repetición ya existe
  sampling: Muestreo
  notification: Notificación
  times: Veces
  load_more: Cargar más
  search: Buscar
  reset: Restablecer
  save: Guardar
  create_sampling: Crear muestreo
  create_formula: Crear fórmula
  select_formula: Seleccionar fórmula
  formula_type: Indicar tipo de fórmula
  repeat_every: Repetir cada
  created_successfully: La repetición se ha creado con éxito
  created_successfully_message: Ahora puedes comenzar a usar la nueva repetición en tus documentos
  creation_error: Se produjo un error inesperado
  creation_error_message: Se produjo un error inesperado al crear tu repetición.
report:
  target: Objetivo
  dynamic_target: Objetivo dinámico
  controls: Controles
  last_update: Última actualización el
  execution_date: Fecha de ejecución
  all_day: Todo el día
  category: Categoría
  checkbox: Pendiente
  control: Control
  document: Documento
  dynamic: Dinámico
  static: Estático
  date: Fecha
  done: Completado
  status_history: Historial de estados
  filter_last_day: Último día
  filter_last_3_days: Últimos 3 días
  filter_last_7_days: Últimos
  filter_last_month: Último mes
  filter_valid: Válido
  filter_invalid: No válido
  is_valid: Estado
  no_rows_to_show: Problema al mostrar los datos
  job: Orden de producción
  job_abbreviation: OP
  measure: Medida
  no_steps: Este archivo está vacío
  operator: Operador
  product: Producto
  raise_event: Informar un evento
  report_update_message: El formulario se ha guardado con éxito
  select_option: Seleccionar opción
  see_alert: Ver alerta
  status: Estado
  status_finished: Terminado
  status_in_progress: En progreso
  status_new: Nuevo
  step_answer: Respuesta
  step_answer_state: Estado
  step_name: Nombre
  step_number: Paso
  view_report: Ver informe
  text: Texto
  update_date: Fecha de actualización
  work_order: Orden de producción
  workplace: Lugar de trabajo
  cell_history: Historial de celdas
  cell_history_desc: Este campo ha sido modificado por uno o varios usuarios
  new_data: Nuevos datos
  enter_value: Ingresar valor...
  modify_data: Modificación de un campo previamente ingresado
  restore_data_title: ¿Realmente quieres restaurar estos datos?
  restore_data: Restaurar la entrada
  restore_data_desc: Estos datos se ingresaron anteriormente antes de ser reemplazados por entradas más recientes.
  justify_ko_title: Justificación de no conformidad
  justify_ko_desc: Por favor, justifique el valor de la celda siguiente para continuar la entrada en este documento
  modify_data_error: No todos los campos se completaron correctamente
  modify_data_success: Los nuevos datos se han guardado correctamente
  modify_data_desc: Otro usuario ingresó datos en esta celda. Estos nuevos datos se actualizarán en las tablas de todos los participantes en el documento.
  justification: Justificación
  precise_reasons: Especifique las razones de esta modificación...
  downloading: Descargando...
  downloading_message: ¡Espere mientras preparamos su documento para la descarga! | ¡Espere mientras preparamos sus documentos para la descarga!
  download_finished: Su documento está listo | Sus documentos están listos
  download_finished_message: Ábralos ahora
  download_failed: Error
  download_failed_message: Se produjo un error al preparar su documento. | Se produjo un error al preparar sus documentos.
  download_limit_reached: Límite de descarga de 20 informes
  limit_reached: Ha alcanzado su límite, ajuste su búsqueda.
session:
  automatic_reports: Informes automáticos
  ongoing_session: Sesión en curso
  ongoing_session_message: Estás a punto de iniciar una sesión que ya comenzó
  search_po: Buscar orden de fabricación
  refine_search: Refina tu búsqueda para mostrar las órdenes de fabricación relacionadas
  workorder_doesnt_exist: Esta orden de fabricación no existe.
  create_workorder: Crear una nueva orden de fabricación
  select_product: Selecciona el producto
  create_operation: Crear una nueva operación
  product_requiered: Selecciona al menos un producto para continuar
  of_name_requiered: Ingresa un nombre para tu orden de fabricación
  operation_requiered: Crea al menos una nueva operación
  all_documents_not_complete: No todos tus documentos están completos
  all_documents_not_complete_desc: No puedes finalizar la operación hasta que todos los documentos obligatorios estén completos
  launch_on: Iniciar en
  update: Actualizar
settings:
  no_user_found: No se encontraron usuarios
  users: Usuarios
  new_user: Nuevo usuario
  members: Miembros
  name: Nombre
  search_user_placeholder: Buscar y agregar nuevos miembros
  create_group: Crear grupo
  update_group: Modificar grupo
  profile: Perfil
  users_and_access: Cuentas y acceso
  mandatory_column: Este campo es obligatorio
  value_exists: Este nombre ya está en uso
  workflow: Ciclo de vida
  groups: Grupos
  audit_logs:
    name: Cuenta asociada
    action_hour: Hora de la acción
    action: Acción
    get_more: Cargar + {count} entrada | Cargar + {count} entradas
    load_more: Más entradas
    login: Inicio de sesión
    logout: Cierre de sesión
    reactivate: Reactivar
    deactivate: Desactivar
  status_name: Nombre
  justify_ko: Justificación de NC
  status_icon: Icono
  status_color: Color
  status_saved: El estado se ha guardado
  create_status: Crear estado
  new_status: Estado sin nombre
  save_status: Guardar estado
  conception_rights: Derechos de diseño
  decimal_values: Valores decimales
  number_of_decimals: Número de decimales
  same_as_tolerance: Igual que la tolerancia
  alerts: Alertas
  input_modification: Modificación de entrada
  message: Contenido
  additional_data: Datos adicionales
  operator_session: Sesión
  input_parameters: Entradas
  lookup_keys_error: La configuración del archivo no coincide con las claves de este tipo. Ponte en contacto con tu jefe de operaciones.
  parameter_name: Nombre
  parameter_value: Valor
  maximum_inactivity_time: Tiempo máximo de inactividad antes de desconectar
  enabled: Habilitado
  disabled: Deshabilitado
  cancel: Cancelar
  color: Color
  team_name: Nombre del equipo
  get_more_teams: Cargar + {count} equipo | Cargar + {count} equipos
  load_more_teams: Más equipos
  formula: Fórmula
  decimal_number: Número decimal
  precise_decimal_number: Especificar el número decimal
  reasons: Justificación de modificación
  add_option: Ingresa tu opción y presiona Enter
  create_setting_success_message: La configuración se ha creado correctamente
  settings_error: Se produjo un problema al conectarse al servidor
  create_ftp_success_message: El servidor FTP se ha registrado correctamente
  default: Predeterminado
  document_status: Estado del documento
  report_status: Estado del informe
  delete: Eliminar configuración
  delete_confirmation_message: ¿Seguro que deseas eliminar la configuración? Esta acción no se puede deshacer.
  delete_option: Eliminar opción
  delete_option_confirmation_message: ¿Seguro que deseas eliminar esta opción? Esta acción no se puede deshacer.
  delete_setting_success_message: La configuración se ha eliminado correctamente
  document_category: Categoría de documento
  get_more_document_category: Cargar + {count} categoría | Cargar + {count} categorías
  load_more_document_category: Más categorías
  ending_time: Hora de finalización
  frequency: Frecuencia
  grid_header: Encabezado de cuadrícula
  get_more_grid_header: Cargar + {count} encabezado | Cargar + {count} encabezados
  load_more_grid_header: Más encabezados
  is_selected: Seleccionado
  list_options: Opciones de lista
  get_more_list_options: Cargar + {count} lista de selección | Cargar + {count} listas de selección
  load_more_list_options: Más listas de selección
  list_type: Tipo de lista
  list_value: Valor de opción
  lookup_tables: Tabla de asignación
  means_of_measure: Medio de medida
  get_more_means_of_measure: Cargar + {count} medio de medida | Cargar + {count} medios de medida
  load_more_means_of_measure: Más medios de medida
  media_library: Habilitado
  measure_unit: Unidad de medida
  order: Orden
  products:
    export: Exportar lista de productos en formato CSV
    import: Importar un archivo CSV
  workplaces:
    export: Exportar lista de puestos de trabajo en formato CSV
    import: Importar un archivo CSV
  blocker: Bloqueador
  roles_access: Accesible por
  mandatory_finish: Entrada obligatoria
  organization: Organización
  product: Producto
  roles: Roles y permisos
  roles_new: Nuevo rol
  roles_create: Agregar rol
  roles_edit_name: Editar nombre
  roles_delete_role: Eliminar
  roles_read: Consultar
  roles_write: Modificar
  roles_change-status: Cambiar estado
  roles_delete: Archivar
  roles_ressource: Recurso
  roles_cannot_delete_title: No se puede eliminar el rol
  roles_cannot_delete_description: '{amount} usuario(s) tienen este rol. Asigna un rol diferente o elimínalo primero.'
  roles_confirm_delete_title: ¿Confirmar eliminación?
  roles_confirm_delete_description: Al confirmar, el rol se eliminará definitivamente. Esta acción no se puede deshacer.
  repetition: Repetición
  sampling: Muestreo
  step_tag: Etiqueta
  get_more_step_tag: Cargar + {count} etiqueta | Cargar + {count} etiquetas
  load_more_step_tag: Más etiquetas
  get_more_messages: Cargar + {count} mensaje | Cargar + {count} mensajes
  load_more_messages: Más mensajes
  get_more_workflow_models: Cargar + {count} ciclo de vida | Cargar
  load_more_workflow_models: Más modelos
  repetition_type: Tipo de repetición
  starting_time: Hora de inicio
  team: Equipo
  theTeam: El equipo
  tolerance: Tolerancia
  enable_tolerance: Activar tolerancia en las medidas
  value_tolerance: Valor de tolerancia
  type: Tipo
  unit: Unidad
  units_of_measure: Unidades de medida
  update_password_message_title: ¿Confirmar cambio de contraseña?
  update_password_message_description: El usuario deberá iniciar sesión nuevamente con la nueva contraseña
  get_more_units_of_measure: Cargar + {count} unidad de medida | Cargar + {count} unidades de medida
  load_more_units_of_measure: Más unidades
  update_setting_success_message: La configuración se ha modificado correctamente
  update_setting_error_message: Se ha producido un error. La configuración no se ha modificado.
  update_setting_tolerance_error_message: No puedes modificar la tolerancia
  value: Valor
status:
  created: El estado se ha creado correctamente
  updated: El estado se ha actualizado correctamente
  deleted: El estado se ha eliminado correctamente
  status: Estado
  statuses: Estados
step:
  add_file: Agregar un archivo
  answer_nok: Nok
  answer_ok: Ok
  boolean: OK / NOK
  checkbox: Casilla de verificación
  description_placeholder: Ingresar una descripción
  multiple_selections: Selección múltiple
  customized_answer: Respuesta personalizada
  create_answer: Crear respuesta
  done: Hecho
  file: Archivo
  file_upload_issue: Se ha encontrado un problema al cargar el archivo
  link: Enlace
  list: Selección
  select_condition_item_in_list: Seleccionar una respuesta...
  select_condition_step_in_list: Seleccionar bloques a mostrar...
  uncertainty: Incertidumbre
  mandatory: Obligatorio
  means: Medio de medida
  measure: Medida
  measure_max: Máximo
  measure_min: Mínimo
  measure_target: Objetivo
  margin: Margen
  set_value: Valor definido
  percentage: Porcentaje
  absolute_value: Valor absoluto
  measure_tolerance: +/- 
  measure_unit: Unidad
  name_placeholder: Ingresar un nombre
  napossible: No aplicable
  take: Veces
  delete_step_title: Imposible borrar bloque
  delete_step_description: No se puede borrar este bloque ya que depende de un bloque condicional.
  add_condition: Agregar una condición
  no_step_available: No hay bloques disponibles
trigger:
  day: Día
  production_order: Orden de fabricación
  team: Equipo
user:
  unknown_creator: Creador desconocido
  personal_information: Información personal
  role: Rol
  role_attribution_mandatory: La atribución de un rol es obligatoria
  email: Correo electrónico
  updated: El usuario ha sido actualizado
  reactivate: Reactivar
  desactivate : Desactivar
  reactivated_successfully: Esta cuenta ha sido reactivada
  deactivated_successfully: Esta cuenta ha sido desactivada
  confirmation_title_activate_user: ¿Estás seguro de querer reactivar la cuenta de {name}?
  confirmation_title_desactivate_user: ¿Seguro que quieres desactivar la cuenta de {name}?
  confirmation_description_activate_user: El usuario tendrá acceso nuevamente a JUNO.
  confirmation_description_desactivate_user: El usuario ya no podrá acceder a JUNO una vez que su cuenta esté desactivada.
  disabled_at: Desactivado el
  reactivate_account: Reactivar la cuenta
  disable_account : Desactivar la cuenta
  active: Activo
  disabled: Desactivado
  status : Estado
  by: por
  language: Idioma
  client: Cliente
  site: Sitio
  automatic_deactivation: Desactivación automática
  temporary_account : Cuenta temporal
  temporary: Temporal
  disabled_automatically_at: Desactivado automáticamente el
  edit_information : Editar información
  password: Contraseña
  modify_password: Modificar la contraseña
groups:
  name: Nombre
  created_at: Fecha de creación
  users: Usuarios
  load_more_groups: Más grupos
  placeholder: Selecciona personas o grupos a alertar
  no_result: No se encontraron resultados.
validations:
  document_category: La categoría no puede estar vacía
  document_description: El campo de descripción es obligatorio
  document_name: El campo de nombre es obligatorio
  minimum_characters : Ingresa al menos {length} caracteres
template:
  archive: Archivar la plantilla
  create: Nueva plantilla
  search: Buscar plantilla
  template_name: Nombre de la plantilla
  new_template: Nueva plantilla
  delete_confirmation_message: ¿Seguro que quieres archivar esta plantilla?
  template_update_error_message: Se ha producido un error al guardar la plantilla
  template_update_documents_error_message: Algunos documentos no se actualizaron correctamente, inténtalo de nuevo.
  template_update_message: La plantilla se ha modificado correctamente
  archive_template: Archivar
  unlink_template: Desvincular
  modify: Modificar plantilla
  publish_template: Publicar
  reference: Referencia
  template_used_reference: "Cualquier modificación en la plantilla es irreversible y afectará los siguientes documentos:"
  template_delete_used_reference: "Archivar la plantilla es irreversible y afectará los siguientes documentos:"
  are_you_sure_edit: La modificación de esta plantilla afectará todos los documentos asociados, ¿seguro que deseas continuar?
  get_more: Cargar + { count } plantillas
  load_more: Más plantillas
users:
  readonly_mode: Modo de solo lectura
  get_more: Cargar + { count } usuario | Cargar + { count } usuarios
  load_more: Más usuarios
workorder:
  complete: Completar la operación
  confirm_modal_title: Completar y cerrar la operación en curso
  confirm_modal_description: Estás a punto de completar la operación, ¿has completado todos tus documentos?
views:
  add: Crear vista
  title: Supervisión
  name: Nombre de la vista
  description: Descripción de la vista
  filters: Filtros activos
  no_filters_selected: Ningún filtro seleccionado
  no_group: Ninguno
  save: Guardar vista
  delete: Borrar vista
  new_vue_template_title: Nueva vista
  new_vue_template_description: Ingresa una descripción aquí
  saved_views: Vistas guardadas
  save_view: Guardar
  view_all: Ver todo
  view_created: Se ha creado una nueva vista con éxito
  view_updated: La vista se ha modificado con éxito
  view_delete: La vista se ha eliminado con éxito
  error_create: Se produjo un error al crear la vista
  error_update: Se produjo un error al guardar la vista
  error_delete: Se produjo un error al eliminar la vista
  error_empty_title: El título no puede estar vacío
  delete_confirmation_message: ¿Estás seguro de querer eliminar esta vista?
  delete_modal_title: Eliminar vista
  get_more_reports: Cargar + { count } informe | Cargar + { count } informes
  load_more_reports: Más informes
actions:
  new_goal: Nuevo objetivo
  alerts: Alertas
  quick_actions: Acciones rápidas
  report_alert: Informar
  pin_column: Fijar
  unpin_column: Desfijar
  report_alert_description: "Especifica los detalles de la notificación y selecciona a las personas para recibir alertas:"
workflows:
  add: Agregar paso
  description: Descripción
  associated_status: Estado asociado
  associated_permissions: Permisos asociados
  create: Crear ciclo de vida
  created: El ciclo de vida se creó
  updated: El ciclo de vida se actualizó con éxito
  error: Se produjo un error al crear/actualizar el ciclo de vida
  error_inputs: Completa los campos obligatorios para crear el nuevo paso
  add_description: Agregar descripción
  name: Nombre del ciclo de vida
  built_in: Ciclos de vida predefinidos
  customizable: Ciclos de vida personalizables
  new_workflow_title: Nuevo ciclo de vida
  not_found: Ningún ciclo de vida encontrado
  not_found_description: Crea un nuevo ciclo de vida haciendo clic en el botón en la esquina superior derecha de la pantalla
  save: Guardar ciclo de vida
  select_workflow: Seleccionar ciclo de vida
  no_workflow: Sin ciclo de vida
  select_workflow_type: Selecciona un tipo de ciclo de vida para crear uno nuevo
  unnamed: Ciclo de vida sin nombre
  search_status: Buscar estado...
  select_option: Seleccionar estado
  templates: Plantillas
  title: Ciclo de vida
  default: Ciclo de vida predefinido
  type: Tipo
  type_select: Seleccionar tipo
  type_documents: Documento
  type_reports: Informe
  type_alerts: Alerta
  type_operations: Operación
  type_of: Orden de fabricación
  default_document_step_1: Documento en proceso de edición
  default_document_step_2: Documento publicado
  default_report_step_1: Nuevo informe
  default_report_step_2: Informe en proceso
  default_report_step_3: Informe completado
  default_alert_step_1: Nueva alerta
  default_alert_step_2: Alerta en proceso
  default_alert_step_3: Alerta gestionada
  default_operations_step_1: Nueva operación
  default_operations_step_2: Operación en proceso
  default_operations_step_3: Completar operación
  default_of_step_1: Nueva orden de fabricación 
  default_of_step_2: Orden de fabricación en proceso
  default_of_step_3: Orden de fabricación completada
firebase:
  wrong_password: Contraseña incorrecta
  wrong_actual_password: Contraseña actual incorrecta
  wrong_email: Correo electrónico incorrecto
  weak_password: La contraseña debe tener al menos 6 caracteres
  too_many_attempts: Tu cuenta está temporalmente desactivada debido a demasiados intentos. Por favor, restablece tu contraseña
  unexpected_error: Se produjo un error inesperado. Por favor, inténtalo de nuevo más tarde
  user_disabled: Tu cuenta ha sido desactivada
operator:
  search_workplace: Buscar puesto de trabajo
  sel: Buscar puesto de trabajo
  search_workplace_message: Refina tu búsqueda para mostrar los puestos de trabajo asociados
  select_all_workplaces: Seleccionar todos los puestos de trabajo
  workplaces_recently_opened: Puestos de trabajo abiertos recientemente
  all_workplaces: Todos los puestos de trabajo
  workplace_doesnt_exist: Este puesto de trabajo no existe
  no_workplaces_recently_opened: Aún no has abierto ningún puesto de trabajo
  filter_by_workplaces: Filtrar por puesto de trabajo
  workplace: Puesto de trabajo
error:
  password_policy_not_valid: La contraseña debe tener al menos 8 caracteres y contener letras minúsculas y mayúsculas
  passwords_not_match: Las contraseñas no coinciden
  not_same_old_password: La contraseña no puede ser igual a la antigua
  email_already_in_use: El correo electrónico ya está en uso
welcome_url:
  operator_selection: Selección de puesto de trabajo
  operator_free: Todos los puestos de trabajo
  designer_space: Espacio de diseño
  select_option: Seleccionar...
  operator_space: Espacio de operación
  library: Biblioteca
  supervision: Supervisión
  analytics: Análisis
  space: Espacio
  conception: Diseño
  operation: Operación
  welcome_page: Página de bienvenida