<script setup lang="ts">
import { usersStore } from '~/store/users'
import { redirectToPage } from '~/utils/auth'

const router = useRouter()

const init = async () => {
  const user = usersStore().user
  const redirectTo = await redirectToPage(user)
  if (redirectTo)
    return router.push(redirectTo)

  const welcomePage = usersStore().checkUserRedirection()
  return router.push(welcomePage)
}

init()
</script>

<template>
  <div>
    <h1>{{ $t('analysis.analysis') }}</h1>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
  requiresForcePass: true
</route>

<style>
  input, button, textarea, select, div{
    outline: none !important;
  }
</style>
