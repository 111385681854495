<script setup lang="ts">
import { AvatarSize } from '~/models/Style'
import type { JUser } from '~/models/users/JUser'
import { usersStore } from '~/store/users'

const props = defineProps<{
  userId?: string
  size?: AvatarSize
  editable?: boolean
  fullname?: boolean
  selfUpdate?: boolean
  multiple?: boolean
  textBold: boolean
  textPrimary?: boolean
  forceReload: boolean
}>()

const state = reactive({
  user: {} as JUser,
})

const userInitials = computed(() => {
  const first_name = state.user?.first_name ? state.user?.first_name[0] : ''
  const last_name = state.user?.last_name ? state.user?.last_name[0] : ''
  const initials = `${first_name}${last_name}`.toUpperCase()

  return state.user?.trigramme || initials
})

watch(() => props.forceReload, () => {
  init()
})

watch(() => props.userId, () => {
  init()
})

const init = () => {
  state.user = props.selfUpdate
    ? usersStore().user as JUser
    : usersStore().findUser(props.userId!) as JUser
}

init()
</script>

<template>
  <div
    class="inline-flex items-center"
    :class="{ 'space-x-2': fullname }"
  >
    <div
      class="flex justify-center items-center rounded-full"
      :class="[{
        'w-[120px] h-[120px] text-6xl': size === AvatarSize.LARGE && !state.user?.trigramme,
        'w-[120px] h-[120px] text-5xl': size === AvatarSize.LARGE && state.user?.trigramme,
        'pb-2': !state.user?.picture_url && size === AvatarSize.LARGE,
        'h-8 w-8': size === AvatarSize.MEDIUM,
        'h-8 w-8 text-xxs': size === AvatarSize.MEDIUM && state.user?.trigramme,
        'h-5 w-5 text-xxs': size === AvatarSize.SMALL,
        'h-6 w-6 text-xs': !size && !state.user?.trigramme,
        'h-6 w-6 text-xxs': !size && state.user?.trigramme,
        'border border-white': multiple,
      }, `bg-[${state.user?.color || '#5d669e'}]`]"
    >
      <figure
        v-if="state.user?.picture_url"
        class="relative"
      >
        <img
          class="h-full w-full rounded-full"
          :class="`${editable ? 'cursor-pointer' : 'cursor-default'}`"
          :src="state.user?.picture_url"
        >
        <!-- <figcaption
          v-if="editable"
          class="w-full h-full absolute opacity-0 hover:opacity-100 bg-gray-100/50 transition top-0 rounded-full cursor-pointer"
        >
          <img
            class="w-12 h-12 align-center justify-center translate-x-3/4 translate-y-3/4 cursor-pointer"
            src="https://api.iconify.design/carbon:edit.svg"
          >
        </figcaption> -->
      </figure>
      <span
        v-else
        class="text-white text-center align-middle text-clip cursor-default"
      >
        {{ userInitials?.toUpperCase() }}
      </span>
    </div>
    <span
      v-if="fullname"
      class="text-center"
      :class="{ 'font-medium': textBold, 'text-primary text-sm': textPrimary, 'text-gray-600 text-md': !textPrimary }"
    >
      {{ state.user?.first_name }} {{ state.user?.last_name }}
    </span>
  </div>
</template>
