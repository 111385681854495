export enum AvatarSize {
  MEDIUM = 'md', // Sidebar & Operator Dropdown
  LARGE = 'lg', // Profile
  SMALL = 'sm', // Alert drawer => select assignee
  // Default size everywhere else
}

export enum Colors {
  YELLOW = 'yellow',
  YELLOW_DARK = 'yellow-dark',
  YELLOW_DARK_SECONDARY = 'yellow-dark-secondary',

  ORANGE = 'orange',
  ORANGE_DARK = 'orange-dark',

  PURPLE = 'purple',
  PURPLE_DARK = 'purple-dark',
  PURPLE_DARK_SECONDARY = 'purple-dark-secondary',

  GREEN = 'green',
  ORIENT_GREEN = 'orientgreen',
  GREEN_DARK = 'green-dark',
  GREEN_DARK_SECONDARY = 'green-dark-secondary',

  GREY = 'grey',
  GREY_DARK = 'grey_dark',

  BROWN = 'brown',
  BROWN_DARK = 'brown-dark',
  BROWN_DARK_SECONDARY = 'brown-dark-secondary',

  GRASS_GREEN = 'grass-green',

  SALMON_RED = 'salmon-red',

  WHITE = 'white',
  NARCISUSS_YELLOW = 'narcissus-yellow',
}

export enum StyleSizes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LARGE = 'large',
}

export const bgColors = {
  [Colors.YELLOW]: 'bg-[#FFF8D9]',
  [Colors.YELLOW_DARK]: 'bg-[#FEF0CD] ',
  [Colors.YELLOW_DARK_SECONDARY]: 'bg-[#FCF1D1] ',

  [Colors.ORANGE]: 'bg-[#FFF0E5]',
  [Colors.ORANGE_DARK]: 'bg-[#FAE3D0]',

  [Colors.PURPLE]: 'bg-[#F0F2FF]',
  [Colors.PURPLE_DARK]: 'bg-[#F1E1E9]',
  [Colors.PURPLE_DARK_SECONDARY]: 'bg-[#E1E4FF]',

  [Colors.GREEN]: 'bg-[#EBF8DC]',
  [Colors.GREEN_DARK]: 'bg-[#E2EEC0]',
  [Colors.GREEN_DARK_SECONDARY]: 'bg-[#DEECDC]',

  [Colors.GREY]: 'bg-[#F6F6F6]',

  [Colors.BROWN]: 'bg-[#FAEDCC]',
  [Colors.BROWN_DARK]: 'bg-[#F1F0F0]',
  [Colors.BROWN_DARK_SECONDARY]: '',

  [Colors.GRASS_GREEN]: 'bg-grassgreen-200',

  [Colors.SALMON_RED]: 'bg-salmonred-200',
}

export const colors: Record<Colors, string> = {
  [Colors.YELLOW]: 'bg-[#FFF8D9] text-[#876D03]',
  [Colors.YELLOW_DARK]: 'bg-[#FEF0CD] text-[#9B6E03]',
  [Colors.YELLOW_DARK_SECONDARY]: 'bg-[#FCF1D1] text-[#947024]',

  [Colors.ORANGE]: 'bg-[#FFF0E5] text-[#9c4926]',
  [Colors.ORANGE_DARK]: 'bg-[#FAE3D0] text-[#A85D3B]',

  [Colors.PURPLE]: 'bg-[#F0F2FF] text-[#5D669E]',
  [Colors.PURPLE_DARK]: 'bg-[#F1E1E9] text-[#472536]',
  [Colors.PURPLE_DARK_SECONDARY]: 'bg-[#E1E4FF] text-[#252C69]',

  [Colors.GREEN]: 'bg-[#EBF8DC] text-[#164805]',
  [Colors.ORIENT_GREEN]: 'bg-[#DCECE2] text-[#164805]',
  [Colors.GREEN_DARK]: 'bg-[#E2EEC0] text-[#3D5000]',
  [Colors.GREEN_DARK_SECONDARY]: 'bg-[#DEECDC] text-[#23372A]',

  [Colors.GREY]: 'bg-[#F6F6F6] text-[#161616]',
  [Colors.GREY_DARK]: 'bg-[#E0E0E0] text-[#161616]',

  [Colors.BROWN]: 'bg-[#FAEDCC] text-[#3D2D1E]',
  [Colors.BROWN_DARK]: 'bg-[#F1F0F0] text-[#32302C]',
  [Colors.BROWN_DARK_SECONDARY]: '',

  [Colors.GRASS_GREEN]: 'bg-grassgreen-200 text-grassgreen-900',

  [Colors.SALMON_RED]: 'bg-salmonred-200 text-salmonred-900',

  [Colors.WHITE]: 'bg-white text-black',
  [Colors.NARCISUSS_YELLOW]: 'bg-narcisussyellow-200 text-narcisussyellow-900',
}
