<script lang="ts" setup>
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { getLastUpdatedByOnCell, isMultipleAnswers, onHistoryClick } from '~/helpers/UtilsHelper'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})
const route = useRoute()

const checkedHandler = (event) => {
  const value = event.target.checked ? true : null
  const colId = props.params.column.colId
  props.params.node.setDataValue(colId, value)
}

const state = reactive({
  rowId: props.params.rowId,
  colId: props.params.column.colDef.colId,
  isActivated: true,
  refName: `cell_inactive_div_${props.params?.colDef?.colId}_${props.params?.rowIndex}`,
  colIndex:
    props.params.colDef.index - props.params?.data?.details?.initialShiftIndex,
})

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  const { data, colDef } = props.params

  if (data?.isDisabled || colDef?.disabledStepIds?.includes(data?.stepId))
    return false

  return colDef
    ? props.params?.colDef?.editable(props.params, false)
    : props.isCleared
})

const activateCell = () => {
  if (isHistory.value || !isEditable)
    return
  state.isActivated = true
}

const init = () => {
  if (state.isActivated && props.params.value === '')
    props.params.node.setDataValue(props.params.column.colId, false)
}

let tempWord = null
if (props.params.value === true)
  tempWord = 'word'
state.isActivated = setValueOrNA(tempWord, props.params)

init()
</script>

<template>
  <div
    v-if="!props.params.data.hidden"
    :class="[
      `_data-cy-column-${state.colIndex}`,
      `_data-cy-row-${props.params.rowIndex}`,
    ]"
    data-cy="input-container"
    :data-cy-column="
      props.params.colDef.index - props.params?.data?.details?.initialShiftIndex
    "
    class="_data-cy-input-type-checkbox"
  >
    <div
      v-if="isMultipleAnswers(props.params) && props.params?.data?.isHistoryEnabled"
      v-can.any="['read-report', 'write-report']"
      class="absolute right-1 top-1"
      data-cy="history-button"
      @click="onHistoryClick(props.params)"
    >
      <component
        :is="Icons.DATA_BACKUP"
        class="text-grey-500 w-5 h-5 cursor-pointer"
      />
    </div>
    <div
        v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 bottom-1"
        data-cy="avatar-button"
      >
      <AvatarMultiple
        :user-ids="[getLastUpdatedByOnCell(props.params)]"
        hide-full-name
        display-amount
        is-enable-modal
      />
      </div>
    <input
      v-if="state.isActivated"
      :checked="props.params.value"
      :disabled="isHistory || !isEditable"
      class="h-5 w-5"
      type="checkbox"
      @click="checkedHandler($event)"
    >
    <div
      v-else
      :id="state.refName"
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
</template>
