import { addDays } from 'date-fns'
import localforage from 'localforage'
import localForage from 'localforage'
import { apiStore } from '~/store/api'
import { gS } from '~/store/global'
import type { JReport } from '~/models/documents/jReport'
import type { JMasterSession } from '~/models/sessions/JMasterSessions'
import loggerHelper from '~/helpers/LoggerHelper'
import { isOperatorView } from '~/utils/helpers'

export class OfflineHandler {
  static expiryDays = 2
  static postRequestPrefix = 'juno_offline_post_'

  static generateExpiryDate() {
    return addDays(+new Date(), this.expiryDays).getTime()
  }

  // set local answers when offline
  static setInputData(answer) {
    if (gS().isOffline && answer) {
      answer = {
        ...answer,
        update_date: answer.update_date.toISOString(),
        value: answer.value ? answer.value.toString() : answer.value,
      }

      localforage.setItem(`jra_report:${answer.report_id};date:${+new Date()}`, { data: answer, expiryDate: this.generateExpiryDate() })
    }
  }

  // set report status when offline
  static setReportStatus(report, status) {
    if (gS().isOffline && status > -1)
      localforage.setItem(`jrs_report:${report.id}`, { data: status, expiryDate: this.generateExpiryDate() })
  }

  // set session status when offline
  static setSessionStatus(session, status) {
    if (gS().isOffline && status > -1)
      localforage.setItem(`jss_report:${session.id}`, { data: status, expiryDate: this.generateExpiryDate() })
  }

  // inject local answers to report when offline
  static async injectLocalAnswers(currentReport) {
    if (gS().canUseCache) {
      await localForage.keys().then(async (keys) => {
        for (const key of keys) {
          if (key.startsWith('jra_report')) {
            const [,reportId] = key.match(/jra_report:(\d+);date:(\d+)/)

            if (currentReport.id !== Number.parseInt(reportId))
              return

            const answer = (await localForage.getItem(key))?.data
            if (answer === undefined)
              return
            // check if this answer is related to any of this report steps
            const stepIndex = currentReport.steps.findIndex(step => step.id === answer.step_id)
            if (stepIndex > -1) {
              // add answer the right step list of answers
              currentReport.steps[stepIndex].answers.push(answer)
              // add answer to global list of answers
              currentReport.inputData.push(answer)
            }
          }
        }
      })
    }

    return currentReport
  }

  // inject local statuses to report when offline
  static async injectReportStatus(reports) {
    if (gS().canUseCache) {
      await localForage.keys().then(async (keys) => {
        for (const key of keys) {
          if (key.startsWith('jrs_report') && key) {
            const status = (await localForage.getItem(key))?.data
            if (status === undefined)
              return
            const [,reportId] = key.match(/jrs_report:(\d+)/)
            // todo: check expiryDate
            const reportIndex = reports.findIndex((report: JReport) => Number.parseInt(report.id) === Number.parseInt(reportId))
            if (reportIndex > -1)
              reports[reportIndex].status = status
          }
        }
      })
    }

    return reports
  }

  // inject local statuses to session when offline
  static async injectSessionStatus(sessions) {
    if (gS().canUseCache) {
      await localForage.keys().then(async (keys) => {
        for (const key of keys) {
          if (key.startsWith('jss_report') && key) {
            const status = (await localForage.getItem(key))?.data
            if (status === undefined)
              return
            const [,sessionId] = key.match(/jss_report:(\d+)/)
            const sessionIndex = sessions.findIndex((session: JMasterSession) => session.id === Number.parseInt(sessionId))
            if (sessionIndex > -1)
              sessions[sessionIndex].status = status
          }
        }
      })
    }

    return sessions
  }

  // sync data when online again
  static async syncPostData() {
    const keys: string[] = (await localForage.keys()).filter(key => key.startsWith(OfflineHandler.postRequestPrefix))
    const resultList: any[] = (await Promise.all(
      keys.map(async (key) => {
        const item: any = await localForage.getItem(key)
        return {
          ...item,
          key,
        }
      }),
    )).sort((a, b) => a.expiryDate - b.expiryDate)
    for (const cacheObject of resultList) {
      const item: any = typeof cacheObject.data === 'string' ? JSON.parse(cacheObject.data) : cacheObject.data
      await apiStore().getApiClient.syncUnsentData(item.url, item.payload)
        .then(() => localforage.removeItem(cacheObject.key))
        .catch(() => {
          loggerHelper.logError('Error syncing post data')
          throw new Error('Error syncing post data')
        })
    }
  }

  // delete all local keys
  static async deleteLocalInsertion() {
    if (gS().canUseCache && isOperatorView())
      return

    await localForage.keys().then(async (keys) => {
      for (const key of keys) {
        if (!key.startsWith('checksum_') && !key.startsWith('fetch_') && !key.startsWith('post_') && !key.startsWith(OfflineHandler.postRequestPrefix))
          localforage.removeItem(key)
      }
    })
  }
}
