<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import _ from 'lodash'
import type { ModalTheme } from '~/models/Global'
import { ModalType } from '~/models/Global'
import { Icons } from '~/models/Icon'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  width: {
    type: Object,
    required: false,
    default: () => ({ inner: '', outer: '' }),
  },
  type: {
    type: String,
    required: false,
    default: 'success',
  },
  modalTitle: {
    type: String,
    required: false,
    default: '',
  },
  modalTitleVariables: {
    type: Object,
    required: false,
    default: {} as any,
  },
  modalDescription: {
    type: String,
    required: false,
    default: '',
  },
  modalDescriptionPluralisation: {
    type: Number,
    required: false,
    default: 1,
  },
  saveText: {
    type: String,
    required: false,
    default: 'global.confirm',
  },
  isCrossClose: {
    type: Boolean,
    required: false,
    default: false,
  },
  closeText: {
    type: String,
    required: false,
    default: 'global.cancel',
  },
  icon: {
    required: false,
    default: false,
  },
  displayBtns: {
    type: Boolean,
    required: false,
    default: true,
  },
  displaySaveBtn: {
    type: Boolean,
    required: false,
    default: true,
  },
  displayCloseBtn: {
    type: Boolean,
    required: false,
    default: true,
  },
  loadingBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableSaveBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDisplayIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  dataCy: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'save'): void
  (e: 'actionOnClose'): void
  (e: 'update:modelValue', val: boolean): void
}>()

const state = () => ({
  icon: Icons.WARNING,
})

watch(() => props.icon, (newVal) => {
  state.icon = props.icon || props.type === ModalType.success ? Icons.CERTIFICATE_CHECK : Icons.WARNING
}, {
  immediate: true,
})

const theme: ModalTheme = {
  success: {
    button: 'bg-primary hover:bg-primary/90',
    text: 'text-primary',
    icon: 'bg-primary',
    displayConfirm: false,
  },
  error: {
    button: 'bg-cherryred-700 hover:bg-cherryred-700/90',
    text: 'text-cherryred-700',
    icon: 'bg-cherryred-700',
    displayConfirm: true,
  },
}

const widthManager = (): { outer: string; inner: string } => {
  const result = {
    outer: 'sm:my-8 sm:max-w-lg sm:w-full',
    inner: '',
  }
  if (!_.isEmpty(props.width.outer))
    result.outer = props.width.outer

  if (!_.isEmpty(props.width.inner))
    result.inner = props.width.inner

  return result
}

const closeModal = () => {
  emit('update:modelValue', false)
  emit('actionOnClose')
}
</script>

<template>
  <TransitionRoot
    appear
    as="template"
    :show="props.modelValue"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="closeModal()"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg text-left shadow-xl transform transition-all"
              :class="widthManager().outer"
            >
              <div
                class="bg-white p-6 rounded-lg"
                :data-cy="dataCy"
              >
                <div
                  class="items-start gap-x-6"
                  :class="{ flex: props.isDisplayIcon }"
                >
                  <div
                    v-if="props.isDisplayIcon"
                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg  sm:mx-0 sm:h-10 sm:w-10"
                    :class="theme[props.type].icon"
                  >
                    <component
                      :is="state.icon"
                      class="w-[26px] h-[26px] text-white"
                    />
                  </div>

                  <div
                    class="mt-3 w-full text-center sm:mt-0 sm:text-left"
                    :class="widthManager().inner"
                  >
                    <div class="flex flex-row items-center justify-between">
                      <div class="inline-flex space-x-4">
                        <DialogTitle
                          as="h3"
                          class="block text-xl font-semibold"
                          :class="theme[props.type].text"
                        >
                          {{ $t(props.modalTitle, { ...props.modalTitleVariables }) }}
                        </DialogTitle>
                        <slot name="additionnalData" />
                      </div>
                      <component
                        :is="Icons.CLOSE"
                        v-if="props.isCrossClose"
                        class="font-bold w-6 h-6 cursor-pointer"
                        :data-cy="`${dataCy}-close`"
                        @click="closeModal()"
                      />
                    </div>
                    <div class="mt-4">
                      <p class="text-sm text-cloudygray-700 text-justify">
                        {{ $t(props.modalDescription, modalDescriptionPluralisation) }}
                      </p>
                    </div>
                    <slot
                      name="body"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="props.displayBtns && !props.isCrossClose"
                class="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse gap-x-2 rounded-b-lg"
              >
                <o-button
                  v-if="props.displaySaveBtn"
                  :loading="props.loadingBtn"
                  big
                  :bg-css-color="theme[props.type].button"
                  :is-disabled="props.disableSaveBtn"
                  data-cy="confirm-modal-button"
                  @click="emit('save')"
                >
                  {{ $t(props.saveText) }}
                </o-button>
                <o-button
                  v-if="props.displayCloseBtn"
                  big
                  alternate
                  data-cy="cancel-modal-button"
                  @click="closeModal()"
                >
                  {{ $t(props.closeText) }}
                </o-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
