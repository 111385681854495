export enum ExportTarget {
  EXCEL = 'excel',
  CSV = 'csv',
  PDF = 'pdf',
}

export const targetInfos = {
  excel: {
    extension: 'xlsx',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    MultipleExtension: 'xlsx',
    MultipleType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  pdf: {
    extension: 'pdf',
    type: 'application/pdf',
  },
}
