import List from '~icons/carbon/list'
import StatusStep4 from '~icons/mdi/circle-slice-4'
import StatusStep6 from '~icons/mdi/circle-slice-6'
import Add from '~icons/carbon/add'
import AddAlt from '~icons/carbon/add-alt'
import SubtractAlt from '~icons/carbon/subtract-alt'
import Alert from '~icons/carbon/warning-alt'
import Alarm from '~icons/carbon/alarm'
import Analytics from '~icons/carbon/analytics'
import Book from '~icons/carbon/book'
import Bullhorn from '~icons/carbon/bullhorn'
import CenterCircle from '~icons/carbon/center-circle'
import Checkmark from '~icons/carbon/checkmark'
import CheckmarkFilled from '~icons/carbon/checkmark-filled'
import CarbonLicense from '~icons/carbon/license'
import CarbonPending from '~icons/carbon/pending'
import CarbonScreen from '~icons/carbon/screen'
import CheckboxChecked from '~icons/carbon/checkbox-checked'
import ChevronDown from '~icons/carbon/chevron-down'
import ChevronRight from '~icons/carbon/chevron-right'
import ChevronLeft from '~icons/carbon/chevron-left'
import ChevronUp from '~icons/carbon/chevron-up'
import CaretDown from '~icons/carbon/caret-down'
import CaretRight from '~icons/carbon/caret-right'
import CaretLeft from '~icons/carbon/caret-left'
import CaretUp from '~icons/carbon/caret-up'
import CircleSolid from '~icons/carbon/circle-solid'
import CircleFilled from '~icons/carbon/circle-filled'
import Close from '~icons/carbon/close'
import DocumentAttachment from '~icons/carbon/document-attachment'
import DocumentTasks from '~icons/carbon/document-tasks'
import DocumentExport from '~icons/carbon/document-export'
import Export from '~icons/carbon/export'
import Dashboard from '~icons/carbon/dashboard'
import Flash from '~icons/carbon/flash'
import EdgeEnhancement from '~icons/carbon/edge-enhancement'
import InProgress from '~icons/carbon/in-progress'
import Help from '~icons/carbon/help-filled'
import Login from '~icons/carbon/login'
import Logout from '~icons/carbon/logout'
import MediaLibrary from '~icons/carbon/media-library'
import OpenPanelTop from '~icons/carbon/open-panel-top'
import Save from '~icons/carbon/save'
import DocumentPdf from '~icons/carbon/document-pdf'
import RadioButton from '~icons/carbon/radio-button'
import RadioButtonChecked from '~icons/carbon/radio-button-checked'
import RecentlyViewed from '~icons/carbon/recently-viewed'
import TrashCan from '~icons/carbon/trash-can'
import TrafficCone from '~icons/carbon/traffic-cone'
import Upload from '~icons/carbon/upload'
import View from '~icons/carbon/view'
import Settings from '~icons/carbon/settings'
import WarningAlt from '~icons/carbon/warning-alt'
import Warning from '~icons/carbon/warning'
import WarningFilled from '~icons/carbon/warning-filled'
import Information from '~icons/carbon/information'
import UserAvatar from '~icons/carbon/user-avatar'
import CarbonUser from '~icons/carbon/user'
import Cube from '~icons/carbon/cube'
import ThreeDCube from '~icons/carbon/3d-mpr-toggle'
import Tag from '~icons/carbon/tag'
import CubeView from '~icons/carbon/cube-view'
import Package from '~icons/carbon/package'
import Play from '~icons/carbon/play'
import Menu from '~icons/carbon/menu'
import Hashtag from '~icons/carbon/hashtag'
import Edit from '~icons/carbon/edit'
import Email from '~icons/carbon/email'
import User from '~icons/carbon/user'
import DataShare from '~icons/carbon/data-share'
import ViewFilled from '~icons/carbon/view-filled'
import ViewOffFilled from '~icons/carbon/view-off-filled'
import documentAdd from '~icons/carbon/document-add'
import search from '~icons/carbon/search'
import ArrowRight from '~icons/carbon/arrow-right'
import ArrowLeft from '~icons/carbon/arrow-left'
import ArrowUp from '~icons/carbon/arrow-up'
import ArrowDown from '~icons/carbon/arrow-down'
import SendAlt from '~icons/carbon/send-alt'
import Filter from '~icons/carbon/filter'
import GroupResource from '~icons/carbon/group-resource'
import CircleDash from '~icons/carbon/circle-dash'
import CarbonFolder from '~icons/carbon/folder'
import CarbonCalendar from '~icons/carbon/calendar'
import CarbonRenew from '~icons/carbon/renew'
import CarbonDocument from '~icons/carbon/document'
import CarbonUsers from '~icons/carbon/user-multiple'
import CarbonCube from '~icons/carbon/cube'
import CarbonGroupResource from '~icons/carbon/group-resource'
import CarbonIndustry from '~icons/carbon/industry'
import MdiApps from '~icons/mdi/apps'
import Barcode from '~icons/mdi/barcode'
import CarbonTaskLocation from '~icons/carbon/task-location'
import CarbonTemplate from '~icons/carbon/template'
import WorkspaceImport from '~icons/carbon/workspace-import'
import Identification from '~icons/carbon/identification'
import OverflowMenuVertical from '~icons/carbon/overflow-menu-vertical'
import CheckmarkOutline from '~icons/carbon/checkmark-outline'
import CloseOutline from '~icons/carbon/close-outline'
import DashedOutline from '~icons/carbon/circle-dash'
import CarbonMachineLearning from '~icons/carbon/machine-learning'
import screen from '~icons/carbon/screen'
import dashboardIcon from '~icons/carbon/dashboard'
import CloseFilled from '~icons/carbon/close-filled'
import licenseDraft from '~icons/carbon/license-draft'
import Layers from '~icons/carbon/layers'
import PresentationFile from '~icons/carbon/presentation-file'
import Undefined from '~icons/carbon/undefined-filled'
import SkipForward from '~icons/carbon/skip-forward-solid-filled'
import WarningHexFilled from '~icons/carbon/warning-hex-filled'
import PauseOutlineFilled from '~icons/carbon/pause-outline-filled'
import LightFilled from '~icons/carbon/light-filled'
import MenuVertical from '~icons/carbon/overflow-menu-vertical'
import ChartLine from '~icons/carbon/chart-line'
import Binoculars from '~icons/carbon/binoculars'
import Bell from '~icons/carbon/notification'
import Ruler from '~icons/carbon/ruler-alt'
import Calibrate from '~icons/carbon/calibrate'
import DatabaseError from '~icons/carbon/data-error'
import UserProfile from '~icons/carbon/user-profile'
import Language from '~icons/carbon/language'
import UserFollow from '~icons/carbon/user-follow'
import Locked from '~icons/carbon/locked'
import Timer from '~icons/carbon/timer'
import Time from '~icons/carbon/time'
import SettingsAdjust from '~icons/carbon/settings-adjust'
import Reset from '~icons/carbon/Reset'
import CertificateCheck from '~icons/carbon/certificate-check'
import BookmarkAdd from '~icons/carbon/bookmark-add'
import Archive from '~icons/carbon/archive'
import Password from '~icons/carbon/password'
import UserActivity from '~icons/carbon/user-activity'
import SettingsCheck from '~icons/carbon/settings-check'
import Pin from '~icons/carbon/pin'
import Unpin from '~/components/CustomIcons/Unpin.vue'
import Percentage from '~icons/carbon/percentage'
import Sparkles from '~/components/CustomIcons/Sparkles.vue'
import ListChecked from '~icons/carbon/list-checked'
import ListBulleted from '~icons/carbon/list-bulleted'
import ListNumbered from '~icons/carbon/list-numbered'
import CopyFile from '~icons/carbon/copy-file'
import TextFont from '~icons/carbon/text-font'
import Camera from '~icons/carbon/camera'
import Link from '~icons/carbon/link'
import CircleMeasurement from '~icons/carbon/settings-adjust'
import CheckboxCheckedFilled from '~icons/carbon/radio-button-checked'
import VideoOff from '~icons/carbon/video-off'
import DataBackup from '~icons/carbon/data-backup'
import UserRole from '~icons/carbon/user-role'
import CloudOffline from '~icons/carbon/cloud-offline'
import CloudMonitoring from '~icons/carbon/cloud-monitoring'
import Chat from '~icons/carbon/chat'

import TextBold from '~icons/carbon/text-bold'
import TextItalic from '~icons/carbon/text-italic'
import TextUnderline from '~icons/carbon/text-underline'
import TextParagraph from '~icons/mdi/format-paragraph'
import TextLeft from '~icons/carbon/text-align-left'
import TextCenter from '~icons/carbon/text-align-center'
import TextRight from '~icons/carbon/text-align-right'
import TextH1 from '~icons/mdi/format-header-1'
import TextH2 from '~icons/mdi/format-header-2'
import TextH3 from '~icons/mdi/format-header-3'

export enum Icons {
  TEXT_BOLD = TextBold,
  TEXT_ITALIC = TextItalic,
  TEXT_UNDERLINE = TextUnderline,
  TEXT_PARAGRAPH = TextParagraph,
  TEXT_LEFT = TextLeft,
  TEXT_CENTER = TextCenter,
  TEXT_RIGHT = TextRight,
  TEXT_H1 = TextH1,
  TEXT_H2 = TextH2,
  TEXT_H3 = TextH3,
  DATA_BACKUP = DataBackup,
  RULER = Ruler,
  CALIBRATE = Calibrate,
  PIN = Pin,
  UNPIN = Unpin,
  BULLHORN = Bullhorn,
  ADD = Add,
  ALARM = Alarm,
  ANALYTICS = Analytics,
  PLAY = Play,
  ADD_ALT = AddAlt,
  SUBTRACT_ALT = SubtractAlt,
  ALERT = Alert,
  ALERT_NOTIFICATION = TrafficCone,
  BOOK = Book,
  BELL = Bell,
  CENTER_CIRCLE = CenterCircle,
  CHEVRON_DOWN = ChevronDown,
  CHEVRON_RIGHT = ChevronRight,
  CHEVRON_LEFT = ChevronLeft,
  CHEVRON_UP = ChevronUp,
  CARET_DOWN = CaretDown,
  CARET_RIGHT = CaretRight,
  CARET_LEFT = CaretLeft,
  CARET_UP = CaretUp,
  CHECKMARK = Checkmark,
  CHECKMARK_OUTLINE = CheckmarkOutline,
  CHECKBOX_CHECKED = CheckboxChecked,
  CHECKBOX_CHECKED_FILLED = CheckboxCheckedFilled,
  CIRCLE_SOLID = CircleSolid,
  CIRCLE_FILLED = CircleFilled,
  CLOSE = Close,
  CLOUD_MONITORING = CloudMonitoring,
  CLOUD_OFFLINE = CloudOffline,
  DASHBOARD = Dashboard,
  DOCUMENT_ATTACHMENT = DocumentAttachment,
  DOCUMENT_TASKS = DocumentTasks,
  DOCUMENT_EXPORT = DocumentExport,
  EDGE_ENHANCEMENT = EdgeEnhancement,
  FLASH = Flash,
  HELP = Help,
  IDENTIFICATION = Identification,
  IN_PROGRESS = InProgress,
  STATUS_STEP_4 = StatusStep4,
  STATUS_STEP_6 = StatusStep6,
  LOGIN = Login,
  LOGOUT = Logout,
  LIGHT_FILLED = LightFilled,
  MEDIA_LIBRARY = MediaLibrary,
  OPEN_PANEL_TOP = OpenPanelTop,
  PERCENTAGE = Percentage,
  RADIO_BUTTON = RadioButton,
  RADIO_BUTTON_CHECKED = RadioButtonChecked,
  RECENTLY_VIEWED = RecentlyViewed,
  PAUSE_OUTLINE_FILLED = PauseOutlineFilled,
  SKIP_FORWARD = SkipForward,
  SAVE = Save,
  DOCUMENTPDF = DocumentPdf,
  SEND = SendAlt,
  SETTINGS = Settings,
  TRASH_CAN = TrashCan,
  UPLOAD = Upload,
  VIEW = View,
  WARNING_ALT = WarningAlt,
  WARNING = Warning,
  WARNING_FILLED = WarningFilled,
  INFORMATION = Information,
  USER_AVATAR = UserAvatar,
  USER_ROLE = UserRole,
  CUBE = Cube,
  THREE_D_CUBE = ThreeDCube,
  TAG = Tag,
  CUBE_VIEW = CubeView,
  PACKAGE = Package,
  MENU = Menu,
  HASHTAG = Hashtag,
  EDIT = Edit,
  LICENSE = CarbonLicense,
  PENDING = CarbonPending,
  MACHINE = CarbonMachineLearning,
  EMAIL = Email,
  USER = User,
  DATE_SHARE = DataShare,
  VIEW_FILLED = ViewFilled,
  VIEW_OFF_FILLED = ViewOffFilled,
  DOCUMENT_ADD = documentAdd,
  SEARCH = search,
  ARROW_RIGHT = ArrowRight,
  ARROW_Left = ArrowLeft,
  ARROW_UP = ArrowUp,
  ARROW_DOWN = ArrowDown,
  FILTER = Filter,
  GROUP_RESOURCE = GroupResource,
  CIRCLE_DASH = CircleDash,
  CARBON_FOLDER = CarbonFolder,
  CARBON_CALENDAR = CarbonCalendar,
  CARBON_USER = CarbonUser,
  CARBON_SCREEN = CarbonScreen,
  CARBON_DOCUMENT = CarbonDocument,
  CARBON_USERS = CarbonUsers,
  CARBON_CUBE = CarbonCube,
  CARBON_GROUP_RESOURCE = CarbonGroupResource,
  CARBON_RENEW = CarbonRenew,
  CARBON_INDUSTRY = CarbonIndustry,
  BARCODE = Barcode,
  MDI_APPS = MdiApps,
  CARBON_TASK_LOCATION = CarbonTaskLocation,
  CARBON_TEMPLATE = CarbonTemplate,
  MACHINE_LEARNING = CarbonMachineLearning,
  WORKSPACE_IMPORT = WorkspaceImport,
  CLOSE_OUTLINE = CloseOutline,
  DASHED_OUTLINE = DashedOutline,
  CHECKMARK_FILLED = CheckmarkFilled,
  CLOSE_FILLED = CloseFilled,
  CARBON_PENDING = CarbonPending,
  OVERFLOW_MENU_VERTICAL = OverflowMenuVertical,
  SCREEN = screen,
  DASHBOARD_ICON = dashboardIcon,
  LICENSE_DRAFT = licenseDraft,
  LAYERS = Layers,
  EXPORT = Export,
  PRESENTATION_FILE = PresentationFile,
  UNDEFINED = Undefined,
  BINOCULARS = Binoculars,
  WARNING_HEX_FILLED = WarningHexFilled,
  USER_PROFILE = UserProfile,
  LANGUAGE = Language,
  LOCKED = Locked,
  RESET = Reset,
  TIMER = Timer,
  TIME = Time,
  MENU_VERTICAL = MenuVertical,
  CHART_LINE = ChartLine,
  DATABASE_ERROR = DatabaseError,
  USER_FOLLOW = UserFollow,
  CERTIFICATE_CHECK = CertificateCheck,
  BOOKMARK_ADD = BookmarkAdd,
  SETTINGS_CHECK = SettingsCheck,
  ARCHIVE = Archive,
  PASSWORD = Password,
  USER_ACTIVITY = UserActivity,
  SPARKLES = Sparkles,
  SETTINGS_ADJUST = SettingsAdjust,
  LIST_CHECKED = ListChecked,
  LIST_BULLETED = ListBulleted,
  LIST_NUMBERED = ListNumbered,
  COPY_FILE = CopyFile,
  TEXT_FONT = TextFont,
  CAMERA = Camera,
  LINK = Link,
  CIRCLE_MEASUREMENT = CircleMeasurement,
  VIDEO_OFF = VideoOff,
}

export interface Icon {
  name: keyof typeof Icons
  class?: string | Object
}
