import * as _ from 'lodash'
import { subDays } from 'date-fns'
import { updateMasterSessionById } from '../masterSessions'
import type { JReportContext } from './../../models/documents/jReport'
import type { MasterSessionModel } from './../../models/sessions/MasterSessionModel'
import { masterSessionStore } from './../../store/masterSessions'
import { removeInstance } from './../../utils/object'
import loggerHelper from '~/helpers/LoggerHelper'
import type { JSessionContext } from '~/models/sessions/JSession'
import { JSession, SessionScope, SessionStatus } from '~/models/sessions/JSession'
import { SESSIONS_COLLECTION_NAME } from '~/config/storage'
import { getDocumentsFromIds } from '~/controllers/documents/index'
import { usersStore } from '~/store/users'
import DbHelper from '~/helpers/dbHelper'
import { getDocuments } from '~/controllers/documents/'
import { isUndefinedOrNullOrEmptyOrNan } from '~/utils/object'
import type { JDocument } from '~/models/documents/jDocument'
import { Days, DocumentStatus, DocumentType, RecurrenceType } from '~/models/documents/jDocument'
import { createReportWithContext, getOrCreateReports, getReportsByContext, getReportsByIds } from '~/controllers/reports/reportsController'
import type { JReport } from '~/models/documents/jReport'
import { getCurrentWorkingTeam } from '~/controllers/teams'
import { getDifferenceInDays, getMonthDiff, getWeekDiff, getYearDiff } from '~/utils/date'
import { sessionStore } from '~/store/sessions'
import { routingStore } from '~/store/routing'
import type { JSiteFlags } from '~/models/sites'
import { RoutingMode } from '~/models/sites'
import apiHelper from '~/helpers/ApiHelper'

const dbHelper = new DbHelper()

// Sessions
export const getAllSessions = async (): Promise<any[]> => {
  const currentUser = usersStore().user
  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'site_id',
    compare: '==',
    value: currentUser.site_id as any,
  },
  {
    field: 'is_deleted',
    compare: '==',
    value: false as any,
  },
  ]

  return await dbHelper.getAllDataFromCollectionWithAll(SESSIONS_COLLECTION_NAME, { where_constraints })
}

export const createSession = async (sessionViewModel: JSession, masterSession: MasterSessionModel): Promise<JSession> => {
  const currentUser = usersStore().user
  const session = removeInstance(new JSession(sessionViewModel))

  if (!session.stakeholders_operator_ids?.includes(currentUser.id))
    session.stakeholders_operator_ids.push(currentUser.id)
  session.start_date = new Date().getTime()
  session.client_id = currentUser.client_id
  session.site_id = currentUser.site_id
  session.creator_id = currentUser.id
  session.status = SessionStatus.new
  session.master_session_id = masterSession.id

  const currentWorkingTeam = await getCurrentWorkingTeam()
  session.last_team = currentWorkingTeam?.id || ''

  const createdSession = await dbHelper.addDataToCollection(SESSIONS_COLLECTION_NAME, session)
  session.id = createdSession.id

  sessionStore().addSession(session)
  masterSessionStore().addMasterSession(masterSession)

  return getSession(createdSession.id)
}

export const createSessions = async (sessionsViewModel: JSession[], masterSession: MasterSessionModel): Promise<JSession[]> => {
  const currentUser = usersStore().user
  const sessions = [] as JSession[]
  let sessionIds = [] as any[]

  for (let session of sessionsViewModel) {
    session = removeInstance(new JSession(session))

    if (!session.stakeholders_operator_ids?.includes(currentUser.id))
      session.stakeholders_operator_ids.push(currentUser.id)
    session.start_date = new Date().getTime()
    session.client_id = currentUser.client_id
    session.site_id = currentUser.site_id
    session.creator_id = currentUser.id
    session.status = SessionStatus.new
    session.master_session_id = masterSession.id
    const currentWorkingTeam = await getCurrentWorkingTeam()
    session.last_team = currentWorkingTeam?.id || ''
    sessions.push(session)
  }

  const createdSessions = await dbHelper.batchAdd(SESSIONS_COLLECTION_NAME, sessions)

  sessionIds = Array.isArray(masterSession.session_ids)
    ? masterSession.session_ids.concat(createdSessions.map(e => e.id))
    : createdSessions.map(e => e.id)

  masterSession.session_ids = sessionIds

  await updateMasterSessionById(masterSession.id, { sessionIds, workplaceIds: masterSession?.master_session_context?.workplace_ids })
  sessionStore().addSessions(createdSessions)
  masterSessionStore().addMasterSession(masterSession)

  return createdSessions
}

export const isSessionValid = async (currentSession: Record<string, any>, currentReport?: Record<string, any>) => {
  const reportIds = currentSession.reports_ids ?? []
  const allReports = await getReportsByIds(reportIds)
  const filteredAndUpdatedReports = allReports
    .filter(report => report.id !== currentReport?.id)
    .concat(currentReport ? [currentReport] : [])

  return filteredAndUpdatedReports.every(report => report.is_valid)
}

export const updateSessionStatus = async (id: string, status: SessionStatus, isValid?: boolean): Promise<any> => {
  const currentUser = usersStore().user

  // OfflineHandler.setSessionStatus(session, status)
  return await dbHelper.updateDataToCollection(SESSIONS_COLLECTION_NAME, id, {
    status,
    is_valid: isValid ?? true,
    update_date: new Date().getTime(),
    updated_by: currentUser.id,
  })
}

export const updateSessionTeam = async (session: JSession): Promise<any> => {
  const currentWorkingTeam = await getCurrentWorkingTeam()

  return await dbHelper.updateDataToCollection(SESSIONS_COLLECTION_NAME, session.id, { last_team: currentWorkingTeam?.id || '' })
}

export const updateSessionReports = async (id: string, reports_ids: any[], documents_ids: any[], lastTeam?: string): Promise<any> => {
  const currentUser = usersStore().user
  const session = await getSession(id)

  if (!session.stakeholders_operator_ids?.includes(currentUser.id))
    session.stakeholders_operator_ids.push(currentUser.id)

  return await dbHelper.updateDataToCollection(SESSIONS_COLLECTION_NAME, id, {
    reports_ids,
    documents_ids,
    stakeholders_operator_ids: session.stakeholders_operator_ids,
    last_team: lastTeam || session.last_team || '',
  })
}

export const setSessionStakeholders = async (id: string, stakeholders_operator_ids: []): Promise<any> => {
  return await dbHelper.updateDataToCollection(SESSIONS_COLLECTION_NAME, id, { stakeholders_operator_ids })
}

export const getSessionsOnWorkplaceOrAll = async (workplaceId = '', daysBefore = 0): Promise<any[]> => {
  const currentUser = usersStore().user
  const constraints = [
    {
      field: 'client_id',
      compare: '==',
      value: currentUser.client_id as any,
    },
    {
      field: 'site_id',
      compare: '==',
      value: currentUser.site_id as any,
    },
  ]

  if (daysBefore !== 0) {
    const date = subDays(new Date(), daysBefore).getTime()
    constraints.push({
      field: 'start_date',
      compare: '>=',
      value: date,
    })
  }

  if (workplaceId) {
    constraints.push({
      field: 'session_context.workplace_id',
      compare: '==',
      value: workplaceId,
    })
  }

  const sessions = await dbHelper.getAllDataFromCollectionWithAll(SESSIONS_COLLECTION_NAME, {
    where_constraints: constraints,
  })

  return sessions
}

export const getSession = async (sessionId: any): Promise<any> => {
  const session = await dbHelper.getDocFromCollection(SESSIONS_COLLECTION_NAME, sessionId)
  if (!session)
    return
  const allDocuments = await getDocumentsFromIds(session.documents_ids)
  session.documents = {}
  session.documents.read_write_documents = allDocuments?.filter(p => p.documentType === DocumentType.read_write)
  session.documents.readonly_documents = allDocuments?.filter(p => p.documentType === DocumentType.readonly)

  return session
}

export const getSessions = async (sessionIds: any[]): Promise<any[]> => {
  return await dbHelper.getAllDataFromCollectionFromIds(SESSIONS_COLLECTION_NAME, sessionIds)
}

export const filterSchedules = (documents) => {
  // Filter on day documents
  const day = new Date().getDay()
  const currentTime = new Date().getTime()

  // Filter on up to date documents
  const checkExpirationDate = (d: any) => {
    const expirationTimestamp = typeof d.expiration_date === 'string' ? new Date(d.expiration_date).getTime() : d.expiration_date

    return isUndefinedOrNullOrEmptyOrNan(expirationTimestamp) || expirationTimestamp > currentTime
  }

  const checkStartingDate = (d: any) => isUndefinedOrNullOrEmptyOrNan(d.starting_date) || d.starting_date <= currentTime

  documents = documents?.filter(d => checkExpirationDate(d) && checkStartingDate(d)) ?? []

  documents = documents?.filter((d) => {
    const starting_date = d.starting_date || new Date(new Date().getFullYear(), 1, 1).getTime()
    const recurrence_starting_date = new Date(d.schedule?.recurrence_starting_date).getTime() || new Date(new Date().getFullYear(), 1, 1).getTime()

    if (!d.schedule?.recurrenceType)
      return true

    if (d.schedule?.recurrenceType === RecurrenceType.day) {
      const daysDiff = getDifferenceInDays(starting_date, new Date().getTime())

      return daysDiff % d.schedule.recurrence === 0
    }
    if (d.schedule?.recurrenceType === RecurrenceType.week) {
      const week = getWeekDiff(starting_date, new Date().getTime())

      return (week % d.schedule.recurrence === 0 && d.schedule.days.includes(day)) || (d.scheduling == Days.ALL || d.scheduling == String(day))
    }

    // starting_date =>  recurrence_starting_date
    if (d.schedule?.recurrenceType === RecurrenceType.month) {
      const monthDiff = getMonthDiff(recurrence_starting_date, new Date().getTime())

      return monthDiff % d.schedule.recurrence === 0 && new Date(recurrence_starting_date).getDate() === new Date().getDate()
    }
    if (d.schedule?.recurrenceType === RecurrenceType.year) {
      const yearDiff = getYearDiff(recurrence_starting_date, new Date().getTime())

      return yearDiff % d.schedule.recurrence === 0 && new Date(recurrence_starting_date).getDate() === new Date().getDate()
    }
    return true
  }) ?? []

  documents = documents.filter((doc) => {
    if (!doc.schedule.recurrence_hours_enabled)
      return true

    const currentTimeString = new Date().toTimeString().split(':').slice(0, 2).join(':')

    const fitHours = doc.schedule.recurrence_hours.some(({ from, to }) => {
      return from < currentTimeString && currentTimeString < to
    })

    return fitHours
  })

  return documents
}

export const filterDocuments = async (filterOptions: { productId: string; workplaceId: string; scope: SessionScope; operationId: string | null }, withDraft = false) => {
  const { productId, workplaceId, scope, operationId } = filterOptions
  let docs = await getDocuments(withDraft ? [DocumentStatus.published, DocumentStatus.draft] : DocumentStatus.published, withDraft)
  docs = (scope === SessionScope.WORKPLACE)
    ? await apiHelper.filterWorkplaceDocuments(workplaceId)
    : await apiHelper.filterSessionDocuments({ productId, workplaceId, operationId })

  return docs
}

export const splitAndOrderDocuments = (docs) => {
  const getDocumentFromType = (docs, documentType: DocumentType) => {
    return docs
      .filter((p: any) => p.type === documentType)
      .sort((a: any, b: any) => a.order - b.order)
  }

  return {
    read_write_documents: getDocumentFromType(docs, DocumentType.read_write),
    readonly_documents: getDocumentFromType(docs, DocumentType.readonly),
  }
}

export const computeSessionDocuments = async (session: JSession, freshReports: any[], sessionContext: JSessionContext): Promise<any> => {
  try {
    const { product_id: productId, workplace_id: workplaceId, quantity, operation_id: operationId } = sessionContext
    const result: any = {}

    let docs = [] as JDocument[] | undefined
    let reports: JReport[] = []

    if (session.documents_ids?.length === 0) {
      docs = await filterDocuments({
        scope: session.scope,
        productId,
        workplaceId,
        operationId,
      })
      const existingIds = freshReports.map(report => report.id)
      reports = await getOrCreateReports(docs, session.id, quantity, sessionContext, existingIds)

      await updateSessionReports(session.id, reports.map(e => e.id), docs.map(e => e.id))
      result.reports = reports
    }
    else {
      docs = await getDocumentsFromIds(session.documents_ids)
      docs = Array.isArray(docs) ? docs : [docs]
      result.reports = freshReports
    }

    const { read_write_documents, readonly_documents } = splitAndOrderDocuments(docs)
    const automatic_documents = result.reports
      .filter(report => report.type === 'NIR')
      ?.map((report) => {
        return {
          ...report,
          last_input_update_date: getReportLastInputUpdate(report),
        }
      }) ?? []
    const automatic_document_ids = automatic_documents.map(report => report.document_id)
    result.read_write_documents = read_write_documents.filter(document => !automatic_document_ids.includes(document.id))
    result.readonly_documents = readonly_documents
    result.automatic_documents = automatic_documents
    return result
  }
  catch (e: any) {
    loggerHelper.logError('Compute documents', e.message)
  }
}

export const getReportLastInputUpdate = (report) => {
  return _.max(report?.inputData?.map(input_data => (new Date(input_data.update_date)).getTime()))
}

export const filterOperatorReports = async (freshReports: any[]) => {
  const reportsById = _.groupBy(freshReports, 'document_id')

  const finalReports = []
  // From each document, take only the last one in case the same team worked several times on the same document
  for (const p in reportsById) {
    const items = reportsById[p]
    const orderedItems = _.orderBy(items, ['update_date'], ['desc'])
    const report = _.take(orderedItems, 1)
    finalReports.push(report[0])
  }

  return finalReports
}

export const getSessionByParameters = async (productionOrderId: string, workplaceId: string, operationId?: string | null) => {
  const currentUser = usersStore().user
  const where_constraints = [
    {
      field: 'client_id',
      compare: '==',
      value: currentUser.client_id,
    }]
  if (productionOrderId) {
    where_constraints.push({
      field: 'session_context.production_order_id',
      compare: '==',
      value: productionOrderId,
    })
  }

  if (workplaceId) {
    where_constraints.push({
      field: 'session_context.workplace_id',
      compare: '==',
      value: workplaceId,
    })
  }

  if (operationId) {
    where_constraints.push({

      field: 'session_context.operation_id',
      compare: '==',
      value: operationId,
    })
  }

  const sessions = (await dbHelper.getAllDataFromCollectionWithAll(SESSIONS_COLLECTION_NAME, {
    where_constraints,
  }))

  return sessions[0]
}

export const getOrCreateWorkplaceReports = async (workplaceId: string, currentTeam: any) => {
  try {
    const isInAllWorkplaces = workplaceId === 'all'
    let contextReportsFilter: JReportContext = {
      workplace_id: workplaceId,
      product_id: 'ALL_PRODUCTS',
      operation_id: '',
    }

    if (isInAllWorkplaces)
      contextReportsFilter = _.omit(contextReportsFilter, 'workplace_id')

    let reports: JReport[] = []
    let contextDocuments = await apiHelper.filterWorkplaceDocuments(workplaceId)
    let contextReports: JReport[] = await getReportsByContext(contextReportsFilter)

    contextDocuments = filterSchedules(contextDocuments)
    const documentIds = contextDocuments.map(d => d.id)

    contextReports = contextReports.filter((report: JReport) => {
      return documentIds.includes(report.document_id || '') // Keep reports with matching document_id
    })

    const docIdsReportToCreate = _.difference(contextDocuments.map(p => p.id), contextReports.map(p => p.document_id))
    if (docIdsReportToCreate.length > 0 && !isInAllWorkplaces) {
      const last_team = currentTeam?.id || ''
      for (let i = 0; i < docIdsReportToCreate.length; i++) {
        const doc = contextDocuments.find(doc => doc.id === docIdsReportToCreate[i])
        reports.push(await createReportWithContext(doc, last_team, workplaceId))
      }
    }
    reports = reports.concat(contextReports)

    return { reports, contextDocuments }
  }
  catch (e) {
    console.error('Cannot get workplace documents', e)
  }
}

export const getRoutingWorkorders = (productionOrders: any, flags: JSiteFlags): any[] => {
  if (!flags?.application_fields?.operations || flags?.routing_mode !== RoutingMode.MANUAL)
    return productionOrders

  const routing = routingStore().getRouting
  const workplaceRoutingMap = new Map()

  // Create a map of workplaceId -> routing array for O(1) lookup
  routing.forEach((e) => {
    if (!workplaceRoutingMap.has(e.workplaceId))
      workplaceRoutingMap.set(e.workplaceId, [])

    workplaceRoutingMap.get(e.workplaceId).push(e.operationId)
  })

  // Create a map for unique orders based on workplaceId and id
  const orderMapKey = order => `${order.workplaceId}_${order.id}`
  const uniqueOrdersMap = new Map()

  productionOrders.forEach((order) => {
    uniqueOrdersMap.set(orderMapKey(order), order)
  })

  const routingWorkorders = [] as any[]

  uniqueOrdersMap.forEach((po) => {
    const operationIds = workplaceRoutingMap.get(po.workplaceId) || []
    operationIds.forEach((operationId) => {
      routingWorkorders.push({
        id: po.id,
        productId: po.productId,
        updateDate: po.updateDate,
        workplaceId: po.workplaceId,
        operationId,
        tags: po.tags,
        quantity: po.quantity,
      })
    })
  })

  return routingWorkorders
}

export const filterExistingSessions = (
  productionOrders: any,
  routingWorkorders: any,
  flags: JSiteFlags,
  sessions: JSession[],
): any[] => {
  const workorders = flags?.pilot_mode ? productionOrders : routingWorkorders

  // Create a set for O(1) lookup times
  const sessionSet = new Set()
  sessions.forEach((session) => {
    const { production_order_id, operation_id, workplace_id } = session.session_context
    const key = flags?.application_fields?.operations
      ? `${production_order_id}_${operation_id}_${workplace_id}`
      : `${production_order_id}_${workplace_id}`
    sessionSet.add(key)
  })

  // Filter the workorders using the set for quick lookups
  return workorders.filter((po) => {
    const key = flags?.application_fields?.operations
      ? `${po.id}_${po.operationId}_${po.workplaceId}`
      : `${po.id}_${po.workplaceId}`
    return !sessionSet.has(key)
  })
}
