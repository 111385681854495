<script lang="ts" setup>
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { getLastUpdatedByOnCell, isMultipleAnswers, onHistoryClick } from '~/helpers/UtilsHelper'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
})
const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
}>()
const regex = /^[0-9]*$/
const minInput = ref(null)
const hourInput = ref(null)

const state = reactive({
  isEnteringMinutes: false,
  hour: props.params?.value?.split(':')[0] ?? undefined,
  minutes: props.params?.value?.split(':')[1] ?? undefined,
  colId: props.params?.column?.colDef?.colId || props.params?.answer?.col_id,
  isActivated: true,
  refName: `cell_inactive_div_${props.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  inputName: `cell_input_${props.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  colIndex:
    props.params?.colDef?.index
    - props.params?.data?.details?.initialShiftIndex,
})

const activateCell = () => {
  if (isHistory.value || !isEditable)
    return
  state.isActivated = true
}

watch(
  () => state.minutes,
  async (newValue, oldValue) => {
    state.isEnteringMinutes = false
    if (newValue === '' || newValue === undefined) {
      state.minutes = ''
      return
    }

    if (regex.exec(newValue)) {
      let newMinutes: string | number = Number.parseInt(newValue)
      if (newMinutes < 10)
        newMinutes = newMinutes.toString().padStart(2, '0') // add a leading zero if value < 10, ex 4 => 04

      if (Number(newMinutes) > 59)
        newMinutes = 59

      state.minutes = newMinutes
    }
    else {
      state.minutes = oldValue
    }
  },
)

watch(
  () => state.hour,
  (newValue) => {
    if (newValue > 9) {
      state.isEnteringMinutes = true
      minInput.value?.select()
    }
  },
)

const onChange = () => {
  const colId = props.params?.column?.colId || props.params?.answer?.col_id
  if (!state.isEnteringMinutes) {
    if (state.hour || state.minutes) {
      if (!state.hour && state.minutes)
        state.hour = '00'
      if (!state.minutes && state.hour)
        state.minutes = '00'
      emit('setNewValue', `${state.hour}:${state.minutes}`)
      props.params?.node?.setDataValue(colId, `${state.hour}:${state.minutes}`)
    }
    else {
      emit('setNewValue', undefined)
      props.params.node.setDataValue(colId, undefined)
    }
  }
  state.isEnteringMinutes = false
}

const route = useRoute()

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  const { data, colDef } = props.params

  if (data?.isDisabled || colDef?.disabledStepIds?.includes(data?.stepId))
    return false

  return colDef
    ? props.params?.colDef?.editable(props.params, false)
    : props.isCleared
})

let tempWord = null
if (props.params.value)
  tempWord = 'word'
state.isActivated = setValueOrNA(tempWord, props.params)

const onFocusHour = () => {
  if (hourInput?.value)
    hourInput.value.select()
}

const onFocusMinutes = () => {
  if (minInput?.value)
    minInput.value.select()
}
</script>

<template>
  <div v-if="!props.params?.data?.hidden">
    <div v-if="state.isActivated">
      <div
        v-if="isMultipleAnswers(props.params) && props.params?.data?.isHistoryEnabled"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click="onHistoryClick(props.params)"
      >
        <component
          :is="Icons.DATA_BACKUP"
          class="text-grey-500 w-5 h-5 cursor-pointer"
        />
      </div>
      <div
        v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 bottom-1"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          hide-full-name
          display-amount
          is-enable-modal
        />
      </div>
      <div
        :class="[
          `_data-cy-column-${state.colIndex}`,
          `_data-cy-row-${props.params.rowIndex}`,
          {
            'text-gray-600': !state.hour && !state.minutes,
            'text-black': state.hour || state.minutes,
            'error': props.isError,
          },
        ]"
        :data-cy-column="state.colIndex"
        class="flex items-center _data-cy-input-type-time"
      >
        <input
          :id="state.inputName"
          ref="hourInput"
          v-model="state.hour"
          :disabled="isHistory || !isEditable"
          class="w-[3ch] bg-transparent placeholder-gray-600 placeholder-opacity-100"
          maxlength="2"
          min="0"
          placeholder="--"
          step="1"
          style="z-index: 45; text-align: right"
          type="text"
          @blur="onChange"
          @focus="onFocusHour"
        >:<input
          ref="minInput"
          v-model="state.minutes"
          :disabled="isHistory || !isEditable"
          class="w-[3ch] bg-transparent placeholder-gray-600 placeholder-opacity-100"
          max="59"
          min="0"
          placeholder="--"
          step="1"
          type="text"
          @blur="onChange"
          @focus="onFocusMinutes"
        >
      </div>
    </div>
    <div
      v-else
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5 items-center flex justify-center self-center"
      @click="activateCell()"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  @apply border-solid border border-red-500;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
