import localforage from 'localforage'
import _ from 'lodash'
import { usersStore } from '~/store/users'

export const redirectToPage = async (isPasswordReset: boolean) => {
  if (isPasswordReset === false)
    return '/auth/force-reset-pass'

  const user = usersStore().user
  const latestVisitedPageUrl = await localforage.getItem(`latestVisitedPageUrl_${user.id}`)

  if (!_.isEmpty(latestVisitedPageUrl)) {
    await localforage.removeItem(`latestVisitedPageUrl_${user.id}`)
    window.location.href = latestVisitedPageUrl
    return
  }

  return null
}
