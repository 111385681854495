import localforage from 'localforage'
import type { UserModule } from '~/types'
import { fb_auth } from '~/services/firebase'

export const install: UserModule = ({ isClient, router }) => {
  if (!isClient)
    return

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresForcePass = to.matched.some(record => record.meta.requiresForcePass)

    localforage.getItem('currentUser').then((u) => {
      const storageUser = JSON.parse(u)
      window.currentPageRequireAuth = requiresAuth

      if (requiresForcePass) {
        if ((storageUser?.isPasswordReset === false)) {
          next({
            path: '/auth/force-reset-pass',
          })
          return
        }
        if (storageUser?.password_expired_at) {
          next({
            path: '/auth/reset-expired-password',
          })
          return
        }
      }

      if (requiresAuth) {
        fb_auth.onAuthStateChanged((user) => {
          if (user) {
            next()
          }
          else {
            next({
              path: '/auth/login',
            })
          }
        })
      }
      else {
        fb_auth.onAuthStateChanged((user) => {
          if (user && to.name === 'auth-login')
            next('/')
          else
            next()
        })
      }
    })
  })
}
