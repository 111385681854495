import { format, formatDistanceToNow } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import _, { camelCase, isArray, isObject, join, transform } from 'lodash'
import { getUserById } from '~/controllers/authentication'

interface JDateOptions {
  displayFullDate: boolean
}

// const { locale } = useI18n()
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// const langMap = [{ lang: 'fr', format: 'fr-FR' }, { lang: 'en', format: 'en-US' }]

export const formatDate = (timestamp: any, dateStyle: any = 'long', timeStyle: any = 'short') => {
  if (timestamp) {
    try {
      const isEN = localStorage.getItem('lang') === 'en'
      // const format = langMap.find(p => p.lang === locale.value)
      const formattedDate = new Intl.DateTimeFormat(isEN ? 'en-US' : 'fr-FR', {
        timeStyle,
        dateStyle,
      }).format(new Date(timestamp))

      return capitalizeFirstLetter(formattedDate)
    }
    catch (error) {
      return timestamp
    }
  }
  return ''
}

export const formatShortDate = (timestamp: any, yearDigits = 4) => {
  const yearFormat = yearDigits === 4 ? 'numeric' : `${yearDigits}-digit`

  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        year: yearFormat as 'numeric' | '2-digit' | undefined,
        month: 'numeric',
        day: 'numeric',
      }).format(new Date(timestamp))
      return capitalizeFirstLetter(formattedDate)
    }
    catch (error) {
      return timestamp
    }
  }
  return ''
}

export const formatTime = (timestamp: any, yearDigits = 4) => {
  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(timestamp))
      return formattedDate
    }
    catch (error) {
      return timestamp
    }
  }
  return ''
}

export const formatDayMonth = (date: any) => {
  if (!date)
    return null

  const isEN = localStorage.getItem('lang') === 'en'
  const locale = isEN ? enUS : fr
  return format(new Date(date), 'd MMMM', { locale })
}

export const formatShortDateWitHourMinute = (timestamp: any, yearDigits = 4) => {
  const yearFormat = yearDigits === 4 ? 'numeric' : `${yearDigits}-digit`

  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        year: yearFormat as 'numeric' | '2-digit' | undefined,
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(timestamp))
      return capitalizeFirstLetter(formattedDate)
    }
    catch (error) {
      return timestamp
    }
  }
  return ''
}

export const formatHour = (timestamp: any) => {
  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(timestamp))
      return capitalizeFirstLetter(formattedDate)
    }
    catch (error) {
      return timestamp
    }
  }
  return ''
}

export const getRelativeTime = (date) => {
  const relativeTimeString = formatDistanceToNow(new Date(date))
  const regex = /(\d+)\s(\w+)/
  const elements = relativeTimeString.match(regex)!

  let value = 1
  let unit = 'minute'

  if (elements) {
    value = Number(elements[1])
    unit = elements[2]
  }

  return {
    value,
    unit: unit.endsWith('s') ? unit.slice(0, -1) : unit,
  }
}

export const createURLFromArray = (arr: Array<string>) => {
  return `/${join(arr, '/')}`
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const parseDMY = (s) => {
  const b = s.split(/\D/)
  const d = new Date(b[0], --b[1], b[2])
  return d && d.getMonth() == b[1] ? d : new Date(Number.NaN)
}

export const truncateString = (str: string, length: number) => {
  return str.length > length ? `${str.substring(0, length)}...` : str
}

// 04, 07
const addZeroFormat = (time) => {
  time = time.toString()
  time = time.padStart(2, '0')

  return time
}

export const formatDateToNumeric = (date: Date | number, options: Partial<JDateOptions> = {}) => {
  date = new Date(date)

  const year = date.getFullYear().toString().slice(options.displayFullDate ? 0 : 2, 4)
  const month = options.displayFullDate
    ? date.toLocaleString('default', { month: 'long' })
    : date.getMonth() + 1
  const day = addZeroFormat(date.getDate())

  let result = options.displayFullDate
    ? `${day} ${month} ${year}`
    : `${day}/${month}/${year}`

  const hour = addZeroFormat(date.getHours())
  const minutes = addZeroFormat(date.getMinutes())
  result = `${result} - ${hour}h${minutes}`

  return result
}

export const getInitialsWithId = (id: string): string => {
  const user = getUserById(id)
  return user ? `${user.first_name?.charAt(0).toUpperCase()}${user.last_name?.charAt(0).toUpperCase()}` : ''
}

export const getInitials = (name: string): string => {
  const splitName = name.split(' ').map(e => e.trim())
  name = `${splitName[0][0] ?? ''}${splitName[splitName.length - 1][0] ?? ''}`

  return name
}

export const recursivelyCamelizeObject = obj => transform(obj, (acc: any, value, key, target) => {
  const camelKey = isArray(target) ? key : camelCase(key as string)

  acc[camelKey] = isObject(value) ? recursivelyCamelizeObject(value) : value
})

export const minutesToMilliseconds = (minutes) => {
  const minutesToSeconds = minutes * 60
  const secondsToMilliseconds = minutesToSeconds * 1000

  return secondsToMilliseconds
}

export const monthNumericToText = (month: number) => {
  const { t } = useI18n()
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

  return t(`monthes.${months[month]}`)
}

// 28 Août 2022 - 12h30
export const dateOrTimestampToTextDate = (date: Date | string | number) => {
  date = new Date(date)

  let result = ''
  const day: string = date.getDate().toString().padStart(2, '0')
  const month: string = monthNumericToText(date.getMonth())
  const year: number = date.getFullYear()
  const hour: string = date.getHours().toString().padStart(2, '0')
  const minutes: string = date.getMinutes().toString().padStart(2, '0')

  result = `${day} ${month} ${year} - ${hour}h${minutes}`
  return result
}

export const dateOrTimestampToReadableDate = (date: Date | string | number, timeSeparator = ':') => {
  date = new Date(date)

  const day = formatShortDate(date, 2)
  const hour = date.toTimeString().split(' ')[0].split(':').slice(0, 2).join(timeSeparator)

  return `${day} - ${hour}`
}

export const dateClassToFormKitDate = (date: number | Date) => {
  date = new Date(date)
  const year = date.getFullYear().toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const result = `${year}-${month}-${day}`
  return result
}

export const convertCamelCase = (string: string): string => {
  const words = _.camelCase(string).replace(/([A-Z])/g, ' $1').split(' ')
  const convertedString = words
    .map((word, index) => {
      return index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word.toLowerCase()
    })
    .join(' ')

  return convertedString
}

export const generateFirebaseLikeId = () => {
  let customId = ''
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    customId += characters[randomIndex]
  }

  return customId
}
