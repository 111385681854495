

import __layout_0 from '/Users/smail/PhpstormProjects/vue3-webapp-2/app/src/layouts/default.vue'
export const layouts = {
'404': () => import('/Users/smail/PhpstormProjects/vue3-webapp-2/app/src/layouts/404.vue'),
'default': __layout_0,
'home': () => import('/Users/smail/PhpstormProjects/vue3-webapp-2/app/src/layouts/home.vue'),
'operator': () => import('/Users/smail/PhpstormProjects/vue3-webapp-2/app/src/layouts/operator.vue'),
'operatorWithTabs': () => import('/Users/smail/PhpstormProjects/vue3-webapp-2/app/src/layouts/operatorWithTabs.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
