export const isOperatorView = (): boolean => {
  return window.location.pathname.startsWith('/operator')
}

export const canUseLocalData = (): boolean => {
  const excludedUrls = [
    //'operator\\/workplaces\\/(.*)\\/sessions\\/(.*)\\/documents\\/(.*)\\/reports\\/(.*)'
  ]

  return isOperatorView() && !excludedUrls.some(url => window.location.pathname.match(url) )
}