<script setup lang="ts">
import { getUserById } from '~/controllers/authentication'
import { formatShortDate } from '~/utils/formatter'
import { ColumnState } from '~/utils/report'
import { Icons } from '~/models/Icon'
import { tasksStore } from '~/store/tasks'
import { alertDrawerStore } from '~/store/alertDrawerState'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const state = reactive({
  lastOperatorInitials: '' as any,
  lastDate: {} as any,
  isChecked: false,
  otherElementsOfColExisit: false,
  isValidControlStyle: false,
  isNOKControlStyle: false,
  params: { displayCheckboxes: true },
})

const init = async () => {
  state.lastOperatorInitials = await getLastOperator()
  state.lastDate = getLastUpdatedDate()
  const selectedCols = tasksStore().getSelectedColumnsIndex as number[]
  state.isChecked = selectedCols.find(col => col === props.params?.colIndex) !== undefined

  if (props?.params.columnState === ColumnState.ok || props?.params.columnState === ColumnState.ko || props?.params.columnState === ColumnState.inProgress)
    state.otherElementsOfColExisit = true

  if (props?.params.columnState === ColumnState.ok)
    state.isValidControlStyle = true

  if (props?.params.columnState === ColumnState.ko)
    state.isNOKControlStyle = true
}

const getLastOperator = async () => {
  try {
    const user = await getUserById(props.params.lastUpdateOnColumn?.updated_by) as any

    return user ? `${user.first_name?.charAt(0).toUpperCase()}.${user.last_name?.charAt(0).toUpperCase()}` : ''
  }
  catch (e) {
    console.error('Cannot get last operator:', e)
  }
}

const getLastUpdatedDate = () => {
  const updatedDate = new Date(props.params.lastUpdateOnColumn?.update_date)

  if (!(Date.parse(updatedDate as unknown as string)))
    return ''

  return { hour: updatedDate.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':'), day: formatShortDate(updatedDate, 2) }
}

const alertClicked = () => {
  alertDrawerStore().setAlert(props.params.alert)
}

const onClick = (event: any) => {
  tasksStore().setSelectedColumns(props.params?.colIndex)
  tasksStore().setSelectedColumnName(props?.params?.displayName)
}

const colId = computed(() => {
  return props.params.column?.colId
})

const isStatic = computed(() => {
  return props.params.static || props.params?.colIndex < 0
})

const isFrequencyEnabled = computed(() => {
  return props?.params?.frequency?.enabled
})

const checkTime = computed(() => {
  if (!isFrequencyEnabled.value)
    return

  let every = Number.parseInt(props.params?.frequency?.every)

  if (props.params?.frequency?.type === 'day')
    every *= 3600
  if (props.params?.frequency?.type === 'hour')
    every *= 60

  let [hours, minutes] = props.params?.frequency.startingAt.split(':')

  hours = Number(hours)
  minutes = Number(minutes)

  const totalMinutes = every * props.params.colIndex
  const initialMinutes = hours * 60 + minutes
  const newMinutes = (initialMinutes + totalMinutes) % 1440

  hours = Math.floor(newMinutes / 60)
  minutes = newMinutes % 60

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
})

init()
</script>

<template>
  <div
    data-cy="custom-header"
    :class="[
      `_data-cy-column-${colId}`,
    ]"
    class="relative flex h-full w-full justify-center"
  >
    <div class="cell-wrapper absolute top-0 left-0 bg-slate-100 border-r-none  h-8 w-full flex items-end font-medium">
      <div
        v-if="isFrequencyEnabled && colId !== 'name'"
        class="flex flex-center bg-[#FAF0CC] rounded-t-md  gap-2 py-1 px-2"
        style="margin-top: 7px;"
      >
        <component
          :is="Icons.TIME"
          class="text-sm"
        />
        <span class="text-sm"> {{ checkTime }} </span>
      </div>
      <div
        v-if="params.alerts?.length"
        v-can:read-alert
        class="bg-[#FBDCE5] flex flex-center gap-2 rounded-t-md px-2 py-1 cursor-pointer text-primary"
        @click="alertClicked"
      >
        <component
          :is="Icons.WARNING_ALT"
          class="text-sm "
        />
        <p class="text-sm tracking-wide">
          {{ $t('report.see_alert') }}
        </p>
      </div>
    </div>
    <div
      class="flex cell-bottom relative w-full mt-8 border-gray-200"
      :class="{
        'justify-start pl-4': colId === 'name',
        'border-l justify-center': !['name', 'file'].includes(colId),
      }"
    >
      <div
        v-if="params.displayCheckboxes && !isStatic"
        class="absolute top-2 -right-3"
      >
        <label
          data-cy="select-column"
          class="input-container"
        >
          <input
            v-model="state.isChecked"
            :value="state.isChecked"
            type="checkbox"
            @change="onClick"
          >
          <span class="checkmark" />
        </label>
      </div>
      <div
        v-if="params.columnState && !isStatic"
        class="mr-4 flex-shrink-0 self-center"
      >
        <component
          :is="Icons.CHECKMARK_OUTLINE"
          v-if="props?.params.columnState === ColumnState.ok"
          class="h-8 w-8 text-green-600"
        />

        <component
          :is="Icons.DASHED_OUTLINE"
          v-else-if="props?.params.columnState === ColumnState.inProgress"
          class="h-8 w-8 text-duskBlue"
        />

        <component
          :is="Icons.CLOSE_OUTLINE"
          v-else-if="props?.params.columnState === ColumnState.ko"
          class="h-8 w-8 text-red-600"
        />
      </div>
      <div class="self-center space-y-2">
        <div
          class="text-md font-bold text-left"
          data-cy="grid-header-name"
        >
          {{ params.displayName }}
        </div>
        <div
          v-if="params.colIndex >= 0 && state.lastOperatorInitials"
          class="inline-flex align-center"
        >
          <span
            v-if="state.lastOperatorInitials"
            :class="{ 'text-[#49AA19]': state.isValidControlStyle, 'text-[#000]': state.isNOKControlStyle }"
            class="font-normal text-xs text-[#757575] align-left text-left self-center"
          >
            {{ state.lastDate?.day }} - {{ state.lastDate?.hour }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style  lang="scss">
.grid-header-content {
  margin-left: 35px;
}

.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input-container input {
  display: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;

  border: 1px solid #757575;
  border-radius: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.input-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.input-container input:checked~.checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-container .checkmark:after {
  width: 8px;
  height: 8px;
  border: 1px solid #2196F3;
  background-color: #2196F3;
  border-radius: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ag-header {
  height: 120px !important;
  border-bottom: none;
}

.ag-theme-alpine .ag-header-cell{
  height: 100% !important;
  background-color: #FAFAFA
}

.ag-root-wrapper {
  background-color: #f8f9ff !important;
}

.ag-body-horizontal-scroll{
  @apply border-r border-l border-gray-200;
}
.ag-horizontal-left-spacer{
  border-right: 1px solid rgb(229 231 235) !important;
}

.ag-horizontal-right-spacer{
  display: none
}

.ag-header-viewport{
  @apply bg-slate-100;

  .ag-header-cell{
    .cell-bottom{
      @apply border-t border-gray-200;
    }
  }
  &::before{
    @apply border-t border-r border-gray-200 bg-[#FAFAFA] ;
    content: "";
    position: absolute;
    top: 32px;
    right: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 8px;
  }
}

.ag-pinned-left-header{
  .ag-header-cell{
    &:first-child{
      .cell-bottom{
        border-top-left-radius: 8px;
      }
    }
    .cell-bottom{
      @apply border-t border-gray-200;
    }

  }

}

.ag-header-viewport{
  @apply bg-slate-100;
}

.ag-header-row {
  height: inherit !important;
}

.ag-header-cell {
  border: none !important;
  // border-right: 1px solid #DEE2EA !important;

  &.header {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ag-pinned-left-header {
  width: 500;
  border-right: none !important;

  .ag-header-cell {
    border-right: none !important;
  }
}
</style>
