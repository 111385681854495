import type { PathLike } from 'node:fs'
import qs from 'qs'
import type { AxiosResponse, CreateAxiosDefaults } from 'axios'
import axios from 'axios'
import localForage from 'localforage'
import { addDays } from 'date-fns'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { hotReload } from './UtilsHelper'
import { BACKEND_URL } from '~/config/index'
import { fb_auth } from '~/services/firebase'
import loggerHelper from '~/helpers/LoggerHelper'
import { usersStore } from '~/store/users'
import DbHelper from '~/helpers/dbHelper'
import { SITES_COLLECTION_NAME } from '~/common/config/firebase/storage'
import { CacheCheckSumsLocalForageKeys } from '~/common/models/cache'
import { gS } from '~/store/global'
import { CACHED_OBJECTS } from '~/config/local-forage'
import { logout } from '~/controllers/authentication'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import { isOperatorView } from '~/utils/helpers'
import { OfflineHandler } from '~/services/offline'
import type { JSite } from '~/models/sites'
import { USERS_COLLECTION_NAME } from '~/config/storage'
import { productsStore } from '~/store/products'
import { workplaceStore } from '~/store/workplaces'
import { routingStore } from '~/store/routing'
import { lookupStore } from '~/store/lookups'

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 180000,
  baseURL: BACKEND_URL,
  paramsSerializer: (params: PathLike) => qs.stringify(params, { indices: false }),
} as CreateAxiosDefaults

export const checksum_cache_pattern = 'checksum_{0}_{1}'

export const get_cache_pattern = 'fetch_{0}_{1}'
export const post_cache_pattern = 'post_{0}_{1}'

class ApiHelper {
  apiClient: any
  apiClientPython: any
  apiClientNylas: any
  userTokenId: string
  fb_auth: any

  constructor() {
    const emptyInstance = axios.create(apiConfig)
    const axiosInstance = axios.create(apiConfig)
    this.apiClient = axios.create(apiConfig)

    // cache get responses
    axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
      // Save all get request to use on ofline mode
      if (response.config.method === 'get' && response.config.url) {
        const currentUser = usersStore().user
        let cacheKey = get_cache_pattern
          .replace('{0}', currentUser.id)
          .replace('{1}', response.config.url) as string

        if (response.config.params)
          cacheKey += JSON.stringify(response.config.params)

        if (response.data)
          localForage.setItem(cacheKey, { data: response.data, expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime() })
      }

      return response
    }, (error) => {
      // Save post data if it fails
      if (error.config.method === 'post' && error.config.url) {
        loggerHelper.logError(`Failed Post request for url '${error.config.url}' with payload ${error.config.data}`)
        const cacheKey = post_cache_pattern
          .replace('{0}', error.config.url)
          .replace('{1}', uuid()) as string
        localForage.setItem(cacheKey, { data: { payload: error.config.data, url: error.config.url }, expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime() })
      }
      return Promise.reject(error)
    })

    this.apiClient.get = async (...args) => {
      const currentUser = usersStore().user
      const url = args[0] as string
      const params = args[1]?.params as object
      // const payload = args[1]
      let cacheKey = get_cache_pattern
        .replace('{0}', currentUser.id)
        .replace('{1}', url) as string

      if (params)
        cacheKey += JSON.stringify(params)

      // if (gS().canUseCache || (canUseLocalData() && Object.keys(payload.params || {}).length === 0)) {
      if (cacheKey && gS().canUseCache) {
        const cachedItem: any = await localForage.getItem(cacheKey)
        if (cachedItem && cachedItem.data && cachedItem.expiryDate > new Date().getTime()) {
          // axiosInstance.get(...args)
          return cachedItem
        }
      }

      return axiosInstance.get(...args)
    }

    this.apiClient.post = async (...args) => {
      const url = args[0] as string
      const payload = args[1] as any

      if (gS().canUseCache && isOperatorView()) {
        const data = JSON.stringify({ payload, url })
        return localForage.setItem(OfflineHandler.postRequestPrefix + uuid(), { data, expiryDate: addDays(+new Date(), 2).getTime() })
      }

      return axiosInstance.post(...args)
    }

    this.apiClient.forceGet = emptyInstance.get
    this.apiClient.forcePost = emptyInstance.post

    this.fb_auth = fb_auth
    this.userTokenId = ''

    this.apiClient.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.status === 401 && window.currentPageRequireAuth)
        window.location.href = '/auth/login'
      return Promise.reject(error)
    })
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId
  }

  async getToken() {
    const user: any = await this.fb_auth?.currentUser
    const token: string = await user?.getIdToken()
    this.setUserTokenId(token)
  }

  getAnonymousHeaders() {
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    }
    return h
  }

  async getHeaders() {
    await this.getToken()
    if (isUndefinedOrNullOrEmpty(this.userTokenId)) {
      loggerHelper.logError('Unable to get user token', { url: window.location.href, firebase_user: JSON.stringify(this.fb_auth?.currentUser), store_user: JSON.stringify(usersStore().user) })

      await logout()
      if (!window.location.pathname.includes('auth/'))
        window.location.href = `/auth/login?path=${window.location.pathname}`
    }
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.userTokenId,
        },
      },
    }
    return h
  }

  async getAppStatus() {
    const resultAxios: any = await this.apiClient.get('/v1/app-status', this.getAnonymousHeaders())

    return resultAxios?.data
  }

  async getUsers(forceFetch = false) {
    const currentUser = usersStore().user

    const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.USERS).replace('{1}', currentUser.site_id)

    if (!forceFetch) {
      const cachedUsers: any = await localForage.getItem(cache_key)

      if (cachedUsers && cachedUsers.expiryDate > new Date().getTime())
        return cachedUsers.data
    }

    const resultAxios: any = await this.apiClient.get('/v1/users', await this.getHeaders())

    if (!_.isEmpty(resultAxios?.data)) {
      for await (const user of resultAxios?.data)
        user.full_name = `${user?.first_name} ${user?.last_name}`
    }

    localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

    return resultAxios?.data
  }

  async getUser(id: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/user/${id}`, await this.getHeaders())

    return resultAxios?.data
  }

  async postUser(id: string, data: any) {
    const resultAxios: any = await this.apiClient.post(`/v1/user/${id}`, data, await this.getHeaders())

    return resultAxios?.data
  }

  async updateUser(id: string, data: any) {
    const resultAxios: any = await this.apiClient.patch(`/v1/user/${id}`, data, await this.getHeaders())

    return resultAxios?.data
  }

  async getProducts(forceUpdate = false, filters = null) {
    try {
      const currentUser = usersStore().user

      const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.PRODUCTS).replace('{1}', currentUser.site_id)
      if (!forceUpdate) {
        const cachedProducts: any = await localForage.getItem(cache_key)

        if (cachedProducts && cachedProducts.expiryDate > new Date().getTime())
          return cachedProducts.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/products', {
        params: {
          ...filters,
        },
        ...await this.getHeaders(),
      })
      if (filters === null)
        localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async updateProduct(id: string, product: any) {
    const resultAxios: any = await this.apiClient.patch(`/v1/products/${id}`, product, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteProduct(id: string) {
    await this.apiClient.delete(`/v1/products/${id}`, await this.getHeaders())
  }

  async getProductionOrders(filters: any = null, forceReload = false) {
    try {
      const currentUser = usersStore().user

      const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.WORKORDERS).replace('{1}', currentUser.site_id)
      if (!forceReload) {
        const cachedWorkorders: any = await localForage.getItem(cache_key)

        if (cachedWorkorders && cachedWorkorders.expiryDate > new Date().getTime())
          return cachedWorkorders.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/production-orders', { params: filters, ...await this.getHeaders() })
      localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async getRouting(forceReload = false) {
    try {
      const currentUser = usersStore().user

      const cache_key = get_cache_pattern.replace('{0}', CACHED_OBJECTS.ROUTING).replace('{1}', currentUser.site_id)
      if (!forceReload) {
        const cachedRouting: any = await localForage.getItem(cache_key)

        if (cachedRouting && cachedRouting.expiryDate > new Date().getTime())
          return cachedRouting.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/routing', await this.getHeaders())
      localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async createProductionOrders(payload) {
    const resultAxios: any = await this.apiClient.post('/v1/production-orders', [payload], await this.getHeaders())

    return resultAxios?.data
  }

  async getWorkplaces(forceFetch = false, filters) {
    try {
      const currentUser = usersStore().user

      const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.WORKPLACES).replace('{1}', currentUser.site_id)
      if (!forceFetch) {
        const cachedWorkplaces: any = await localForage.getItem(cache_key)

        if (cachedWorkplaces && cachedWorkplaces.expiryDate > new Date().getTime())
          return cachedWorkplaces.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/workplaces', {
        params: {
          ...filters,
        },
        ...await this.getHeaders(),
      })
      if (filters === null)
        localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async updateWorkplace(id: string, workplace: any) {
    const resultAxios: any = await this.apiClient.patch(`/v1/workplaces/${id}`, workplace, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteWorkplace(id: string) {
    await this.apiClient.delete(`/v1/workplaces/${id}`, await this.getHeaders())
  }

  async addToWorkplaceHistory(payload: { workplace_id: string }) {
    try {
      const resultAxios: any = await this.apiClient.post('/v1/workplaces-history', payload, await this.getHeaders())

      return resultAxios?.data
    }
    catch (err) {
      loggerHelper.logError('Error adding to workplace history', err)
    }
  }

  async getOperations() {
    try {
      const resultAxios: any = await this.apiClient.get('/v1/operations', await this.getHeaders())
      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async updateLookup(id: string, lookup: any) {
    const resultAxios: any = await this.apiClient.patch(`/v1/lookups/${id}`, lookup, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteLookup(id: string) {
    await this.apiClient.delete(`/v1/lookups/${id}`, await this.getHeaders())
  }

  async getLookupsData(forceFetch = false, filters: any = null) {
    try {
      const currentUser = usersStore().user

      const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.LOOKUPS).replace('{1}', currentUser.site_id)
      if (!forceFetch) {
        const cachedLookups: any = await localForage.getItem(cache_key)

        if (cachedLookups && cachedLookups.expiryDate > new Date().getTime())
          return cachedLookups.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/lookups', { params: { ...filters }, ...await this.getHeaders() })
      if (filters === null)
        localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async getLookupsConfig() {
    try {
      const resultAxios: any = await this.apiClient.get('/v1/lookups-config', await this.getHeaders())
      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async getLookupsDataHistory(forceFetch = false) {
    try {
      const currentUser = usersStore().user

      const cache_key = checksum_cache_pattern.replace('{0}', CACHED_OBJECTS.LOOKUPS_HISTORY).replace('{1}', currentUser.site_id)
      if (!forceFetch) {
        const cachedLookupsHistory: any = await localForage.getItem(cache_key)

        if (cachedLookupsHistory && cachedLookupsHistory.expiryDate > new Date().getTime())
          return cachedLookupsHistory.data
      }

      const resultAxios: any = await this.apiClient.get('/v1/lookups-history', await this.getHeaders())
      localForage.setItem(cache_key, { data: resultAxios?.data, expiryDate: addDays(new Date(), 1).getTime() })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async getOperationsByIds(operationIds: string[], production_order_id: string) {
    const url = `/v1/operations?ids=${operationIds}&production_order_id=${production_order_id}`
    const resultAxios: any = await this.apiClient.get(url, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteFile(id: string) {
    const resultAxios: any = await this.apiClient.delete(`/v1/files/${id}`, await this.getHeaders())

    return resultAxios?.data
  }

  async getFile(id: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/files/${id}`, await this.getHeaders())
    const linkSource = resultAxios?.data?.data
    const fileName = resultAxios?.data?.file_name
    const base64Response = await fetch(linkSource)
    const blob = await base64Response.blob()
    const downloadLink = document.createElement('a')

    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = fileName
    document.body.append(downloadLink)
    downloadLink.click()
    downloadLink.remove()

    return resultAxios?.data
  }

  async uploadFile(link_id: string, type: string, fileName: string, fileData: any) {
    await this.getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${this.userTokenId}`)
    const formdata = new FormData()
    formdata.append('file', fileData, fileName)

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }
    const request: any = {
      response: '',
      result: {},
      error: {},
    }

    await fetch(`${BACKEND_URL}/v1/files?type=${type}&link_id=${link_id}`, requestOptions)
      .then(response => request.response = response.json())
      .then(result => request.result = result)
      .catch(error => request.error = error)

    return request
  }

  async updateFile(file_id: string, filename: string, fileData: any, payload = {}) {
    await this.getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${this.userTokenId}`)

    const formdata = new FormData()
    formdata.append('file', fileData, filename)

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }
    const request: any = {
      response: '',
      result: {},
      error: {},
    }

    await fetch(`${BACKEND_URL}/v1/files/${file_id}`, requestOptions)
      .then(response => request.response = response.json())

    return request.response
  }

  // reports
  async getReport(id: string | number, payload = {}) {
    try {
      const resultAxios: any = await this.apiClient.get(`/v1/report/${id}`, {
        params: payload,
        ...await this.getHeaders(),
      })

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Axios Error: ${error.response.status}`, error)
      else
        loggerHelper.logError('Unknown error', error)
    }
  }

  async getSimpleReportPdf(id: string | number, payload = {}) {
    const resultAxios: any = await this.apiClient.get(`/v1/reports/simple-pdf/${id}`, {
      params: {
        user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...payload,
      },
      responseType: 'blob',
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async getRichExport(id: string | number, target: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/reports/${id}/single-export/rich/${target}`, {
      params: {
        user_time_zone: Intl.DateTthis.apiClient.getimeFormat().resolvedOptions().timeZone,
      },
      responseType: 'blob',
      headers: {
        common: {
          ...(await this.getHeaders()).headers.common,
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
    })

    return resultAxios?.data
  }

  async getMultipleRichExport(ids: string[] | number[], target: string) {
    const resultAxios: any = await this.apiClient.get(`/v1/reports/multiple-export/rich/${target}`, {
      params: {
        report_ids: ids,
      },
      responseType: 'blob',
      headers: {
        common: {
          ...(await this.getHeaders()).headers.common,
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
    })

    return resultAxios?.data
  }

  async getRichReportPdf(id: string | number, payload = {}) {
    const resultAxios: any = await this.apiClient.get(`/v1/reports/rich-pdf/${id}`, {
      params: {
        user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...payload,
      },
      responseType: 'blob',
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async getDocumentPdfFile(id: string, columnsCount: number, order = '', currentWorkOrder = '', payload = {}) {
    const currentUser = usersStore().user
    const resultAxios: any = await this.apiClient.get('/v1/export-pdf', {
      params: {
        id,
        order,
        work_order: currentWorkOrder,
        lang: currentUser.language,
        columns_count: columnsCount,
        user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...payload,
      },
      responseType: 'blob',
      headers: {
        common: {
          ...(await this.getHeaders()).headers.common,
          Accept: 'application/pdf',
        },
      },
    })

    console.log('resultAxios', resultAxios)
    return resultAxios?.data
  }

  async getAnalytics(id) {
    const resultAxios: any = await this.apiClient.get(
      '/v1/analytics',
      {
        params: {
          dashboard_id: id,
        },
        ...await this.getHeaders(),
      },
    )
    return resultAxios?.data
  }

  async getReports(payload: object = {}) {
    const resultAxios: any = await this.apiClient.get('/v1/reports', {
      params: payload,
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async getNirReport(payload: object = {}) {
    const resultAxios: any = await this.apiClient.get('/v1/report/nir', {
      params: payload,
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async createReport(payload: object) {
    try {
      loggerHelper.logInfo(`[Report] Creating : A report is being created report, report : ${JSON.stringify(payload)}`)

      const resultAxios: AxiosResponse = await this.apiClient.post('/v1/reports', payload, await this.getHeaders())

      loggerHelper.logInfo(`[Report] Created report : A report has been created report ${resultAxios?.data?.id}, report : ${JSON.stringify(resultAxios?.data)}`)

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response)
        loggerHelper.logError(`[Report] Failed creating report with payload ${JSON.stringify(payload)} failed with status ${error.response}`, error)
      else
        loggerHelper.logError(`[Report] Creating report with payload ${JSON.stringify(payload)} failed`, error)
    }
  }

  async updateCache(): Promise<boolean> {
    try {
      const dbHelper = new DbHelper()
      const user = usersStore().user

      await dbHelper.setupCollectionSnapshot({
        collectionName: SITES_COLLECTION_NAME,
        checks: [
          {
            field: 'client_id',
            compare: '==',
            value: user.client_id,
          },
          {
            field: '__name__',
            compare: '==',
            value: user.site_id,
          },
        ],
        callback: async (sites: any) => {
          const site = sites[0] as JSite

          // Deactived until the implementation of cache in backend is done
          // const currentRoutingChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.ROUTING)
          // if (currentRoutingChecksum !== site?.checksums.routing) {
          //   console.log('routing changed')
          //   await this.getRouting(true)
          //   await localForage.setItem(CacheCheckSumsLocalForageKeys.ROUTING, site?.checksums?.routing)
          // }

          const currentWorkordersChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.WORKORDERS)
          const currentUsersChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.USERS)
          const currentProductChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.PRODUCTS)
          const currentWorkplacesChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.WORKPLACES)
          const currentRoutingChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.ROUTING)
          const currentLookupChecksum = await localForage.getItem(CacheCheckSumsLocalForageKeys.LOOKUPS)

          if (currentWorkordersChecksum !== site?.checksums?.workorders) {
            await this.getProductionOrders(null, true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.WORKORDERS, site?.checksums?.workorders)
          }

          if (currentRoutingChecksum !== site?.checksums?.routing) {
            await routingStore().loadRouting(true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.ROUTING, site?.checksums?.routing ?? currentRoutingChecksum)
          }

          if (currentUsersChecksum !== site?.checksums.users) {
            await usersStore().loadUsers(true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.USERS, site?.checksums?.users)
          }

          if (currentProductChecksum !== site?.checksums.products) {
            await productsStore().loadProducts(true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.PRODUCTS, site?.checksums?.products)
          }
          if (currentWorkplacesChecksum !== site?.checksums.workplaces) {
            await workplaceStore().loadWorkplaces(true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.WORKPLACES, site?.checksums?.workplaces)
          }

          if (currentLookupChecksum !== site?.checksums?.lookups) {
            await lookupStore().loadLookups(true)
            await lookupStore().loadLookupHistory(true)
            await localForage.setItem(CacheCheckSumsLocalForageKeys.LOOKUPS, site?.checksums?.lookups)
          }
        },
      })
      loggerHelper.logInfo('Cache updated')
      return true
    }
    catch (err) {
      loggerHelper.logError('Error updating cache', err)
      console.error(err)
      return false
    }
  }

  async reloadUserCache(): Promise<boolean> {
    try {
      const dbHelper = new DbHelper()
      const user = usersStore().user

      await dbHelper.setupCollectionSnapshot({
        collectionName: USERS_COLLECTION_NAME,
        checks: [
          {
            field: 'client_id',
            compare: '==',
            value: user.client_id,
          },
          {
            field: '__name__',
            compare: '==',
            value: user.id,
          },
        ],
        callback: async (users: any) => {
          const user = users[0]

          const currentCacheVersion = await localForage.getItem(CacheCheckSumsLocalForageKeys.CACHE_VERSION)

          if (currentCacheVersion === null) {
            await localForage.setItem(CacheCheckSumsLocalForageKeys.CACHE_VERSION, user?.cache_version ?? currentCacheVersion)
            return
          }

          if (user && currentCacheVersion !== user?.cache_version) {
            await localForage.setItem(CacheCheckSumsLocalForageKeys.CACHE_VERSION, user?.cache_version ?? currentCacheVersion)
            await hotReload()
          }
        },
      })
      loggerHelper.logInfo(`User ${user?.id} cache reloaded`)
      return true
    }
    catch (err) {
      loggerHelper.logError('Error updating cache', err)
      console.error(err)
      return false
    }
  }

  async createInputData(payload: any) {
    try {
      loggerHelper.logInfo(`[Input data] Frontend Api helper Updating : Input data saving for report ${payload?.inputData?.report_id} with payload ${JSON.stringify(payload?.inputData)}`)

      const resultAxios: AxiosResponse = await this.apiClient.post('/v1/input-data', payload, await this.getHeaders())

      loggerHelper.logInfo(`[Input data] Frontend Api helper Updated : Input data saved for report ${payload?.inputData?.report_id}, response : ${JSON.stringify(resultAxios?.data)}`)

      return resultAxios?.data
    }
    catch (error: any) {
      loggerHelper.logInfo(`Juno client is ${navigator?.onLine ? 'online' : 'offline'}`)
      loggerHelper.logError(`Saving input data for report ${payload?.inputData?.report_id} with payload ${JSON.stringify(payload?.inputData)} failed response ${error?.response}`, error)
    }
  }

  async updateReport(id: string, payload: object) {
    try {
      loggerHelper.logInfo(`[Report] Updating : A report is being updated with id : ${id}, report : ${JSON.stringify(payload)}`)

      const resultAxios: AxiosResponse = await this.apiClient.post(`/v1/report/${id}`, payload, await this.getHeaders())

      loggerHelper.logInfo(`[Report] Updated report : A report has been updated with id : ${resultAxios?.data.id}, response : ${JSON.stringify(resultAxios?.data)}`)

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`Updating report with id : ${id} with payload ${JSON.stringify(payload)} failed failed with status ${error.response.status}`, error)
      else
        loggerHelper.logError(`Updating report with id : ${id} with payload ${JSON.stringify(payload)} failed`, error)
    }
  }

  async generatePdfForDocument(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.get('/v1/export-pdf', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async updateTemplate(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.patch('/v1/template', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async archiveTemplate(id: string) {
    const resultAxios: AxiosResponse = await this.apiClient.delete(`/v1/template/${id}`, await this.getHeaders())

    return resultAxios?.data
  }

  async postProducts(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/products', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async postWorkplaces(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/workplaces', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async postLookups(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/lookups', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async createAlert(alert) {
    const resultAxios: any = await this.apiClient.post('/v1/alerts', alert, await this.getHeaders(),
    )

    return resultAxios?.data
  }

  async getAlerts(query = {}) {
    const resultAxios: any = await this.apiClient.get('/v1/alerts', {
      ...await this.getHeaders(),
      params: query,
    })

    return resultAxios?.data
  }

  async getAlertbyId(id: any) {
    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/alerts/${id}`, await this.getHeaders())
    return resultAxios?.data
  }

  async updateAlert(id: any, payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/alerts/${id}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async addAlertHistoryEntry(id: any, payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.post(`/v1/alerts/${id}/history`, payload, await this.getHeaders())

    return resultAxios?.data
  }

  async createNotification(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/notifications', payload, await this.getHeaders())
    return resultAxios?.data
  }

  async updateNotificationById(id: any, payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/notifications/${id}`, payload, await this.getHeaders())
    return resultAxios?.data
  }

  async updateNotificationByIds(payload: object) {
    const resultAxios: AxiosResponse = await this.apiClient.patch('/v1/notifications', payload, await this.getHeaders())
    return resultAxios?.data
  }

  async deleteNotification({ id, onReport }) {
    const resultAxios: AxiosResponse = await this.apiClient.delete(`/v1/alerts/${id}?onReport=${onReport}`, await this.getHeaders())
    return resultAxios?.data
  }

  async createStatus(status) {
    const resultAxios: any = await this.apiClient.post('/v1/status', status, await this.getHeaders())

    return resultAxios?.data
  }

  async getStatuses() {
    const resultAxios: any = await this.apiClient.get('/v1/status', {
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async updateStatus(status: any) {
    const resultAxios: any = await this.apiClient.patch(`/v1/status/${status.id}`, status, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteStatus(statusId: number) {
    const resultAxios: any = await this.apiClient.delete(`/v1/status/${statusId}`, await this.getHeaders())

    return resultAxios?.data
  }

  async enableUser(uid: string) {
    try {
      const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/user/enable/${uid}`, {}, await this.getHeaders())
      return resultAxios?.data
    }
    catch (err: any) {
      loggerHelper.logError('An error has occurred while reactivating the user', err)
    }
  }

  async disableUser(uid: string, payload: { disabled_by: string; disabled_at: number }) {
    try {
      const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/user/disable/${uid}`, payload, await this.getHeaders())
      return resultAxios?.data
    }
    catch (err: any) {
      loggerHelper.logError('An error has occurred while disabling the user', err)
    }
  }

  async createMasterSession(payload: object) {
    try {
      loggerHelper.logInfo(`[Master session] Creating : A master session is being created, master session : ${JSON.stringify(payload)}`)

      const resultAxios: AxiosResponse = await this.apiClient.post('/v1/master-sessions', payload, await this.getHeaders())

      loggerHelper.logInfo(`[Master session] Created : A Master session has been updated ${resultAxios?.data?.id}, Master session : ${JSON.stringify(resultAxios?.data)}`)

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`[Master session] Failed creating Master session with payload ${JSON.stringify(payload)} failed with status ${error.response.status}`, error.message)
      else
        loggerHelper.logError(`[Master session] Master session with payload ${JSON.stringify(payload)} failed`, error.message)
    }
  }

  async getMasterSessions() {
    try {
      const resultAxios: any = await this.apiClient.get('/v1/master-sessions', {
        ...await this.getHeaders(),
      })
      return resultAxios?.data
    }
    catch (e) {
      console.error(e)
    }
  }

  async getMasterSessionById(id: string, byProductionOrderId = false) {
    try {
      const resultAxios: any = await this.apiClient.get(`/v1/master-sessions/${id}`, {
        params: {
          id,
          byProductionOrderId,
        },
        ...await this.getHeaders(),
      })
      return resultAxios?.data
    }
    catch (e) {
      console.error(e)
    }
  }

  async updateMasterSessionById(id: number, payload: object) {
    try {
      loggerHelper.logInfo(`[Master session] Updating : A master session is being updated, master session : ${JSON.stringify(payload)}`)

      const resultAxios: AxiosResponse = await this.apiClient.post(`/v1/master-sessions/${id}`, payload, await this.getHeaders())

      loggerHelper.logInfo(`[Master session] Created : A Master session has been updated ${resultAxios?.data?.id}, Master session : ${JSON.stringify(resultAxios?.data)}`)

      return resultAxios?.data
    }
    catch (error: any) {
      if (error.response && error.response.status)
        loggerHelper.logError(`[Master session] Failed updating Master session with payload ${JSON.stringify(payload)} failed with status ${error.response.status}`, error.message)
      else
        loggerHelper.logError(`[Master session] Master session with payload ${JSON.stringify(payload)} failed`, error.message)
    }
  }

  async sendMail(mailInfos) {
    try {
      const resultAxios: AxiosResponse = await this.apiClient.post('/v1/send-mail', mailInfos, await this.getHeaders())

      return resultAxios?.data
    }
    catch (err: any) {
      loggerHelper.logError('An error has occured while sending a mail', err)
    }
  }

  async getUserRolePermissions(roleId: number) {
    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/roles-permissions?roleIds=[${roleId}]`, await this.getHeaders())

    return resultAxios?.data
  }

  async createRole(name) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/roles', { name }, await this.getHeaders())

    return resultAxios?.data
  }

  async getRoles() {
    const resultAxios: AxiosResponse = await this.apiClient.get('/v1/roles', await this.getHeaders())

    return resultAxios?.data
  }

  async updatePermission(updatePermissionForm) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/roles-permission', updatePermissionForm, await this.getHeaders())

    return resultAxios?.data
  }

  async updateRole(roleId, updateRoleForm) {
    const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/roles/${roleId}`, updateRoleForm, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteRole(roleId) {
    const resultAxios: AxiosResponse = await this.apiClient.delete(`/v1/roles/${roleId}`, await this.getHeaders())

    return resultAxios?.data
  }

  async getGroups() {
    const resultAxios: AxiosResponse = await this.apiClient.get('/v1/groups', await this.getHeaders())

    return resultAxios?.data
  }

  async getGroup(groupId) {
    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/groups/${groupId}`, await this.getHeaders())

    return resultAxios?.data
  }

  async createGroup(payload) {
    const resultAxios: AxiosResponse = await this.apiClient.post('/v1/groups', payload, await this.getHeaders())

    return resultAxios?.data
  }

  async updateGroup(groupId, payload) {
    const resultAxios: AxiosResponse = await this.apiClient.patch(`/v1/groups/${groupId}`, payload, await this.getHeaders())

    return resultAxios?.data
  }

  async deleteGroup(groupId) {
    const resultAxios: AxiosResponse = await this.apiClient.delete(`/v1/groups/${groupId}`, await this.getHeaders())

    return resultAxios?.data
  }

  // offline mode: sync unsent data with the server
  async syncUnsentData(url: string, payload: object = {}): Promise<object> {
    return this.apiClient.forcePost(url, payload, await this.getHeaders())
  }

  async startPingingJunoServer(): Promise<void> {
    try {
      const resultAxios: AxiosResponse = await this.apiClient.forceGet(BACKEND_URL, { timeout: 5000 })
        .catch(_ => console.log('error ping!'))
      const status = resultAxios.status >= 200 && resultAxios.status < 300
      gS().setLineStatus(status)
    }
    catch (e) {
      gS().setLineStatus(false)
    }

    setTimeout(() => this.startPingingJunoServer(), 6000)
  }

  async retryFailedPost() {
    if (gS().canUseCache)
      return

    localForage.keys().then(async (keys) => {
      const postKeys = keys.filter(key => key.startsWith('post_'))

      if (postKeys.length > 0) {
        const firstKey = postKeys[0]
        const item: any = await localForage.getItem(firstKey)
        const payload: any = JSON.parse(item.data.payload || '{}')
        await this.apiClient.forcePost(item.data.url, payload, await this.getHeaders())
          .then(() => {
            localForage.removeItem(firstKey)
          }).catch(_ => console.log(_))
      }

      setTimeout(() => this.retryFailedPost(), 10000)
    })
  }

  async getDocuments(payload = {}) {
    const { filters, ...pagination } = payload

    const queryString = Object
      .keys(filters || {})
      .map(key => `${key}=${filters[key]}`)
      .join('&')

    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/documents?${queryString}`, {
      params: pagination,
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async getDocumentsFromGroup(groupData) {
    const { name, id } = groupData

    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/documents?group=${name}&groupId=${id}`, {
      ...await this.getHeaders(),
    })

    return resultAxios?.data
  }

  async filterSessionDocuments({ productId, workplaceId, operationId }: {
    productId: string
    workplaceId: string
    operationId: string | null
  }) {
    const resultAxios: AxiosResponse = await this.apiClient.get('/v1/documents/session', {
      ...await this.getHeaders(),
      params: {
        productId,
        workplaceId,
        operationId,
      },
    })
    return resultAxios?.data
  }

  async filterWorkplaceDocuments(workplaceId: string) {
    const resultAxios: AxiosResponse = await this.apiClient.get(`/v1/documents/workplace/${workplaceId}`, {
      ...await this.getHeaders(),
    })
    return resultAxios?.data
  }

  async getDeviceById(id: string) {
    const result: AxiosResponse = await this.apiClient.get(`/v1/devices/${id}`, { ...await this.getHeaders() })

    return result?.data
  }

  async getDevices() {
    const result: AxiosResponse = await this.apiClient.get('/v1/devices', { ...await this.getHeaders() })

    return result?.data
  }

  async getRecordData(payload) {
    const result: AxiosResponse = await this.apiClient.get('/v1/record-data', { params: payload, ...await this.getHeaders() })

    return result?.data
  }
}

const apiHelper = new ApiHelper()

export default apiHelper
