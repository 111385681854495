import { defineStore } from 'pinia'
import RoleController from '../controllers/roles'
import { rolesStore } from './roles'
import { apiStore } from '~/store/api'
import DBHelper from '~/helpers/dbHelper/index'
import type { JUser } from '~/models/users/JUser'
import { welcomeURLDict } from '~/config/welcome-url'
import type { JClient } from '~/models/client/JClient'
import localforage from "localforage";

const dbHelper = new DBHelper()

export const usersStore = defineStore({
  id: 'users',
  state: () => ({
    user: {} as JUser,
    users: [] as JUser[],
  }),
  getters: {
    getUsers(): any {
      return this.users
    },

    alertableUsers(): any {
      return this.users
        .filter((e) => {
          return !e.is_disabled
        })
        .map((e: any) => ({
          label: `${e.first_name} ${e.last_name}`,
          value: e.id,
        }))
    },

    adminUsers(): any {
      const conceptorRoles = rolesStore().conceptorRoles

      return this.users.filter(e => e.role_ids.some(id => conceptorRoles.some(role => role.id === id)))
    },
    currentUserFullName(): string {
      return `${this.user.first_name} ${this.user.last_name}`
    },
    currentUserRoles(state): any {
      return rolesStore().findRoleByIds(state.user.role_ids)
    },
    currentUserHasOperatorAccess(state): string | null {
      return rolesStore().findRoleByIds(state.user.role_ids)?.some(role => role.operatorAccess)
    },
    currentUserHasConceptorAccess(state) {
      return rolesStore().findRoleByIds(state.user.role_ids)?.some(role => role.conceptorAccess)
    },
    currentUserWelcomeUrl(state) {
      const welcomeUrlKey = rolesStore().findRoleByIds(state.user.role_ids)?.find(role => role.welcomeUrl)?.welcomeUrl
      if (welcomeUrlKey)
        return welcomeURLDict[welcomeUrlKey]

      return undefined
    },
    getUserDesignerLandingPage() {
      const resources = Object.keys(this.user.permissions)
      const redirections = [
        { name: 'documents', to: '/documents' },
        { name: 'alerts', to: '/alerts' },
        { name: 'reports', to: '/views' },
        { name: 'settings', to: '/settings' },
      ]
      let returnPath
      for (const redirection of redirections) {
        if (resources.includes(redirection.name)) {
          returnPath = redirection.to
          break
        }
      }

      return returnPath
    },
  },
  actions: {
    async loadUsers(forceReload = false): Promise<void> {
      const api = apiStore().getApiClient
      const users = await api.getUsers(forceReload)

      this.users = users
      return users.map(e => ({
        ...e,
        role: rolesStore().findRoleById(e.role_ids?.[0] || e.roleId),
      }))
    },
    findUser(id: string, options?: any): JUser | string {
      const user = this.users.find((u: any) => u?.id === id)!

      return options?.full ? `${user.first_name} ${user.last_name}` : user
    },
    async findGlobalUser(id: string, options?: any): Promise<JUser | string> {
      const user = await dbHelper.getDocFromCollection<JUser>('users', id)

      if (!user)
        return 'user.unknown_creator'

      return options?.full ? `${user.first_name} ${user.last_name}` : user
    },
    filterUsers(ids: any) {
      return this.users.filter((u: any) => ids?.length && ids?.includes(u?.id))
    },
    updateUser(user: any) {
      const userToUpdateIndex = this.users.findIndex(currentUser => user.id === currentUser.id)
      if (userToUpdateIndex)
        this.users[userToUpdateIndex] = user
    },
    getUserByEmail(email: any) {
      return this.users.some((user: any) => user.email === email)
    },
    currentUserHasPermission(ressource, permission) {
      return permission === '*'
        ? this.user.permissions?.[`${ressource}s`]?.length > 0
        : this.user.permissions?.[`${ressource}s`]?.includes(permission)
    },
    hasAtLeastOneDesignerPermission() {
      const resources = Object.keys(this.user.permissions)
      const designerResources = rolesStore().ressources

      return resources.some(resource => designerResources.includes(resource))
    },
    checkUserRedirection() {
      const conceptorAccess = this.currentUserHasConceptorAccess
      const operatorAccess = this.currentUserHasOperatorAccess

      const welcomeUrl = this.currentUserWelcomeUrl
      if (welcomeUrl)
        return welcomeUrl

      if (conceptorAccess) {
        const returnPath = this.getUserDesignerLandingPage
        return returnPath
      }

      if (operatorAccess)
        return '/operator'

      return '/forbidden'
    },
    async loadUser(uid: string): Promise<void> {
      const user = await dbHelper.getDocFromCollection<JUser>('users', uid)
      const client = await dbHelper.getDocFromCollection<JClient>('clients', user?.client_id)
      const userPermissions = await RoleController.getUserRolePermissions(user?.role_ids) || []

      if (client)
        user.client_name = client.name || ''

      await localforage.setItem('user-language', user?.language)

      this.user = {
        ...user,
        permissions: {
          ...userPermissions,
        },
      }
    },
  },
})
