import { defineStore } from 'pinia'
import { NotificationType } from '~/common/models/notification'
import type { NotificationViewModel } from '~/common/models/notification'
import { NotificationState } from '~/models/notifications/jNotification'
import NotificationController from '~/controllers/notifications'
import { AlertStatus } from '~/common/models/alerts'

export const notificationStore = defineStore({
  id: 'notifications',
  state: () => ({
    notifications: [] as NotificationViewModel[],
  }),
  actions: {
    getNotifications(notificationType: NotificationType | string) {
      return this.notifications
        .filter(notification => notification.type === notificationType)
        .filter((notification) => {
          return notification.type === NotificationType.ALERT
            ? notification.alert?.status !== AlertStatus.FINISHED
            : notification
        })
        .sort((a, b) => (b.date as number) - (a.date as number))
    },
    updateNotifications(notifications: NotificationViewModel[]) {
      notifications.forEach((notification) => {
        const currentNotifIndex = this.notifications.findIndex(e => e.id === notification.id)

        currentNotifIndex >= 0
          ? this.notifications.splice(currentNotifIndex, 1, notification)
          : this.notifications.push(notification)
      })

      this.notifications = this.notifications.filter(notif => notif.status.state !== NotificationState.READ)
    },
    updateAlertInNotification({ alertId, status }) {
      const notification = this.notifications.find(e => e.alert?.id === alertId)!

      if (notification)
        notification.alert!.status = status
    },
    async removeAlertNotification(notification) {
      try {
        await NotificationController.updateNotificationState(notification, {
          notificationId: notification.id,
          state: NotificationState.READ,
        })

        const notificationIndex = this.notifications.findIndex(e => e.id === notification.id)

        this.notifications.splice(notificationIndex, 1)
      }
      catch (e) {
        console.error(e)
      }
    },
    async removeNotificationGroup(notificationGroup) {
      const groupNotifications = this.notifications
        .filter(e => e.type === NotificationType.REMINDER && e.reportInfo?.name === notificationGroup)

      for (const notification of groupNotifications) {
        await NotificationController.updateNotificationState(notification, {
          notificationId: notification.id,
          state: NotificationState.READ,
        })

        const notificationIndex = this.notifications.findIndex(e => e.id === notification.id)

        this.notifications.splice(notificationIndex, 1)
      }
    },
  },
})

