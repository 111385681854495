<script setup lang="ts">
import vSelect from 'vue-select'
import { setValueOrNA } from '~/controllers/reports/reportsController'
import 'vue-select/dist/vue-select.css'
import { capitalizeFirstLetter, truncateString } from '~/utils/formatter'
import { isMultipleAnswers, onHistoryClick, getLastUpdatedByOnCell } from '~/helpers/UtilsHelper'
import { createPopper } from '@popperjs/core'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  optionsToShow: {
    type: Number,
    default: null,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
  rowIndex: {
    type: Number,
    default: 0,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
}>()

const NUMBER_OF_OPTIONS_TO_SHOW = props.optionsToShow ? props.optionsToShow : 2

const state = reactive({
  valSelect: props.params?.value,
  isMultiple: false,
  options: [],
  isActivated: true,
  searched: '',
  isHistory: props.params?.data?.isHistory,
  lastUpdatedBy: [] as string[],
  refName: `cell_inactive_div_${props.params?.colDef?.colId}_${props.params?.rowIndex}`,
})

const route = useRoute()

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  const { data, colDef } = props.params

  const colDefAnswers = colDef?.answers?.filter(
    answer =>
      answer.col_id === colDef.index - data.shiftIndex
      && answer.row_id === data.index,
  )

  if (
    data?.isDisabled
    || colDef?.disabledStepIds?.includes(data?.stepId)
    || (data?.branching?.length && colDefAnswers?.length)
  )
    return false

  return colDef
    ? props.params?.colDef?.editable(props.params, false)
    : props.isCleared
})

const init = () => {
  state.options = props.params?.data?.details?.selectOptions
  state.lastUpdatedBy = [getLastUpdatedByOnCell(props.params)]

  state.isMultiple = props.params?.data?.details?.isMultiple || false
  let customOptions
    = state.isMultiple && state.valSelect
      ? state.valSelect?.filter(
        val => !state.options?.map(e => e.value)?.includes(val),
      )
      : []
  customOptions = customOptions?.map((option) => {
    return {
      label: option,
      value: option,
    }
  })
  state.options = state.options?.concat(customOptions)
  state.options = state.options?.sort((a: any, b: any) =>
    a.label > b.label ? 1 : a.label < b.label ? -1 : 0,
  )
  state.valSelect = props.isCleared
    ? null
    : props.params?.answer?.value || props.params?.value
}

const updateData = () => {
  const colId = props.params?.column?.colId
  emit('setNewValue', state.valSelect)
  props.params?.node?.setDataValue(colId, state.valSelect)
}

const hasSelectedField = (value: string) => {
  return state.valSelect?.includes(value)
}

const isElementVisible = (value: string) => {
  if (!state.isMultiple)
    return true
  if (!Array.isArray(state.valSelect))
    return true
  return (
    state.valSelect.findIndex(item => item === value)
    < NUMBER_OF_OPTIONS_TO_SHOW
  )
}

const isCountElementVisible = (value: string) => {
  if (!state.isMultiple)
    return false
  const index = state.valSelect?.findIndex(item => item === value)
  return (
    index === NUMBER_OF_OPTIONS_TO_SHOW
    && state.valSelect?.length > NUMBER_OF_OPTIONS_TO_SHOW
  )
}

const countSelection = () => {
  if (!state.isMultiple)
    return
  return `+ ${state.valSelect?.length - NUMBER_OF_OPTIONS_TO_SHOW}`
}
const activateCell = () => {
  if (isHistory.value || !isEditable?.value)
    return

  state.isActivated = true
}

const splitString = (values: Object) => {
  if (values) {
    let result: string | Array<object> = ''
    if (typeof values === 'string') {
      result = capitalizeFirstLetter(values)
    }
    else {
      const arrayValues = Object.values(values)
        .map(value => capitalizeFirstLetter(value).replaceAll('-', ' '))
        .join('\n')
      result = arrayValues
    }
    return result
  }
}

state.isActivated = props.params?.data
  ? setValueOrNA(state.valSelect, props.params)
  : state.isActivated

const createNewOption = () => {
  state.options.push({ label: state.searched, value: state.searched })

  state.valSelect = state.valSelect?.length && props.params?.data?.details?.isMultiple ? [...state.valSelect, state.searched] : [state.searched]

  const colId = props.params?.column?.colId
  props.params?.node?.setDataValue(colId, state.valSelect)
}

const onSearch = (string) => {
  state.searched = string
}

const withPopper = (dropdownList, component) => {
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle(
            'drop-up',
            state.placement === 'top',
          )
        },
      },
    ],
  })
  return () => popper.destroy()
}

const showHistory = computed(() => {
  return isMultipleAnswers(props.params) && props.params?.data?.isHistoryEnabled
})

init()
</script>

<template>
  <div
    v-if="!props.params.data.hidden"
    class="flex flex-1 justify-center _data-cy-select-list"
    :class="[
      {
        '_data-cy-select-unique': !props.params?.data?.details?.isMultiple,
        '_data-cy-select-multiple': props.params?.data?.details?.isMultiple,
        '_data-cy-is-history': state.isHistory,
      },
      `_data-cy-row-${props.params.rowIndex}`,
    ]"
    select-list
    data-cy="select-list"
    :data-cy-column="props.params?.colDef?.index - props.params?.data?.details?.initialShiftIndex
    "
  >
    <div
      v-if="showHistory"
      v-can.any="['read-report', 'write-report']"
      class="absolute right-1 top-1"
      data-cy="history-button"
      @click="onHistoryClick(props.params)"
    >
      <component
        :is="Icons.DATA_BACKUP"
        class="text-grey-500 w-5 h-5 cursor-pointer"
      />
    </div>
    <div
      v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
      class="absolute right-1 bottom-1 z-50"
      data-cy="avatar-button"
    >
      <AvatarMultiple
        :user-ids="state.lastUpdatedBy"
        hide-full-name
        display-amount
        is-enable-modal
      />
    </div>
    <div
      v-if="state.isActivated"
      class="flex flex-1 max-h-[64px]"
      :tooltip="splitString(state.valSelect)"
      :flow="props.rowIndex === 0 && props.params?.data?.nbSteps !== props.params?.data?.id ? 'down' : 'up'"
    >
      <v-select
        v-model="state.valSelect"
        class="options-select"
        data-cy-select="select"
        :class="{
          'w-full': !showHistory,
          'w-95p': showHistory,
          'error': props.isError,
        }"
        :multiple="state.isMultiple"
        :deselect-from-dropdown="true"
        label="label"
        :disabled="state.isHistory || !isEditable"
        :reduce="(option) => option.value"
        :options="state.options"
        :no-drop="state.isHistory || !isEditable"
        :clearable="!state.isHistory"
        append-to-body
        :calculate-position="withPopper"
        :close-on-select="!state.isMultiple"
        @search="onSearch"
        @close="updateData"
      >
        <template #no-options>
          <div
            v-if="props.params?.data?.details?.isCustomized"
            class="hover:bg-slate-100 hover:cursor-pointer gap-2 text-sm items-center flex flex-row py-2 px-1"
            @click="createNewOption"
            @keydown.enter="createNewOption"
          >
            <component :is="Icons.ADD" />
            <span>{{ $t("step.create_answer") }}</span>
          </div>
        </template>
        <template #option="{ value, label }">
          <div class="flex align-middle justify-between">
            <div class="flex flex-row">
              <carbon:checkbox-checked-filled
                v-if="hasSelectedField(value)"
                class="-ml-2 mr-2 text-[#3273f6]"
              />
              <carbon:checkbox
                v-else
                class="-ml-2 mr-2"
              />
              <label
                class="text-sm text-black"
                for="option"
              >{{ label }}</label>
            </div>
          </div>
        </template>
        <template #selected-option-container="{ option }">
          <div
            :style="isElementVisible(option.value) ? '' : 'display:none'"
            class="vs__selected"
          >
            <div class="text-sm leading-6 rounded-md w-max">
              <div class="flex flex-1 flex-wrap w-max">
                <span>{{ truncateString(option.label, 10) }}</span>
              </div>
            </div>
          </div>
          <div
            :style="isCountElementVisible(option.value) ? '' : 'display:none'"
            class="vs__selected"
          >
            <div class="h-6 text-sm leading-6 rounded-md px-1">
              <div class="flex flex-1 flex-wrap">
                <span :flow="props.params?.rowIndex > 1 ? 'up' : 'down'">
                  {{ countSelection() }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </v-select>
    </div>

    <div
      v-else
      :id="state.refName"
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5 items-center flex justify-center self-center"
      @click="activateCell()"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss">
.w-95p {
  width: 95%;
}

.options-select {
  & .vs__dropdown-toggle {
    @apply border-gray-200;
  }

  & .vs__selected {
    @apply font-normal mb-px text-black;
    border: none;
    background-color: #eeeeee !important;
  }

  & .vs__dropdown-menu {
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 4px);
    z-index: 99999999;
  }

  & .vs__search {
    @apply text-gray-400 text-sm;
  }

  & .vs__dropdown-option--highlight {
    background: #f0f2ff;
    color: black;
  }

  & .vs__dropdown-option {
    white-space: unset !important;
  }

  &.error>div:first-child {
    @apply border-solid border border-red-500;
  }
}

.vs__dropdown-menu {
    width: max-content;
  }

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
