import * as papaparse from 'papaparse'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { utils, writeFile } from 'xlsx'
import _ from 'lodash'
import {
  createReportPreview, getMultipleRichExport, getRichExport,
  getRichReportPdf,
  getSimpleReportPdf,
  mapViewModelToGrid,
} from './../controllers/reports/reportsController'
import type { JStep } from './../models/documents/jStep'
import { StepType } from './../models/documents/jStep'
import DbHelper from '~/helpers/dbHelper'
import { findObjectInArrayById, getAllValueByKey, hydrateListIdWithListObject } from '~/utils/array'
import loggerHelper from '~/helpers/LoggerHelper'
import { formatDate, formatShortDate, formatShortDateWitHourMinute } from '~/utils/formatter'
import { getFullReportById } from '~/controllers/reports/reportsController'
import { getAllSessions } from '~/controllers/sessions/'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import { getGridHeader } from '~/services/grid'
import { getTags } from '~/controllers/documents'
import { siteStore } from '~/store/site'
import { STEPS_COLLECTION_NAME } from '~/config/storage'
import { documentSettingsStore } from '~/store/documentSettings'
import { DocumentSettingsType } from '~/models/documents/documentSettings'
import { i18n } from '~/modules/i18n'
import { usersStore } from '~/store/users'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import type { ExportTarget } from '~/config/export'
import { targetInfos } from '~/config/export'

const dbHelper = new DbHelper()
const { t } = i18n.global

export enum ColumnState {
  new = 'new',
  inProgress = 'in_progress',
  ok = 'ok',
  ko = 'ko',
  alerted = 'alerted',
}

const parserTypeStepToTypeCsv = (typeStep) => {
  switch (typeStep) {
    case StepType.Boolean:
      return 'OK/NoK'
    case StepType.Measure:
      return 'Mesure'
    case StepType.Time:
      return 'Time'
    case StepType.Checkbox:
      return 'Checkbox'
    case StepType.Text:
      return 'Texte'
    case StepType.Number:
      return 'Number'
    case StepType.List:
      return 'List'
  }
}

const parserDataToCellCsv = (typeData, data) => {
  switch (typeData) {
    case StepType.Boolean:
      switch (String(data).toUpperCase()) {
        case 'TRUE':
          return 'OK'
        case 'FALSE':
          return 'NOK'
        case 'N/A':
          return '-'
        case '':
          return ''
        case 'NA':
          return 'NA'
        default:
          return ''
      }
    case StepType.Checkbox:
      switch (String(data).toUpperCase()) {
        case 'TRUE':
          return 'COCHÉ'
        case '':
          return 'NÉANT'
        default:
          return ''
      }
    case StepType.Measure: case StepType.Text:
      if (data === 'N/A')
        return '-'
      return data
    case StepType.Time:
      if (data === 'N/A')
        return '-'
      return data
    case StepType.List:
      return data
    case StepType.Number:
      if (data === 'N/A')
        return '-'
      return data
    default:
      return ''
  }
}

export const createObjectCsvForDownloadOnWindow = (fileCsv, fileName) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(fileCsv)
  downloadLink.download = fileName
  downloadLink.click()
}

export const downloadFromUrl = (url, fileName) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = fileName
  downloadLink.click()
}

export const simpleExportToCsv = (steps: any, nbColumn: any, report: any, type: any) => {
  if (type === 'CSV') {
    const { content, fileName } = createSimpleCsv(steps, nbColumn, report)
    createObjectCsvForDownloadOnWindow(content, fileName)
  }
  else {
    const { content, fileName } = createSimpleExcel(steps, nbColumn, report)
    createObjectCsvForDownloadOnWindow(content, fileName)
  }
}

const fitToColumn = (arrayOfArray: any) => {
  // get maximum character of each column
  return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }))
}

export const richExportToCsv = async (steps: any, nbColumn: any, report: any, type: any) => {
  // @ts-expect-error
  if (type === 'CSV') {
    const { content, fileName } = await createRichCsv(steps, nbColumn, report, type)
    createObjectCsvForDownloadOnWindow(content, fileName)
  }
  else {
    const { content, fileName } = await createRichExcel(steps, nbColumn, report, type)
    createObjectCsvForDownloadOnWindow(content, fileName)
  }
}

const checkIfMeasureType = (steps) => {
  return steps.some(step => step.type === StepType.Measure)
}

export const getRichExcelDefinition = async (steps: any, nbColumn: any, report: any) => {
  const merges = []
  const hasOperations = siteStore().site?.flags?.application_fields?.operations
  // const reasons = settingsStore().filterSettings(SettingsType.template_input_modification_message, true)

  const usersList = usersStore().getUsers
  const reasons = settingsStore().settings.filter(setting => setting.type === SettingsType.reasons)
  const statusHistory = report?.status_history?.reverse() ?? []
  const listRow: any = []
  const containsStepMeasureType = checkIfMeasureType(steps)
  const hasAttachments = report.steps.some(step => step.files_attached?.length)
  let targetRow: any = [t('export.change_target'), '', '', '', '']

  if (containsStepMeasureType)
    targetRow = targetRow.concat(['', '', '', ''])
  if (hasAttachments)
    targetRow = targetRow.concat([''])
  let documentHeaderRows = [] as any[]
  const updatedBy = statusHistory?.map((history) => {
    const user = usersList ? usersList.find(user => user.id === history.updated_by) : {}
    return `${user.last_name} ${user.first_name}`
  }) ?? []
  const updatedAt = statusHistory.map((history) => {
    const date = new Date(history.update_date)
    return formatDate(date)
  })
  let isDynamic = false

  const status = statusHistory?.map((history) => {
    const status = siteStore().getStatus(Number.parseInt(history.status))
    if (status?.builtIn)
      return status.name ? t(`report.status_${status.name}`) : ''
    else
      return status.name
  })

  const applicationFields = [[t('report.job'), t('global.workplace'), t('global.product')],
    [report.context.production_order_id, report.context.workplace_id, report.context.product_id]]

  if (hasOperations) {
    applicationFields[0].push(t('global.operation'))
    applicationFields[1].push(report.context.operation_id)
  }
  documentHeaderRows.push(
    [t('export.report_title'), report.document_name],
    [t('export.type'), t('global.enriched_export')],
    [t('export.status')].concat(status),
    [t('export.modified_by')].concat(updatedBy),
    [t('export.modified_at')].concat(updatedAt),
    [],
  )

  documentHeaderRows = documentHeaderRows.concat(applicationFields)
  documentHeaderRows.push([])

  let historyRow = ['', '', '', '', '']
  const baseListOfHeaders = ['id', t('export.name'), t('export.step_type'), t('export.description'), t('export.tool')]
  if (hasAttachments) {
    historyRow.push('')
    baseListOfHeaders.push('attachment')
  }

  const listHeader = baseListOfHeaders
    .concat(containsStepMeasureType ? [t('export.goal'), t('export.min'), t('export.max'), t('export.unit')] : [])

  historyRow = historyRow.concat(containsStepMeasureType ? ['', '', '', ''] : [])

  const updatesByColumnsCount = [] as any[]

  let dynamicCols: any = []
  steps?.forEach((step, colIndex) => {
    if (step.last_targets)
      dynamicCols = dynamicCols.concat(step.last_targets.map(e => e.init_col_id))
  })

  if (report.type !== 'NIR') {
    for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
      const updatesByColumn = _.max(steps.map(step => step.historyAnswers?.[colIndex]?.length ?? 0)) ?? 0
      listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)}`)
      listHeader.push(`${t('export.operator')}-${(colIndex + 1)}`)
      listHeader.push(`${t('export.update_date')}-${(colIndex + 1)}`)
      historyRow.push(...['', '', ''])
      if (dynamicCols.includes(colIndex)) {
        isDynamic = true
        targetRow.push('X')
      }
      else {
        targetRow.push('')
      }
      targetRow.push(...['', ''])
      for (let i = 0; i < updatesByColumn; i++) {
        historyRow = historyRow.concat([`${t('export.historic_value')}-${i + 1}`, `${t('export.historic_value')}-${i + 1}`, `${t('export.historic_value')}-${i + 1}`, `${t('export.historic_value')}-${i + 1}`])
        listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)}`)
        listHeader.push(`${t('export.operator')}-${(colIndex + 1)}`)
        listHeader.push(`${t('export.update_date')}-${(colIndex + 1)}`)
        listHeader.push(`${t('export.justification')}-${(colIndex + 1)}`)
        merges.push({ s: { c: historyRow.length - 4, r: 9 }, e: { c: historyRow.length - 1, r: 9 } })
        targetRow.push(...['', '', '', ''])
      }
      updatesByColumnsCount.push(updatesByColumn)
    }
  }
  documentHeaderRows.forEach((row) => {
    listRow.push(row)
  })

  listRow.push(historyRow)
  listRow.push(listHeader)

  // add to header the patern column : value / operator / updateDate
  if (isDynamic)
    listRow.push(targetRow)

  // build rows
  // const listTag = await getTags()
  for (const step of steps) {
    const listSamplingAreas = step.last_sampling_areas // true means activated, false not activated so set 'N/A'

    // create variable for the row
    const id = step.id
    const name = step.name
    const type = parserTypeStepToTypeCsv(step.type)
    const description = step.description
    const measurement_tool = step.means_of_measure
    const unit = step.unit
    const goal = step.is_dynamic ? t('report.dynamic') : step?.goal || ''
    const min = step?.range?.[0] ? `${step?.range?.[0]}` : ''
    const max = step?.range?.[1] ? `${step?.range?.[1]}` : ''

    // hydrate & stringify list tags
    const baseRows = [id, name, type, description, measurement_tool]
    if (hasAttachments)
      baseRows.push(step.files_attached?.length ? step.files_attached[0].name : '')
    const row = baseRows.concat(containsStepMeasureType ? [goal, min, max, unit] : [])

    // add to row the values column
    for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
      const answer = step.answers.find(answer => answer.step_id === step.id && answer.col_id === colIndex)

      let patternColumnToAddToRow = [] as any[]
      if (!answer) {
        patternColumnToAddToRow = [
          '',
          '',
          '',
        ]

        row.push(...patternColumnToAddToRow)
      }
      else {
        let operator = answer?.updated_by || ''
        if (operator !== '') {
          operator = findObjectInArrayById(usersList, operator)
          operator = operator?.first_name
        }
        const updatedDate = answer?.update_date || ''
        const cellValue = parseCellValueForCsv(
          answer?.value,
          step.type,
          listSamplingAreas?.[colIndex],
        )

        patternColumnToAddToRow = [
          cellValue,
          operator,
          formatShortDateWitHourMinute(updatedDate),
        ]
        row.push(...patternColumnToAddToRow)
      }

      const updatesByColumn = updatesByColumnsCount[colIndex]
      if (report.type !== 'NIR') {
        for (let i = 0; i < updatesByColumn; i++) {
          const cellHistory = step.historyAnswers?.[colIndex] ?? []
          const cell = cellHistory[i]
          if (cell) {
            let operator = cell?.updated_by || ''
            if (operator !== '') {
              operator = findObjectInArrayById(usersList, operator)
              operator = operator?.first_name
            }
            const updatedDate = cell?.update_date || ''
            const cellValue = parseCellValueForCsv(
              cell?.value,
              step.type,
              listSamplingAreas?.[colIndex],
            )
            let previousCellReason = {}
            if (i !== 0)
              previousCellReason = cellHistory[i - 1]?.reason

            else
              previousCellReason = answer.reason

            const previousCellReasonText = previousCellReason !== '' && previousCellReason ? reasons.find(reason => reason.id === previousCellReason)?.value : ''
            const reason = previousCellReasonText ?? previousCellReason
            const patternColumnToAddToRow = [
              cellValue,
              operator,
              formatShortDateWitHourMinute(updatedDate),
              reason ?? '',
            ]
            row.push(...patternColumnToAddToRow)
          }
          else {
            row.push(...['', '', '', ''])
          }
        }
      }
    }
    listRow.push(row)
  }
  // custom state of the columns

  return { listRow, merges }
}

export const createRichExcel = async (steps: any, nbColumn: any, report: any) => {
  try {
    const { listRow, merges } = await getRichExcelDefinition(steps, nbColumn, report)
    // Build csv
    const csv = papaparse.unparse({ fields: [], data: listRow })

    const xls = papaparse.parse(csv)
    const fileName = `${t('global.report')}_${report.document_name}-${formatDate(
        report.update_date,
      )}_${t('global.enriched')}.xlsx`
    const data = utils.aoa_to_sheet(xls.data)
    const wb = utils.book_new()
    data['!cols'] = fitToColumn(xls.data)
    data['!merges'] = merges
    utils.book_append_sheet(wb, data, report.document_name.replace('/', '-').substring(0, 26))

    writeFile(wb, fileName)
  }
  catch (error) {
    loggerHelper.logError(error, `Cannot export report : ${report.id}`)
    console.error(error)
  }
}

export const createRichCsv = async (steps: any, nbColumn: any, report: any) => {
  const { id: idReport } = report
  try {
    const usersList = usersStore().getUsers
    const reasons = settingsStore().settings.filter(setting => setting.type === SettingsType.reasons)

    const rowsList: any = []
    const containsStepMeasureType = checkIfMeasureType(steps)
    let targetRow: any = [t('export.change_target')]

    // build header, if contains step measure type, adding the header for the measure
    const listHeader = ['id', t('export.name'), t('export.step_type')]
      .concat(containsStepMeasureType ? [t('export.goal'), t('export.min'), t('export.max')] : [])
      .concat([t('report.job'), t('global.operation'), t('global.product'), t('global.workplace'), t('export.tags')])
    for (let i = 0; i < listHeader.length - 1; i++)
      targetRow = targetRow.concat([''])

    const updatesByColumnsCount = []
    // add to header the patern column : value / operator / updateDate
    for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
      const updatesByColumn = _.max(steps.map(step => step.historyAnswers?.[colIndex]?.length ?? 0)) ?? 0
      const headerCellValue = `${getGridHeader(report.grid_header)}-${colIndex + 1}`
      const headerCellOperator = `${t('export.operator')}-${colIndex + 1}`
      const headerCellUpdateDate = `${t('export.update_date')}-${colIndex + 1}`
      let dynamicCols: any = []
      steps?.forEach((step) => {
        if (step.last_targets)
          dynamicCols = dynamicCols.concat(step.last_targets.map(e => e.init_col_id))
      })

      if (dynamicCols.includes(colIndex))
        targetRow.push('X')
      else
        targetRow.push('')
      listHeader.push(...[headerCellValue, headerCellOperator, headerCellUpdateDate])
      targetRow = targetRow.concat(['', ''])

      for (let i = 0; i < updatesByColumn; i++) {
        listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)} (${t('export.historic_value')}-${i + 1})`)
        listHeader.push(`${t('export.operator')}-${colIndex + 1} (${t('export.historic_value')}-${i + 1})`)
        listHeader.push(`${t('export.update_date')}-${colIndex + 1} (${t('export.historic_value')}-${i + 1})`)
        listHeader.push(`${t('export.justification')}-${colIndex + 1} (${t('export.historic_value')}-${i + 1})`)
        targetRow.push(...['', '', '', ''])
      }
      updatesByColumnsCount.push(updatesByColumn)
    }
    rowsList.push(targetRow)
    // build rows
    const listTag = await getTags()
    // const listTag = await getTags()

    for (const step of steps) {
      const listSamplingAreas = step.last_sampling_areas // true means activated, false not activated so set 'N/A'

      // create variable for the row
      const id = step.id
      const name = step.name
      const type = parserTypeStepToTypeCsv(step.type)
      const goal = step.is_dynamic ? t('report.dynamic') : step?.goal || ''
      const min = step?.range?.[0] ? `${step?.range?.[0]}` : ''
      const max = step?.range?.[1] ? `${step?.range?.[1]}` : ''
      const workOrder = report?.context?.production_order_id
      const operation = report?.context?.operation_id
      const product = report?.context?.product_id
      const workplace = report?.context?.workplace_id

      // hydrate & stringify list tags
      let tags = hydrateListIdWithListObject(step.tags, listTag)
      tags = getAllValueByKey(tags, 'value')
      tags = tags?.length > 0 ? tags?.join('|') : ''
      // let tags = hydrateListIdWithListObject(step.tags, listTag)
      // tags = getAllValueByKey(tags, 'value')
      // tags = tags?.length > 0 ? tags?.join('|') : ''

      const row = [id, name, type]
        .concat(containsStepMeasureType ? [goal, min, max] : [])
        .concat([workOrder, operation, product, workplace, tags])
      // add to row the values column
      for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
        const answer = step.answers.find(answer => answer.step_id === step.id && answer.col_id === colIndex)
        let operator = answer?.updated_by || ''
        if (operator !== '') {
          operator = findObjectInArrayById(usersList, operator)
          operator = operator?.first_name
        }
        const updatedDate = answer?.update_date || ''
        const cellValue = parseCellValueForCsv(
          answer?.value,
          step.type,
          listSamplingAreas?.[colIndex],
        )
        const patternColumnToAddToRow = [
          cellValue,
          operator,
          formatShortDateWitHourMinute(updatedDate),
        ]
        row.push(...patternColumnToAddToRow)
        const updatesByColumn = updatesByColumnsCount[colIndex]

        for (let i = 0; i < updatesByColumn; i++) {
          const cellHistory = step.historyAnswers?.[colIndex] ?? []
          const cell = cellHistory[i]
          if (cell) {
            let operator = cell?.updated_by || ''
            if (operator !== '') {
              operator = findObjectInArrayById(usersList, operator)
              operator = operator?.first_name
            }
            const updatedDate = cell?.update_date || ''
            const cellValue = parseCellValueForCsv(
              cell?.value,
              step.type,
              listSamplingAreas?.[colIndex],
            )
            let previousCellReason = {}
            if (i !== 0)
              previousCellReason = cellHistory[i - 1]?.reason

            else
              previousCellReason = answer.reason

            const previousCellReasonText = previousCellReason !== '' && previousCellReason ? reasons.find(reason => reason.id === previousCellReason)?.value : ''
            const reason = previousCellReasonText ?? previousCellReason
            const patternColumnToAddToRow = [
              cellValue,
              operator,
              formatShortDateWitHourMinute(updatedDate),
              reason ?? '',
            ]
            row.push(...patternColumnToAddToRow)
          }
          else {
            row.push(...['', '', '', ''])
          }
        }
      }
      rowsList.push(row)
    }
    const reportData = [...rowsList]
    // Build csv
    const csv = papaparse.unparse({ fields: listHeader, data: reportData })

    const csvData = new Blob([`\uFEFF${csv}`], {
      type: 'text/csv;charset=UTF-8',
    })
    const fileName = `Report_${report.document_name}-${formatDate(
          report.update_date,
      )}.csv`
    return {
      content: csvData,
      fileName,
    }
  }
  catch (error) {
    loggerHelper.logError(`Cannot export report : ${idReport}`, error)
    console.error(error)
  }
}

export const simpleExportToPDF = async (reportId, document_name) => {
  const pdfReport = await getSimpleReportPdf(reportId)
  const date = new Date()
  const fileName = `export_simple_Rapport_${document_name}_${formatDate(date)}.pdf`
  createObjectCsvForDownloadOnWindow(new Blob([pdfReport], { type: 'application/pdf' }), fileName)
}

export const richExportToPDF = async (reportId, document_name) => {
  const pdfReport = await getRichReportPdf(reportId)
  const date = new Date()
  const fileName = `export_riche_Rapport_${document_name}_${formatDate(date)}.pdf`
  createObjectCsvForDownloadOnWindow(new Blob([pdfReport], { type: 'application/pdf' }), fileName)
}

export const getRichReportExport = async (report: any, target: ExportTarget) => {
  const reportExportFile = await getRichExport(report.id, target)
  const date = new Date()
  const fileName = `export_riche_Rapport_${report.document_name}_${formatDate(date)}.${targetInfos[target].extension}`
  createObjectCsvForDownloadOnWindow(new Blob([reportExportFile], { type: targetInfos[target].type }), fileName)
}

export const getMultipleRichReportExport = async (reportIds: any, target: ExportTarget) => {
  const reportExportFile = await getMultipleRichExport(reportIds, target)
  const date = new Date()
  const fileName = `export_riche_Rapport_${formatDate(date)}.${targetInfos[target].MultipleExtension}`
  createObjectCsvForDownloadOnWindow(new Blob([reportExportFile], { type: targetInfos[target].MultipleType }), fileName)
}

const parseCellValueForCsv = (cellValue: any, stepType: any, samplingAreas: any) => {
  cellValue = isUndefinedOrNullOrEmpty(cellValue) ? '' : String(cellValue)
  if (!samplingAreas && cellValue === '')
    cellValue = 'N/A'
  cellValue = parserDataToCellCsv(stepType, cellValue)
  return cellValue
}

export const getSimpleExcelDefinition = async (steps: any, nbColumn: any, report: any) => {
  const containsStepMeasureType = checkIfMeasureType(steps)
  const hasOperation = siteStore().site?.flags?.application_fields?.operations
  try {
    const listUser = usersStore().getUsers
    let historyRow = [] as any[]
    const merges = [] as any[]
    const statusHistory = report?.status_history?.reverse() ?? []
    const reasons = settingsStore().settings.filter(setting => setting.type === SettingsType.reasons)

    const listRow: any = []

    let documentHeaderRows = [] as any[]
    const updatedBy = statusHistory?.map((task) => {
      const user = listUser ? listUser.find(user => user.id === task.updated_by) : {}
      return `${user.last_name} ${user.first_name}`
    })
    const updatedAt = statusHistory?.map((history) => {
      const update_date = new Date(history.update_date)
      return formatDate(update_date)
    })
    const status = statusHistory?.map((history: any) => {
      const status = siteStore().getStatus(Number.parseInt(history.status))
      if (status?.builtIn)
        return status?.name ? t(`report.status_${status.name}`) : ''
      else
        return status?.name
    })
    const applicationFields = [[t('report.job'), t('global.workplace'), t('global.product')],
      [report.context.production_order_id, report.context.workplace_id, report.context.product_id]]

    if (hasOperation) {
      applicationFields[0].push(t('global.operation'))
      applicationFields[1].push(report.context.operation_id)
    }
    documentHeaderRows.push(
      [t('export.report_title'), report.document_name],
      [t('export.type'), t('global.simple_export')],
      [t('export.status')].concat(status),
      [t('export.modified_by')].concat(updatedBy),
      [t('export.modified_at')].concat(updatedAt),
      [],
    )
    documentHeaderRows = documentHeaderRows.concat(applicationFields)
    documentHeaderRows.push([])
    const targetRow: any = [t('export.change_target')].concat(containsStepMeasureType ? ['', '', '', ''] : [])
    historyRow = historyRow.concat(containsStepMeasureType ? ['', '', '', '', ''] : [''])
    // build header
    const listHeader = ['id', t('export.name')].concat(containsStepMeasureType ? [t('export.goal'), t('export.min'), t('export.max')] : [])
    // add to header the values column
    let dynamicCols: any = []
    steps?.forEach((step, colIndex) => {
      if (step.last_targets)
        dynamicCols = dynamicCols.concat(step.last_targets.map(e => e.init_col_id))
    })

    let isDynamic = false

    const updatesByColumnsCount = [] as any[]

    if (report.type !== 'NIR') {
      for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
        const updatesByColumn = _.max(steps.map(step => step.historyAnswers?.[colIndex]?.length ?? 0)) ?? 0
        listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)}`)
        historyRow.push('')
        if (dynamicCols.includes(colIndex)) {
          isDynamic = true
          targetRow.push('X')
        }
        else {
          targetRow.push('')
        }
        for (let i = 0; i < updatesByColumn; i++) {
          historyRow = historyRow.concat([`${t('export.historic_value')}-${i + 1}`, `${t('export.historic_value')}-${i + 1}`])
          listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)}`)
          listHeader.push(`${t('export.justification')}-${colIndex}`)
          merges.push({ s: { c: historyRow.length - 2, r: 9 }, e: { c: historyRow.length - 1, r: 9 } })
          targetRow.push(...['', ''])
        }
        updatesByColumnsCount.push(updatesByColumn)
      }
    }
    documentHeaderRows.forEach((row) => {
      listRow.push(row)
    })

    listRow.push(historyRow)
    listRow.push(listHeader)
    if (isDynamic)
      listRow.push(targetRow)
    // build rows
    steps.forEach((step: JStep) => {
      const listSamplingAreas = step.last_sampling_areas // true means activated, false not activated so set 'N/A'
      // begin of row with only headers of row
      const goal = step.is_dynamic ? t('report.dynamic') : step?.goal || ''
      const min = step?.range?.[0] ? `${step?.range?.[0]}` : ''
      const max = step?.range?.[1] ? `${step?.range?.[1]}` : ''
      const row = [step.id, step.name].concat(containsStepMeasureType ? [goal, min, max] : [])
      // add to row the values column
      for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
        const answer = step.answers.find(answer => answer.step_id === step.id && answer.col_id === colIndex)
        const cellValue = parseCellValueForCsv(
          answer?.value,
          step.type,
          listSamplingAreas?.[colIndex],
        )
        row.push(cellValue)

        if (report.type === 'NIR')
          continue

        const updatesByColumn = updatesByColumnsCount[colIndex]

        const cellHistory = step.historyAnswers?.[colIndex] ?? []
        for (let i = 0; i < updatesByColumn; i++) {
          const cell = cellHistory[i]
          if (cell) {
            const cellValue = parseCellValueForCsv(
              cell?.value,
              step.type,
              listSamplingAreas?.[colIndex],
            )
            let previousCellReason = {}
            if (i !== 0)
              previousCellReason = cellHistory[i - 1]?.reason

            else
              previousCellReason = answer.reason

            const previousCellReasonText = previousCellReason !== '' && previousCellReason ? reasons.find(reason => reason.id === previousCellReason)?.value : ''
            const reason = previousCellReasonText ?? previousCellReason
            row.push(cellValue)
            row.push(reason)
          }
          else {
            row.push(...['', ''])
          }
        }
      }
      listRow.push(row)
    })
    return {
      merges,
      listRow,
    }
  }
  catch (error) {
    console.error(error)
    loggerHelper.logError(error, `Cannot export report : ${report.id}`)
  }
}

export const createSimpleExcel = async (steps: any, nbColumn: any, report: any) => {
  const { id: idReport } = report
  try {
    // Build csv
    const { merges, listRow } = await getSimpleExcelDefinition(steps, nbColumn, report)
    const csv = papaparse.unparse({ fields: [], data: listRow })

    const xls = papaparse.parse(csv)
    const fileName = `Report_${report.document_name}-${formatDate(
        report.update_date,
      )}_simple.xlsx`
    const data = utils.aoa_to_sheet(xls.data)
    data['!cols'] = fitToColumn(xls.data)
    data['!merges'] = merges
    const wb = utils.book_new()
    utils.book_append_sheet(wb, data, report.document_name.replace('/', '-').substring(0, 26))
    writeFile(wb, fileName)
  }
  catch (error) {
    loggerHelper.logError(error, `Cannot export report : ${idReport}`)
    console.error(error)
  }
}

export const createSimpleCsv = (steps: any, nbColumn: any, report: any) => {
  const { id: idReport } = report
  const containsStepMeasureType = checkIfMeasureType(steps)
  try {
    const listRow: any = []
    const reasons = settingsStore().settings.filter(setting => setting.type === SettingsType.reasons)

    const targetRow: any = [t('export.change_target')].concat(containsStepMeasureType ? ['', '', '', ''] : [])
    // build header
    const listHeader = ['id', t('export.name')].concat(containsStepMeasureType ? [t('export.goal'), t('export.min'), t('export.max')] : [])
    // add to header the values column
    let dynamicCols: any = []
    steps?.forEach((step) => {
      if (step.last_targets)
        dynamicCols = dynamicCols.concat(step.last_targets.map(e => e.init_col_id))
    })
    const updatesByColumnsCount = []
    for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
      const updatesByColumn = _.max(steps?.map(step => step?.historyAnswers?.[colIndex]?.length ?? 0)) ?? 0

      if (report.type === 'NIR')
        listHeader.push(`${t('export.value')} ${(colIndex + 1)}`)
      else
        listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)}`)

      if (dynamicCols.includes(colIndex))
        targetRow.push('X')
      else
        targetRow.push('')

      for (let i = 0; i < updatesByColumn; i++) {
        listHeader.push(`${getGridHeader(report.grid_header)}-${(colIndex + 1)} (${t('export.historic_value')}-${i + 1})`)
        listHeader.push(`${t('export.justification')}-${colIndex + 1} (${t('export.historic_value')}-${i + 1})`)
        targetRow.push(...['', ''])
      }
      updatesByColumnsCount.push(updatesByColumn)
    }
    listRow.push(targetRow)
    // build rows
    steps.forEach((step: JStep) => {
      const listSamplingAreas = step.last_sampling_areas // true means activated, false not activated so set 'N/A'
      // begin of row with only headers of row
      const goal = step.is_dynamic ? t('report.dynamic') : step?.goal || ''
      const min = step?.range?.[0] ? `${step?.range?.[0]}` : ''
      const max = step?.range?.[1] ? `${step?.range?.[1]}` : ''
      const row = [step.id, step.name].concat(containsStepMeasureType ? [goal, min, max] : [])
      // add to row the values column
      for (let colIndex = 0; colIndex < nbColumn; colIndex++) {
        const answer = step.answers.find(answer => answer.step_id === step.id && answer.col_id === colIndex)
        const cellValue = parseCellValueForCsv(
          answer?.value,
          step.type,
          listSamplingAreas?.[colIndex],
        )
        row.push(cellValue)
        const updatesByColumn = updatesByColumnsCount[colIndex]

        const cellHistory = step.historyAnswers?.[colIndex] ?? []
        for (let i = 0; i < updatesByColumn; i++) {
          const cell = cellHistory[i]
          if (cell) {
            const cellValue = parseCellValueForCsv(
              cell?.value,
              step.type,
              listSamplingAreas?.[colIndex],
            )
            let previousCellReason = {}
            if (i !== 0)
              previousCellReason = cellHistory[i - 1]?.reason

            else
              previousCellReason = answer.reason

            const previousCellReasonText = previousCellReason !== '' && previousCellReason ? reasons.find(reason => reason.id === previousCellReason)?.value : ''
            const reason = previousCellReasonText ?? previousCellReason
            row.push(cellValue)
            row.push(reason)
          }
          else {
            row.push(...['', ''])
          }
        }
      }
      listRow.push(row)
    })
    // Build csv
    const csv = papaparse.unparse({ fields: listHeader, data: listRow })

    const csvData = new Blob([`\uFEFF${csv}`], {
      type: 'text/csv;charset=UTF-8',
    })
    const fileName = `Report_${report.document_name}-${formatDate(
          report.update_date,
      )}.csv`
    return {
      content: csvData,
      fileName,
    }
  }
  catch (error) {
    loggerHelper.logError(`Cannot export report : ${idReport}`, error)
    console.error(error)
  }
}

export const exportSelectedReportsAsSimplePdf = async (reports: any) => {
  if (!reports || reports.length === 0)
    return

  const zip = new JSZip()
  for (let i = 0; i < reports.length; i++) {
    const report = reports[i]
    const pdfReport = await getSimpleReportPdf(report.id)
    const date = new Date()
    const fileName = `export_simple_Rapport_${report.id}_${report.document_name}_${formatDate(date)}.pdf`
    zip.file(fileName, new Blob([pdfReport], { type: 'application/pdf' }))
  }
  zip.generateAsync({ type: 'blob' })
    .then((blob) => {
      saveAs(blob, `Liste Reports ${formatShortDate(new Date())}`)
    })
}

export const exportSelectedReportsAsRichPdf = async (reports: any) => {
  if (!reports || reports.length === 0)
    return

  const zip = new JSZip()
  for (let i = 0; i < reports.length; i++) {
    const report = reports[i]
    const pdfReport = await getRichReportPdf(report.id)
    const date = new Date()
    const fileName = `export_riche_Rapport_${report.id}_${report.document_name}_${formatDate(date)}.pdf`
    zip.file(fileName, new Blob([pdfReport], { type: 'application/pdf' }))
  }
  zip.generateAsync({ type: 'blob' })
    .then((blob) => {
      saveAs(blob, `Liste Reports ${formatShortDate(new Date())}`)
    })
}

export const exportSelectedSimpleCsvAsZip = async (reportData: Array<any>) => {
  if (!reportData || reportData.length === 0)
    return
  // if (!reportData[0].css)
  //   return

  let reports = reportData.slice(0, 50).map(report => getFullReportById(report.id))

  reports = await Promise.all(reports)
  const zip = new JSZip()
  const sessions = await getAllSessions()
  reports.forEach((report) => {
    const session = sessions.find((p: any) => p.id === report.session_id)
    report.session_context = session?.session_context
    report = mapViewModelToGrid(report).report
    const { content, fileName } = createSimpleCsv(report.steps, report.gridSize, report)
    zip.file(fileName, content)
  })

  zip.generateAsync({ type: 'blob' })
    .then((blob) => {
      saveAs(blob, `Liste Reports ${formatShortDate(new Date())}`)
    })
}

export const exportSelectedRichCsvAsZip = async (reportData: Array<any>) => {
  if (!reportData || reportData.length === 0)
    return
  // if (!reportData[0].css)
  //   return

  let reports = reportData.slice(0, 50).map(report => getFullReportById(report.id))

  reports = await Promise.all(reports)
  const zip = new JSZip()
  const sessions = await getAllSessions()
  for (let report of reports) {
    const session = sessions.find((p: any) => p.id === report.session_id)
    report.session_context = session?.session_context
    report = mapViewModelToGrid(report).report
    report.grid_header = documentSettingsStore().documentSettings.find(setting => setting.id === report.grid_header)?.value
    const { content, fileName } = await createRichCsv(report.steps, report.gridSize, report)
    zip.file(fileName, content)
  }

  zip.generateAsync({ type: 'blob' })
    .then((blob) => {
      saveAs(blob, `Liste Reports ${formatShortDate(new Date())}`)
    })
}

export const exportSelectedRichXls = async (reportsData: Array<any>) => {
  try {
    if (!reportsData || reportsData.length === 0)
      return
    // if (!reportData[0].css)
    //   return

    const tables = [] as any[]
    const reports: any = await getAllReportsWithDetails(reportsData)

    for (const report of reports) {
      const { merges, listRow } = await getRichExcelDefinition(report.steps, report.gridSize, report)
      const csv = papaparse.unparse({ fields: [], data: listRow })
      const xls = papaparse.parse(csv)
      tables.push({ DataTable: xls.data, report, merges })
    }
    const wb = utils.book_new()
    tables.forEach((table) => {
      const data = utils.aoa_to_sheet(table.DataTable)
      data['!cols'] = fitToColumn(table.DataTable)
      data['!merges'] = table.merges
      const SheetName = table.report.document_name.replace('/', '-').substring(0, 26)
      if (wb.SheetNames.includes(SheetName))
        utils.book_append_sheet(wb, data, `${SheetName}_${wb.SheetNames.length + 1}`)
      else
        utils.book_append_sheet(wb, data, SheetName)
    })
    const fileName = `Export_Report_${formatDate(new Date())}.xlsx`
    writeFile(wb, fileName)
  }
  catch (error) {
    loggerHelper.logError(error, `Cannot export multiple excel : ${reportsData?.map(report => report.id)}`)
    console.error(error)
  }
}

const getAllReportsWithDetails = async (reportsList) => {
  let reports: any = reportsList
    .slice(0, 50)
    .map(report => getFullReportById(report.id))
  reports = await Promise.all(reports)
  const unitsSettings = documentSettingsStore().filterDocumentSettings(DocumentSettingsType.units_of_measure)
  const meansOfMeasureSettings = documentSettingsStore().filterDocumentSettings(DocumentSettingsType.means_of_measure)
  const gridHeaders = documentSettingsStore().filterDocumentSettings(DocumentSettingsType.grid_header)

  for (const currentReport of reports) {
    const { report, allAnswers } = mapViewModelToGrid(currentReport) // map to answers
    report.steps.forEach((step) => {
      unitsSettings?.map((p) => {
        if (step.unit === p.id) {
          step.unit = p.value
          return step.unit = p.value
        }
      })

      meansOfMeasureSettings?.map((p) => {
        if (step.means_of_measure === p.id) {
          step.means_of_measure = p.value
          return step.means_of_measure = p.value
        }
      })
      step.historyAnswers = {}
      const updatedColumns = [] as any[]
      step.answers.forEach((answer) => {
        const historyAnswer = allAnswers.filter(historyAnswer => historyAnswer.id !== answer.id && answer.col_id === historyAnswer.col_id && answer.row_id === historyAnswer.row_id)
        if (historyAnswer.length > 0) {
          step.historyAnswers[answer.col_id] = historyAnswer
          if (!updatedColumns.includes(answer.col_id))
            updatedColumns.push(answer.col_id)
        }
      })
      step.answers = step?.answers ?? []
      if (!step.answers.length)
        return
    })
    report.grid_header = gridHeaders.find(p => p.id === report.grid_header)?.value
  }

  return reports
}

export const exportSelectedSimpleXls = async (reportsData: Array<any>) => {
  try {
    if (!reportsData || reportsData.length === 0)
      return
    // if (!reportData[0].css)
    //   return

    const Tables: any = []
    const reports = await getAllReportsWithDetails(reportsData)
    for (const report of reports) {
      const { merges, listRow } = await getSimpleExcelDefinition(report.steps, report.gridSize, report)
      const csv = papaparse.unparse({ fields: [], data: listRow })
      const xls = papaparse.parse(csv)
      Tables.push({ DataTable: xls.data, report, merges })
    }
    const wb = utils.book_new()
    Tables.forEach((table) => {
      const data = utils.aoa_to_sheet(table.DataTable)
      data['!cols'] = fitToColumn(table.DataTable)
      data['!merges'] = table.merges
      const SheetName = table.report.document_name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(0, 26)
      if (wb.SheetNames.includes(SheetName))
        utils.book_append_sheet(wb, data, `${SheetName}_${wb.SheetNames.length + 1}`)
      else
        utils.book_append_sheet(wb, data, SheetName)
    })
    const fileName = `Export_Report_${formatDate(new Date())}.xlsx`
    writeFile(wb, fileName)
  }
  catch (error) {
    loggerHelper.logError(error, `Cannot export multiple excel : ${reportsData?.map(report => report.id)}`)
    console.error(error)
  }
}

export const getDocPreview = async (document, loadSteps = false) => {
  const docToPreview = _.cloneDeep(document)

  if (loadSteps) {
    if (docToPreview.steps?.length) {
      docToPreview.steps = await dbHelper.getAllDataFromCollectionFromIds(STEPS_COLLECTION_NAME, docToPreview.steps)
      docToPreview.steps = _.orderBy(docToPreview.steps, ['order'], ['asc'])
    }
  }
  const report = await createReportPreview(docToPreview)

  report.steps = report.steps.map(step => step.id)

  return { docToPreview, report }
}
