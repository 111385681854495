export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBWZt-opMD-IvBm0BW55p3rAcwTcAyfi0w',
  authDomain: 'juno-prod.firebaseapp.com',
  projectId: 'juno-prod',
  storageBucket: 'juno-prod.appspot.com',
  messagingSenderId: '58605707323',
  appId: '1:58605707323:web:8ac2554e6bebdd79853c1e',
  measurementId: 'G-FHS7RY86EZ',
}

export const BACKEND_URL = 'https://berry-api.gojuno.io'

export const LOGGER_CONFIG = {
  key: 'AIzaSyBYHEEy4pyoXf3ujJ0VuKdkzMtZX_bmJ3g',
  projectId: 'juno-prod',
  service: 'juno-prod',
  disabled: false,
}

export const reCAPTCHA_KEY = ''
// export const reCAPTCHA_KEY = '6Lc1NboiAAAAACvBnPVGqD5jmmO1M-r7nxxW_Fi1'
