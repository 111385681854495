import { Timestamp } from 'firebase/firestore'
import { getUsers } from '../settings'
import { isUndefinedOrNullOrEmpty, removeInstance } from './../../utils/object'
import { StepType, buildStep } from './../../models/documents/jStep'
import { JDocument } from '~/models/documents/jDocument'
import { DocumentStatus } from '~/common/models/status'
import DbHelper from '~/helpers/dbHelper'
import { DOCUMENTS_COLLECTION_NAME, DOCUMENT_SETTINGS_COLLECTION_NAME, STEPS_COLLECTION_NAME } from '~/config/storage'
import { usersStore } from '~/store/users'
import { documentSettingsStore } from '~/store/documentSettings'
import { settingsStore } from '~/store/settings'
import type { JStep } from '~/models/documents/jStep'
import { SettingsType } from '~/models/settings/settings'
import type { JRepetition } from '~/models/documents/documentSettings'
import { DocumentSettingsType } from '~/models/documents/documentSettings'
import { getFullTemplates } from '~/controllers/templates'
import { documentStore } from '~/store/document'
import loggerHelper from '~/helpers/LoggerHelper'
import { siteStore } from '~/store/site'
import { generateFirebaseLikeId } from '~/utils/formatter'
import ApiHelper from '~/helpers/ApiHelper'
import { CellMeasureValidation, ValidationType } from '~/services/steps/CellMeasureValidation'

const dbHelper = new DbHelper()

// Documents
export const areWriteStepsExists = (steps: JStep[]) => {
  return steps.filter(p => p.type === StepType.Boolean
    || p.type === StepType.Checkbox
    || p.type === StepType.Measure
    || p.type === StepType.Text
    || p.type === StepType.List
    || p.type === StepType.Number
    || p.type === StepType.Time
    || p.type === StepType.Photo)
}

const estimateDescriptionLength = (stepId) => {
  const RICH_EDITOR_HEADER_HEIGHT = 30
  const LINE_SIZE = 27 // line-size: 20px + margin bottom 7px
  const descriptionInputHeight = document.getElementById(`rich-text-editor-${stepId}`)?.clientHeight - RICH_EDITOR_HEADER_HEIGHT

  return Math.floor(descriptionInputHeight / LINE_SIZE)
}

export const createDocument = async (doc: JDocument, options?: any) => {
  loggerHelper.logInfo('conceptor document createDocument function')
  const document = new JDocument(doc)
  const currentUser = usersStore().user
  document.client_id = currentUser.client_id
  document.site_id = currentUser.site_id
  document.created_by = currentUser.id as string
  document.updated_by = currentUser.id as string
  document.creation_date = new Date().getTime()
  document.update_date = new Date().getTime()

  document.expiration_date = document.expiration_date ? new Date(document.expiration_date).getTime() : null
  document.starting_date = document.starting_date ? new Date(document.starting_date).getTime() : null

  // const writeStepExists = areWriteStepsExists(document.steps)
  // document.type = writeStepExists.length > 0 || document.steps.length === 0 ? DocumentType.read_write : DocumentType.readonly

  // Document is multiple if repetition exists
  document.is_multiple = document.steps.some((p: any) => p.repetitions && p.repetitions.length > 0)

  const stepIds = new Array<string>()
  const newSteps = new Array<JStep>()
  const templatesIds = new Array<string>()

  const listTypeOptions = documentSettingsStore().filterDocumentSettings(DocumentSettingsType.list_options)
  await Promise.all(document.steps.map(async (step: JStep) => {
    if (step.type === StepType.List) {
      const stepList = listTypeOptions.find((q: any) => q.id === step.list_data.list_id)
      if (stepList) {
        step.list_data.list_id = stepList.id
        step.list_data.list_type = stepList.list_type
      }
    }

    if (step.sourceStepId && !templatesIds.includes(step.templateId))
      templatesIds.push(step.templateId)

    if (step.description?.length)
      step.descriptionLineLength = estimateDescriptionLength(step.id)

    if (step.temporary)
      step = buildStep(step)
    step.files_attached = step.files_attached?.map(file => Object.assign({}, file))

    const createdStep = await createStep(step, undefined, options)

    newSteps.push(createdStep)
    stepIds.push(step.id)
  }))

  await updateBranching(newSteps)

  document.steps = stepIds
  document.templates = templatesIds
  const createdDocument = await dbHelper.addDataToCollection(DOCUMENTS_COLLECTION_NAME, removeInstance(document))
  return { id: createdDocument.id, steps: newSteps }
}

export const updateDocument = async (doc: JDocument) => {
  loggerHelper.logInfo('conceptor document updateDocument function')
  let document = JSON.parse(JSON.stringify(doc))
  document = new JDocument(document)
  const currentUser = usersStore().user
  document.client_id = currentUser.client_id
  document.site_id = currentUser.site_id
  document.updated_by = currentUser.id
  document.update_date = new Date().getTime()
  document.starting_date = document.starting_date ? Date.parse(document.starting_date) : null
  document.expiration_date = document.expiration_date ? Date.parse(document.expiration_date) : null

  // const writeStepExists = areWriteStepsExists(document.steps)
  // if (DocumentType.automatic !== document.type)
  // document.type = writeStepExists.length > 0 ? DocumentType.read_write : DocumentType.readonly
  // Document is multiple if repetition exists
  document.is_multiple = document.steps.some(p => p.repetitions && p.repetitions.length > 0)

  const stepIds = new Array<string>()
  const templatesIds = new Array<string>()

  const listTypeOptions = documentSettingsStore().filterDocumentSettings(DocumentSettingsType.list_options)

  await Promise.all(document.steps.map(async (step: any) => {
    const isStepTemporary = step.temporary

    if (step.type === StepType.Measure) {
      if (step.is_centered)
        step.range = [Number(step.goal) - Number(step.extent), Number(step.goal) + Number(step.extent)]
    }
    if (step.type === StepType.List) {
      const stepList = listTypeOptions.find((q: any) => q.id === step.list_data.list_id)
      if (stepList) {
        step.list_data.list_id = stepList.id
        step.list_data.list_type = stepList.list_type
      }
    }

    if (step.sourceStepId && !templatesIds.includes(step.templateId))
      templatesIds.push(step.templateId)

    if (step.description?.length)
      step.descriptionLineLength = estimateDescriptionLength(step.id)

    step = buildStep(step)

    step.files_attached = step.files_attached.map((file: any) => Object.assign({}, file))

    if (isStepTemporary)
      await createStep(step)
    else
      await setStep(step)
  }))

  const filledDocument = JSON.parse(JSON.stringify(document))

  document.steps = document.steps?.filter(element => element.id !== '')?.map(element => element.id)

  document.templates = templatesIds
  await dbHelper.updateDataToCollection(DOCUMENTS_COLLECTION_NAME, document.id, removeInstance(document))

  if (typeof filledDocument.expiration_date != 'undefined' && filledDocument.expiration_date)
    filledDocument.expiration_date = new Date(filledDocument.expiration_date).toISOString().split('T')[0]
  if (typeof filledDocument.starting_date != 'undefined' && filledDocument.starting_date)
    filledDocument.starting_date = new Date(filledDocument.starting_date).toISOString().split('T')[0]
  return filledDocument
}

export const updateDocumentStatus = async (documentId: string, status: DocumentStatus) => {
  try {
    const result = await dbHelper.updateDataToCollection(DOCUMENTS_COLLECTION_NAME, documentId, { status, update_date: Date.now() })
    loggerHelper.logInfo(`Document ${documentId} status has been updated : ${status}`)
    return result
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError('Error in updateDocumentStatus', e.message)
  }
}

const loadReferences = async (users, documents) => {
  const settings = settingsStore().filterSettings(SettingsType.document_category)
  documents.map((doc: JDocument) => {
    const creator = users.find(item => item.id === doc.created_by)
    const setting = settings.find(item => item.id === doc.category)
    const modifier = users.find(item => item.id === doc.updated_by)

    doc.created_by = `${creator?.first_name} ${creator?.last_name}`
    doc.updated_by = `${modifier?.first_name} ${modifier?.last_name}`
    doc.updated_by_id = modifier?.id
    doc.update_date = new Date(doc.update_date instanceof Timestamp ? doc.update_date.toDate() : doc.update_date)

    doc.category = setting?.value
    doc.categoryId = setting?.id
    doc.blocker = setting?.blocker
  })
}

interface DocumentOptions {
  perPage: number
  currentPage: number
  search: string
  [x: string]: any
}

interface DocumentResponse {
  items: JDocument[]
  total: number
}

export const getDocumentsWithSettings = async (options: Partial<DocumentOptions>): Promise<DocumentResponse> => {
  const [{ items, total }, users] = await Promise.all([
    await ApiHelper.getDocuments(options),
    getUsers(),
  ])

  await loadReferences(users, items)

  return { items, total }
}

export const getDocumentsFromGroup = async (groupOptions: Partial<DocumentOptions>) => {
  const documents = await ApiHelper.getDocuments(groupOptions)

  return documents
}

export const getVersionedDocuments = async (rootDocumentId: string): Promise<JDocument[]> => {
  const currentUser = usersStore().user
  const [documents, users] = await Promise.all([
    dbHelper.getAllDataFromCollectionWithAll(DOCUMENTS_COLLECTION_NAME, {
      where_constraints: [
        {
          field: 'client_id',
          compare: '==',
          value: currentUser.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: currentUser.site_id,
        },
        {
          field: 'status',
          compare: 'not-in',
          value: [DocumentStatus.deleted],
        },
        {
          field: 'root_version_document_id',
          compare: '==',
          value: rootDocumentId,
        },
      ],
    }),
    getUsers(),
  ])
  await loadReferences(users, documents)

  return documents
}

export const getDocuments = async (status: DocumentStatus | DocumentStatus[], onlyLastVersion = true): Promise<any[]> => {
  const currentUser = usersStore().user
  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'status',
    compare: 'in',
    value: Array.isArray(status) ? status : [status] as any,
  },
  ]
  if (onlyLastVersion) {
    where_constraints.push({
      field: 'next_version_document_id',
      compare: '==',
      value: '',
    })
  }

  return await dbHelper.getAllDataFromCollectionWithAll(DOCUMENTS_COLLECTION_NAME, { where_constraints })
}

export const getAutomaticDocuments = async (workplaceId: string) => {
  const currentUser = usersStore().user
  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'next_version_document_id',
    compare: '==',
    value: '',
  },
  {
    field: 'type',
    compare: '==',
    value: 'automatic',
  }]

  const documents = await dbHelper.getAllDataFromCollectionWithAll(DOCUMENTS_COLLECTION_NAME, { where_constraints })
  const filteredDocumentsOnWorkplace = documents
    .filter(e => e.applicationfields.some(e => [workplaceId, 'ALL_WORKPLACES'].includes(e.id)))
    .filter(e => e.status !== DocumentStatus.archived)

  for await (const doc of filteredDocumentsOnWorkplace) {
    const documentSteps = await dbHelper.getAllDataFromCollectionFromIds(STEPS_COLLECTION_NAME, doc?.steps)

    doc.steps = doc.steps.map(e => documentSteps.find(stepDoc => stepDoc.id === e))
    doc.device = await ApiHelper.getDeviceById(doc.device_id)
  }

  return filteredDocumentsOnWorkplace
}

export const getDocumentsFromIds = async (documentIds: any[]): Promise<any[]> => {
  return await dbHelper.getAllDataFromCollectionFromIds(DOCUMENTS_COLLECTION_NAME, documentIds)
}

export const getDocumentRef = async (idDocument: string): Promise<any> => {
  return await dbHelper.getDocFromCollection(DOCUMENTS_COLLECTION_NAME, idDocument)
}

export const subscribeToUpdateDocument = async (idDocument: string): Promise<any> => {
  documentStore().setDocument(new JDocument({}))

  return await dbHelper.getDocFromCollectionOnSnapshot(DOCUMENTS_COLLECTION_NAME, idDocument, async (doc: any) => {
    doc.steps = await dbHelper.getAllDataFromCollectionFromIds(STEPS_COLLECTION_NAME, doc.steps)
    doc.steps.map((p: any) => {
      p.tags = p.tags || []
      if (p.type === StepType.List)
        p.list_data = p.list_data || {}

      return p
    })
    if (doc.templates?.length > 0) {
      // assign template steps
      doc.templates = await getFullTemplates(doc.templates)

      // assign template attachments
      doc.attachment_ids = doc.attachment_ids.concat(
        doc.templates.flatMap((tmp: any) => tmp.attachment_ids).map((item: any) => {
          item.isTemplateAttachment = true
          return item
        }),
      )
    }
  })
}

export const getFullDocumentById = async (idDocument: string): Promise<any> => {
  documentStore().setDocument(new JDocument({}))
  let doc = await dbHelper.getDocFromCollection(DOCUMENTS_COLLECTION_NAME, idDocument)
  doc = Array.isArray(doc) ? doc[0] : doc
  doc.steps = await dbHelper.getAllDataFromCollectionFromIds(STEPS_COLLECTION_NAME, doc?.steps)
  doc?.steps.map((p: any) => {
    p.tags = p.tags || []
    if (p.type === StepType.List)
      p.list_data = p.list_data || {}

    return p
  })

  doc.id = idDocument

  if (doc.templates?.length > 0) {
    // assign template steps
    doc.templates = await getFullTemplates(doc.templates)

    // assign template attachments
    doc.attachment_ids = doc.attachment_ids.concat(
      doc.templates.flatMap(tmp => tmp.attachment_ids).map((item) => {
        item.isTemplateAttachment = true
        return item
      }),
    )
  }

  if (typeof doc.expiration_date != 'undefined' && doc.expiration_date)
    doc.expiration_date = new Date(doc.expiration_date).toISOString().split('T')[0]
  if (typeof doc.starting_date != 'undefined' && doc.starting_date)
    doc.starting_date = new Date(doc.starting_date).toISOString().split('T')[0]

  doc.steps.sort((a: any, b: any) => (a.num_step > b.num_step) ? 1 : -1)
  documentStore().setDocument(new JDocument(doc))
}

// Steps
export const createStep = async (step: JStep, documentId = '', options?) => {
  try {
    loggerHelper.logInfo('conceptor step createStep function')
    const currentUser = usersStore().user

    step.client_id = currentUser.client_id
    step.site_id = currentUser.site_id
    step.previous_version_step_id = step.id || ''
    step.document_id = documentId
    step.creation_date = new Date().getTime()
    step.temporary = false

    // If id is already generated through custom function, then create document with this id, else let firebase auto generate it
    const stepId = options?.newVersion || !step.id ? generateFirebaseLikeId() : step.id

    await dbHelper.setDataToCollection(STEPS_COLLECTION_NAME, stepId, removeInstance(step))

    step.id = stepId

    return removeInstance(step)
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError('Error in createStep', e.message)
  }
}

export const updateBranching = async (steps: JStep[]) => {
  for (const step of steps) {
    if (step.branching?.length && step.id) {
      step.branching.forEach((branching: any) => {
        branching.action_details.steps_to_display = branching.action_details?.steps_to_display?.map((stepId) => {
          return steps.find(s => s.previous_version_step_id === stepId)?.id || stepId
        })
      })
      await dbHelper?.updateDataToCollection(STEPS_COLLECTION_NAME, step.id, removeInstance(step))
    }
  }
}

export const updateStep = async (step: JStep) => {
  try {
    loggerHelper.logInfo('conceptor step updateStep function')
    const currentUser = usersStore().user
    step.client_id = currentUser.client_id
    step.site_id = currentUser.site_id
    step.update_date = new Date().getTime()

    await dbHelper.updateDataToCollection(STEPS_COLLECTION_NAME, step.id, removeInstance(step))
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError('Error in updateStep', e.message)
  }
}

export const setStep = async (step: JStep) => {
  try {
    loggerHelper.logInfo('conceptor step setStep function')
    const currentUser = usersStore().user
    step.client_id = currentUser.client_id
    step.site_id = currentUser.site_id
    step.update_date = new Date().getTime()

    await dbHelper.setDataToCollection(STEPS_COLLECTION_NAME, step.id, removeInstance(step))
  }
  catch (e) {
    console.error(e)
    loggerHelper.logError('Error in setStep', e.message)
  }
}

export const validateStepKo = (valCell: any, step: any, indexCol?: number, isNotLastColumn = false) => {
  if (isUndefinedOrNullOrEmpty(valCell))
    return isNotLastColumn
  return isStepTypeKo(valCell, step, indexCol)
}

export const validateMandatoryStepFilled = (valCell, isMandatory, isActivated) => {
  return isUndefinedOrNullOrEmpty(valCell) && isMandatory && isActivated
}

export const isStepTypeKo = (valCell: any, step: any, indexCol?: number) => {
  const decimals = siteStore().site?.flags?.decimals

  if ([StepType.Text, StepType.Time, StepType.List, StepType.Checkbox].includes(step.type))
    return false

  if (step.type === StepType.Boolean)
    return (valCell === false) || (valCell === 'false')

  if (step.type === StepType.Measure) {
    const options = { step, cellValue: valCell, decimals, indexCol }

    return new CellMeasureValidation(ValidationType.ERROR).validate(options)
  }
}

export const validateStepBorderLine = (valCell: any, step: any, tolerance: number | undefined, indexCol: number) => {
  if (step.type !== StepType.Measure || !valCell)
    return false

  const options = { step, cellValue: valCell, tolerance, indexCol }

  return new CellMeasureValidation(ValidationType.WARNING).validate(options)
}

// Repetitions
export const getRepetitions = async (): Promise<any[]> => {
  return documentSettingsStore().filterDocumentSettings(DocumentSettingsType.repetition)
}

export const getTags = async (): Promise<any[]> => {
  return documentSettingsStore().filterDocumentSettings(DocumentSettingsType.step_tag)
}

export const createRepetition = async (repetition: JRepetition): Promise<any> => {
  const currentUser = usersStore().user
  repetition.client_id = currentUser.client_id
  repetition.site_id = currentUser.site_id
  // TO DO refacto => no need to create then get
  repetition.deleted_at = repetition.deleted_at || null
  const newRepetition = await dbHelper.addDataToCollection(DOCUMENT_SETTINGS_COLLECTION_NAME, repetition)
  const newRepetitionCreated = await dbHelper.getDocFromCollection(DOCUMENT_SETTINGS_COLLECTION_NAME, newRepetition.id)
  return newRepetitionCreated
}

export const getDocumentAttachment = async (attachment_id: string) => {
  const file = await dbHelper.getDocFromCollection('files_attachment', attachment_id)
  return file
}

export const incrementVersion = () => {
  // Upgrade minor from 0 to 9 then uppgrade major
}

export const checkDuplicatesDocumentsByName = async (documentName: string): Promise<JDocument[]> => {
  const documents = await dbHelper.getAllDataFromCollectionWithAll(DOCUMENTS_COLLECTION_NAME, {
    where_constraints: [{
      field: 'name',
      compare: '==',
      value: documentName,
    }],
  })

  return documents
}
