<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const props = defineProps<{
  userIds: string[]
  hideFullName: boolean
  inDrawerHeader: boolean
  displayAmount: boolean
  isEnableModal: boolean
  isRelative: {
    type: boolean
    required: false
    default: true
  }
  isLastLine: {
    type: boolean
    required: false
    default: false
  }
}>()

const state = reactive({
  isRelative: props.isRelative !== undefined,
})
</script>

<template>
  <div class="cursor-pointer">
    <Popover :class="`h-6 ${state.isRelative && 'relative'}`">
      <PopoverButton class="text-primary inline-flex items-center space-x-2">
        <div
          class="flex -space-x-2 items-center overflow-hidden"
          data-cy="avatar-multiple-container"
        >
          <Avatar
            v-for="userId in userIds"
            :key="userId"
            class="inline-block"
            :user-id="userId"
            multiple
            :fullname="!hideFullName && userIds.length === 1"
          />
        </div>
        <span
          v-if="userIds.length > 1 && displayAmount"
          class="align-center text-sm"
        >
          {{ $t('global.people', { amount: userIds.length }) }}
        </span>
      </PopoverButton>
      <PopoverPanel
        v-if="userIds.length > 1 || props.isEnableModal"
        class="flex flex-col w-max z-10  border border-gray-600 bg-white rounded-lg select-none"
        :class="[props.isLastLine ? 'fixed' : `absolute ${props.isEnableModal && '-bottom-0.5 right-6'}`]"
        :style="{ bottom: props.isLastLine ? `108px` : '' }"
      >
        <Avatar
          v-for="userId in userIds"
          :key="userId"
          :user-id="userId"
          class="py-1 px-2 hover:bg-gray-100 hover:rounded-lg"
          multiple
          fullname
        />
      </PopoverPanel>
    </Popover>
  </div>
</template>
