<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { minutesToMilliseconds } from 'date-fns'
import localforage from 'localforage'
import { storeToRefs } from 'pinia'
import ViewsController from './controllers/views'
import type { JSite } from './models/sites'
import { retryUpdateInputData } from './controllers/reports/reportsController'
import { Icons } from './models/Icon'
import { getUserLanguage } from './helpers/locales'
import { VERSION } from '~/common'
import { loadUser, logout } from '~/controllers/authentication'
import { usersStore } from '~/store/users'
import { siteStore } from '~/store/site'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/store/api'
import loggerHelper from '~/helpers/LoggerHelper'
import startup from '~/helpers/Startup'
import { gS } from '~/store/global'
import apiHelper from '~/helpers/ApiHelper'
import { fb_auth } from '~/services/firebase'
import { OfflineHandler } from '~/services/offline'
import DBHelper from '~/helpers/dbHelper'

if (import.meta.env.VITE_ENV_TEST === 'true' || import.meta.env.VITE_ENV_TEST === true) {
  // @ts-expect-error: Hide/Show Crisp Chatbox
  $crisp.push(['do', 'chat:hide'])
}

const dbHelper = new DBHelper()
const route = useRoute()
const router = useRouter()
const api = apiStore().getApiClient
const { locale } = useI18n()
const user = computed(() => usersStore().user)

useHead({
  title: `Juno - ${VERSION}`,
  meta: [{ name: 'description', content: `Juno web app ${VERSION}` }],
})

const appData: any = reactive({
  loading: false,
  offline: false,
  showPopUp: false,
})

const state = reactive({
  maxActiveTime: 480 as number,
  currentSite: {} as JSite,
  // In order to use deconnect function only one time
  userIsDeconnected: 0,
})

// offline init
const { isOnline } = storeToRefs(gS())

watch(isOnline, (value) => {
  if (value)
    OfflineHandler.syncPostData()
})

OfflineHandler.syncPostData()

apiHelper.startPingingJunoServer()
apiHelper.retryFailedPost()
// end offline init

const checkVersion = async () => {
  const status = await api.getAppStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  // const currentTimestamp = addMinutes(new Date(), 4)
  //  && (status.releaseTimeStamp && currentTimestamp.getTime() > status.RELEASE_TIMESTAMP)
  if (frontVersion < serverVersion) {
    if (!status.forceReload)
      appData.showPopUp = true
    else
      await hotReload()
  }
}
const replayData = async () => {
  if (fb_auth.currentUser)
    await retryUpdateInputData()
}

const channel = new BroadcastChannel('logoutChannel')
channel.addEventListener('message', (event) => {
  if (event.data === 'logout')
    location.reload()
})

const logNetworkInfo = async () => {
  if (import.meta.env.DEV)
    return

  const connection: any = navigator?.connection

  const occurence_date = new Date()
  const userData = usersStore().user
  const logData: any = {
    type: connection?.effectiveType,
    downlinkype: `${connection?.downlink} Mb/s`,
    rtt: `${connection?.rtt} ms`,
    client_id: userData?.client_id ? userData?.client_id : 'anonymous',
    client_name: userData?.client_name ? userData?.client_name : 'anonymous',
    site_name: userData?.site_name ? userData?.site_name : 'anonymous',
    uid: userData?.id ? userData?.id : 'anonymous',
    first_name: userData?.first_name ? userData?.first_name : 'anonymous',
    last_name: userData?.last_name ? userData?.last_name : 'anonymous',
    online: navigator.onLine,
    timestamp: occurence_date.getTime(),
    date: occurence_date,
    sync_status: 'todo',
    version: VERSION,
  }
  const logs: any = await localforage.getItem('connection-logs')
  const logsDataList = logs?.data ? logs?.data : []
  logsDataList.push(logData)
  if (navigator.onLine === true) {
    if (logsDataList?.length) {
      for (const f of logsDataList) {
        if (f.sync_status === 'todo') {
          f.sync_status = 'synched'
          await dbHelper.addDataToCollection('connection_logs', f)
        }
      }
    }
    appData.offline = false
  }
  else {
    appData.offline = true
  }
  localforage.setItem('connection-logs', { data: logsDataList })
}

const init = async () => {
  loggerHelper.logEvent('INIT_APP')
  // eslint-disable-next-line no-console
  console.log('INIT_APP')

  // Firefox doesn't handle network API
  if ('connection' in navigator)
    navigator.connection.addEventListener('change', logNetworkInfo)

  if (typeof window !== 'undefined') {
    checkVersion()
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version every 10 min
    appData.interval_replay = setInterval(replayData, 1000 * 60 * 4) // ask for data to replay every 5 min
  }
  appData.loading = true
  await loadUser()
  const user = usersStore().user

  if (user?.isPasswordReset === false && user?.isPasswordReset !== null)
    router.push('/auth/force-reset-pass')

  if (user?.password_expired_at)
    router.push('/auth/reset-expired-password')

  if (user)
    await localforage.setItem('currentUser', JSON.stringify(user))

  if (user?.role_ids?.length) {
    await startup.bootstrap()

    state.currentSite = siteStore().site

    if (state.currentSite?.flags?.idle_delay)
      state.maxActiveTime = state.currentSite.flags.idle_delay as number
    ViewsController.setupView()

    if (!usersStore().currentUserHasConceptorAccess && !route.path.includes('/operator'))
      router.push('/operator')
  }

  appData.loading = false
}

let timeOut: ReturnType<typeof setTimeout>

const userActivityHandler = () => {
  timeOut = setTimeout(async () => {
    state.userIsDeconnected++
    if (state.userIsDeconnected === 1) {
      const currentUrl = window.location.href
      const userId = usersStore().user.id
      localforage.setItem(`latestVisitedPageUrl_${userId}`, currentUrl)
      localforage.setItem('disconnectedForNonActivite', 'true')
      await logout()
    }
  }, minutesToMilliseconds(state.maxActiveTime))
}

const globalListener = () => {
  state.maxActiveTime = siteStore().site.flags?.idle_delay as number ?? 480
  // if user is logged in
  if (usersStore().user.id?.length) {
    clearTimeout(timeOut)
    state.userIsDeconnected = 0
    userActivityHandler()
  }
}

init()

watch([user, navigator.language], async () => {
  locale.value = await getUserLanguage(user.value)
})
</script>

<template>
  <div
    v-if="!isOnline"
    class="bg-[#FEE4E3] p-4 text-center"
    role="alert"
  >
    <div class="inline-flex items-center space-x-2 font-base">
      <component
        :is="Icons.CLOUD_OFFLINE"
        class="text-red-600"
      />
      <div>{{ $t("global.you_are_offline") }} </div>
    </div>
  </div>

  <VersionPopUp v-model="appData.showPopUp" />

  <router-view
    v-if="!appData.loading"
    @click="globalListener"
    @input="globalListener"
    @mousemove="globalListener"
  />
  <div
    v-if="appData.loading"
    class="flex h-screen justify-center items-center"
  >
    <div
      style="border-top-color: transparent"
      class="w-12 h-12 border-4 border-blue-400 border-solid rounded-full animate-spin justify-center"
    />
  </div>
</template>

<style>
.readOnlyMode{
  pointer-events: none;
}

.read-only {
  pointer-events: none !important;
}
</style>
