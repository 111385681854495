import { defineStore } from 'pinia'
export const gS = defineStore({
  id: 'global',

  state: () => ({
    global: {
      sessionId: '',
      workplace_id: '',
      product_id: '',
    } as any,
    online: true,
  }),

  getters: {
    isOnline(state): boolean {
      return state.online && window.navigator.onLine
    },
    isOffline(state): boolean {
      return !state.online || !window.navigator.onLine
    },
    canUseCache(state): boolean {
      return this.isOffline && window.location.pathname.startsWith('/operator')
    },
  },

  actions: {
    setLineStatus(status) {
      this.online = status
    },
    setGlobal(obj: object) {
      this.global = Object.assign(this.global, obj)
    },
    resetGlobal() {
      this.global = {}
    },
  },
})

// pouvoir modifier les champs dans la table
// figer des colones de la table
// scroller en horizontal dans la table

// appliquer table partout
// appliquer select partout
