import * as _ from 'lodash'
import { addHours } from 'date-fns'
import { i18n } from '~/modules/i18n'
import type { JStep, StepBranching } from '~/models/documents/jStep'
import { StepType } from '~/models/documents/jStep'
import CellRendererCheckbox from '~/components/UI/CellRendererCheckbox.vue'
import CellRendererRadio from '~/components/UI/CellRendererRadio.vue'
import CellRendererSelect from '~/components/UI/CellRendererSelect.vue'
import CellRendererTime from '~/components/UI/CellRendererTime.vue'
import CellRendererString from '~/components/UI/CellRendererString.vue'
import CustomHeader from '~/components/UI/CustomHeader.vue'
import CellRendererID from '~/components/UI/CellRendererID.vue'
import CellRendererPhoto from '~/components/UI/CellRendererPhoto.vue'
import { RepetitionType } from '~/models/documents/documentSettings'
import { parseArrayObjectToKeyAndLabel } from '~/utils/collections'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import { usersStore } from '~/store/users'
import CellRendererNameDescriptionDetail from '~/components/UI/CellRendererNameDescriptionDetail.vue'
import NotificationController from '~/controllers/notifications'
import { NotificationType } from '~/models/notifications/jNotification'
import { lookupStore } from '~/store/lookups'
import NumericCellEditor from '~/components/Classes/NumericCellEditor'
import { ColumnState } from '~/utils/report'
import { isStepTypeKo, validateMandatoryStepFilled, validateStepKo } from '~/controllers/documents'
import { ReportInputDataViewModel } from '~/viewModels/reportAnswerViewModel'
import type { ContextScopeViewModel } from '~/viewModels/session/contextScopeViewModel'
import { NotificationMode, NotificationState } from '~/common/models/notification'
import type { AlertViewModel } from '~/common/models/alerts'
import TextAreaCellEditor from '~/components/Classes/TextAreaCellEditor'
import { ReportStatus, RoleGrid } from '~/models/documents/jReport'

const { t } = i18n.global

export const DISABLED_ROW_BACKGROUND_COLOR = { 'background-color': '#F4F4F4' }

export const isNewColumn = (steps: any[], indexCol: number) => {
  for (const step of steps) {
    if (!isUndefinedOrNullOrEmpty(step.answers?.[indexCol]?.value)) {
      if (step.type === StepType.Checkbox) {
        if (step.answers?.[indexCol]?.value !== false)
          return false
        else
          continue
      }
      return false
    }
  }
  return true
}

export const isNewCol = (steps: any[], indexCol: number) => {
  for (const step of steps) {
    const answer = step.answers.find(answer => answer?.col_id === indexCol)
    if (answer)
      return false
  }
  return true
}

export const isColFullNA = (steps: any[], indexCol: number) => {
  for (const step of steps) {
    if (step.last_sampling_areas[indexCol] === true)
      return false
  }
  return true
}

const hasColValidValues = (steps: any[], indexCol: number) => {
  let result = true
  let isValid = true
  for (const step of steps) {
    if (checkIsCellDisabled(step.col_ids_to_disable, indexCol, step.parentFrequency, step.disabled))
      continue
    const answer = step.answers?.find(answer => answer?.col_id === indexCol)
    if (answer)
      isValid = !isStepTypeKo(answer.value, step, indexCol)
    result = result && isValid
  }
  return result
}

export const areAllRowsFilled = (steps: any[], indexCol: number) => {
  let cellsToFill = 0
  let cellsFilled = 0

  steps?.forEach((step: JStep) => {
    if (step.is_mandatory && step.last_sampling_areas?.[indexCol] && !step.hidden) {
      if (checkIsCellDisabled(step.col_ids_to_disable, indexCol, step.parentFrequency, step.disabled))
        return
      cellsToFill++
    }
  })
  for (const step of steps) {
    if (step.is_mandatory && step.last_sampling_areas?.[indexCol]) {
      if (checkIsCellDisabled(step.col_ids_to_disable, indexCol, step.parentFrequency, step.disabled))
        continue
      const answer = step.answers?.find(answer => answer?.col_id === indexCol)
      if (answer !== undefined && answer.value !== null)
        cellsFilled++
    }
  }
  return cellsFilled === cellsToFill
}

export const checkIsCellDisabled = (colIdsToDisable: number[], colIndex: number, parentFrequency: number, isDisabled: boolean) => {
  const sortedIndexToDisable = _.sortBy(colIdsToDisable) || []
  const col = getClosestElementLessThanIndex(sortedIndexToDisable, colIndex)
  const frequency = Number(parentFrequency) || 1
  const isBeforeFirstEnabledIndex = colIndex < sortedIndexToDisable[0]
  const isDisabledIndexRange = colIndex >= frequency + col

  if (isBeforeFirstEnabledIndex || isDisabledIndexRange || isDisabled)
    return true

  return false
}

export const checkMandatoryCellsNotFilled = (report: any) => {
  const erroredCells = [] as Array<{ row_id: number; col_id: number }>
  const numberOfColumns = report.gridSize
  const steps = report.steps.filter(step => !step.hidden)

  for (const [rowIndex, step] of steps.entries()) {
    if (!step.is_mandatory)
      continue

    for (let i = 1; i <= numberOfColumns; i++) {
      const colIndex = i - 1
      if (!step.last_sampling_areas[colIndex])
        continue

      if (checkIsCellDisabled(step.col_ids_to_disable, colIndex, step.parentFrequency, step.disabled))
        continue

      const answer = step.answers?.find(answer => answer?.col_id === colIndex)

      if (validateMandatoryStepFilled(answer?.value, true, true))
        erroredCells.push({ row_id: step?.num_step - 1, col_id: colIndex })
    }
  }

  const grouped = _.groupBy(erroredCells, 'col_id')
  return Object.keys(grouped).map(col_id => ({
    col_id: Number.parseInt(col_id),
    rows: grouped[col_id].map(obj => obj.row_id),
  }))
}

export const getGridHeader = (providedHeader: string) => {
  return `${providedHeader || t('report.control')}`
}

export const getInsightGridHeader = (providedHeader: string) => {
  console.log(providedHeader)
  return Array.isArray(providedHeader) || !providedHeader
    ? t('report.valid_controls')
    : t('report.custom_valid_controls', { header: providedHeader })
}

export const getColumnState = (steps: any[], index: number, isCurrentColumn = true, maxIndexUsed: number, isHistory = false, role = RoleGrid.editor) => {
  const indexCol = index

  if (isNewCol(steps, indexCol)) {
    if ((indexCol < maxIndexUsed) && role !== RoleGrid.preview)
      return isColFullNA(steps, indexCol) ? ColumnState.ok : ColumnState.ko
    return ColumnState.new
  }
  if (areAllRowsFilled(steps, indexCol)) {
    if (hasColValidValues(steps, indexCol))
      return ColumnState.ok
    else
      return ColumnState.ko
  }
  else {
    return isCurrentColumn && hasColValidValues(steps, indexCol) && !isHistory ? ColumnState.inProgress : ColumnState.ko
  }
}

export const computeColumnsInsights = (allInputData: ReportInputDataViewModel[], indexCol: number, steps: JStep[], isLastColumn = false) => {
  let colValidCells = 0
  let colControlKo = 0
  let columnState = ColumnState.ok

  for (let indexRow = 0; indexRow < steps.length; indexRow++) {
    const step = steps[indexRow]
    const dataCell = _.findLast(allInputData, data => data.col_id === indexCol && data.row_id === indexRow)
    const isValid = !validateStepKo(dataCell?.value, steps[indexRow], indexCol, !isLastColumn)

    if (dataCell) {
      if (!isValid && !checkIsCellDisabled(step.col_ids_to_disable, indexCol, step.parentFrequency, step.disabled) && !step.hidden) {
        colControlKo++
        columnState = ColumnState.ko
      }
      else {
        colValidCells++
        if (columnState !== ColumnState.ko)
          columnState = ColumnState.ok
      }
    }
    else if ((isUndefinedOrNullOrEmpty(dataCell?.value) && isLastColumn) || step.hidden) {
      colValidCells++
      if (columnState !== ColumnState.ko)
        columnState = ColumnState.ok
    }
    else if (step?.last_sampling_areas && step?.last_sampling_areas[indexCol] && step?.is_mandatory) {
      colControlKo++
      columnState = ColumnState.ko
    }
    else if (!step?.last_sampling_areas) {
      colControlKo++
      columnState = ColumnState.ko
    }
  }
  return { columnState, colValidCells, colControlTotal: colControlKo + colValidCells }
}

export const computeStepsInsights = (allInputData: ReportInputDataViewModel[], maxIndexUsed: number, steps: JStep[]) => {
  let validSteps = steps.length

  for (let indexRow = 0; indexRow < steps.length; indexRow++) {
    let isStepKo = false
    for (let indexCol = 0; indexCol <= maxIndexUsed; indexCol++) {
      const dataCell = _.findLast(allInputData, data => data.col_id === indexCol && data.row_id === indexRow)
      const isValid = !validateStepKo(dataCell?.value, steps[indexRow], indexCol, true)

      if (dataCell && !isValid)
        isStepKo = true
      else if (steps[indexRow]?.last_sampling_areas && steps[indexRow].last_sampling_areas[indexCol] && !isValid && steps[indexRow].is_mandatory)
        isStepKo = true
      else if (!steps[indexRow]?.last_sampling_areas)
        isStepKo = true
    }
    isStepKo && validSteps--
  }
  return validSteps
}

export const getUpdatedData = (steps: any[], event: any, shiftColumnIndex: number, report_id: string, reason: string, isValid: boolean) => {
  const columnState = getColumnState(steps, event.colDef.index - shiftColumnIndex)
  const currentUserId = usersStore().user.id

  return new ReportInputDataViewModel({
    value: event.newValue,
    col_id: event.colDef.index - shiftColumnIndex,
    row_id: event.rowIndex,
    updated_by: currentUserId,
    update_date: new Date().getTime(),
    columnState,
    step_id: steps[event.rowIndex]?.id,
    type: steps[event.rowIndex]?.type,
    report_id,
    reason,
    is_valid: isValid,
  })
}

export const getSeparator = (locale: string, separatorType: string): string | undefined => {
  if (!locale)
    locale = 'fr'

  const numberWithGroupAndDecimalSeparator = 1000.1
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find(part => part.type === separatorType)?.value
}

export const handleTags = (allTags: any[], currentTags: any[]) => {
  return currentTags?.map((tagId: string) => allTags.find(e => e.id === tagId)?.value) || []
}

export const setDetailsByType = (step: any, samplingAreas: any[], shiftColumnIndex: number, updatedShiftIndex: number, listOptions: any[], computeSampling: boolean) => {
  let details: any

  if (step.type === StepType.Measure) {
    details = {
      goal: step.goal,
      unit: step.unit,
      extent: step.extent,
      range: step.range,
      means_of_measure: step.means_of_measure,
      samplingAreas,
      last_targets: step.last_targets ?? [],
      initialShiftIndex: shiftColumnIndex,
      startEditIndex: shiftColumnIndex,
      is_dynamic: step.is_dynamic,
    }
  }
  else if (step.type === StepType.Text) {
    details = {
      samplingAreas,
      initialShiftIndex: shiftColumnIndex,
      startEditIndex: shiftColumnIndex,
    }
  }
  else if (step.type === StepType.Time) {
    details = {
      date: step.date,
      samplingAreas,
      initialShiftIndex: shiftColumnIndex,
      startEditIndex: shiftColumnIndex,
    }
  }
  else if (step.type === StepType.Number || step.type === StepType.Photo) {
    details = {
      samplingAreas,
      initialShiftIndex: shiftColumnIndex,
      startEditIndex: shiftColumnIndex,
    }
  }
  else if (step.type === StepType.Boolean) {
    details = {
      means_of_measure: step.means_of_measure,
      is_not_applicable: step.is_not_applicable,
      samplingAreas,
      startEditIndex: shiftColumnIndex,
      initialShiftIndex: shiftColumnIndex,
    }
  }
  else if (step.type === StepType.Checkbox) {
    details = {
      is_activated: true,
      samplingAreas,
      startEditIndex: shiftColumnIndex,
      initialShiftIndex: shiftColumnIndex,
    }
  }
  else if (step.type === StepType.List) {
    const selectOptions = handleOptions(step.list_data.list_id, listOptions)
    details = {
      selectOptions,
      isMultiple: step.list_data.is_multiple,
      isCustomized: step.list_data.is_customized,
      samplingAreas,
      startEditIndex: shiftColumnIndex,
      initialShiftIndex: shiftColumnIndex,
    }
  }
  details.compute_sampling = computeSampling
  details.frequency = step.frequency
  details.minType = step.min_type
  details.maxType = step.max_type
  details.updatedShiftIndex = (!isUndefinedOrNullOrEmpty(updatedShiftIndex) && updatedShiftIndex !== 0) ? updatedShiftIndex : shiftColumnIndex
  return details
}

export const handleOptions = (listId: string, listOptions: any[]) => {
  const listValues = listOptions.find(p => p.id === listId)?.list_value.filter(e => e.deleted_at === null)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return parseArrayObjectToKeyAndLabel(listValues, 'option_value', 'option_key')
}

export const computeRowHeight = ({ tagsLength, descriptionLength, descriptionLineLength = 0, type, isHistory }: any) => {
  // temp fix descriptionLineLength === -2 ??
  if (descriptionLineLength < 0)
    descriptionLineLength = 0

  let height = isHistory ? 50 + (descriptionLength || descriptionLineLength ? 20 : 0) : 90

  if (type === StepType.Measure)
    height += 60

  if (type === StepType.List || type === StepType.Boolean)
    height += 30

  if (tagsLength > 0)
    height += 30

  /* Code to handle old descriptions */
  if (!descriptionLineLength && descriptionLength && type !== StepType.Photo) {
    height += 30

    if (descriptionLength > 120)
      height += 20
    if (descriptionLength > 260)
      height += 40
  }

  if (type === StepType.Photo)
    height += 100

  return height + descriptionLineLength * 27
}

export const computeTypeRepetitionInStep = (step: any) => {
  step.typeRepetition = []
  if (!step.repetitions)
    return
  for (const repetition of step.repetitions) {
    if (repetition.repetition_type === RepetitionType.sampling)
      step.typeRepetition.push(RepetitionType.sampling)
    if (repetition.repetition_type === RepetitionType.formula)
      step.typeRepetition.push(RepetitionType.formula)
    if (repetition.repetition_type === RepetitionType.event)
      step.typeRepetition.push(RepetitionType.event)
  }
}

// renderer outside of the function so it's initialized once
const renderers = [
  { type: StepType.Checkbox, renderer: CellRendererCheckbox },
  { type: StepType.Boolean, renderer: CellRendererRadio },
  { type: StepType.List, renderer: CellRendererSelect },
  { type: StepType.Time, renderer: CellRendererTime },
  { type: StepType.Photo, renderer: CellRendererPhoto },
]

export const getCellRendererSelector = (params: any) => {
  return {
    component: renderers.find(e => e.type === params.data.type)?.renderer || CellRendererString,
  }
}

export const addDefaultHeader = (isHistory: boolean, hasAttachements: boolean, answers?: any) => {
  // static columns
  const columnsDefinition = [] as any
  const fixedCellStyles = {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'overflow': 'visible',
  }
  columnsDefinition.push({
    headerName: '',
    headerClass: 'header',
    headerComponent: CustomHeader,
    answers,
    field: 'id',
    colId: 'id',
    index: 0,
    flex: 1,
    pinned: 'left',
    width: 50,
    height: 50,
    cellRenderer: CellRendererID,
    suppressMovable: true,
  })

  columnsDefinition.push({
    headerName: i18n.global.t('document.name'),
    headerClass: 'header',
    headerComponent: CustomHeader,
    headerComponentParams: {
      static: true,
    },
    field: 'name',
    colId: 'name',
    answers,
    index: hasAttachements ? 2 : 1,
    wrapText: true,
    flex: 1,
    pinned: 'left',
    width: 450,
    height: 50,
    cellStyle: {
      'border-right': '0 !important',
    },
    cellRenderer: CellRendererNameDescriptionDetail,
    suppressMovable: true,
  })

  return columnsDefinition
}

export const cellStyle = (pinned = false) => {
  const style = {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'height': '100%',
    'white-space': 'normal',
    'line-height': '18px',
    'overflow': 'visible',
  }

  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

export const disableCellStyle = (pinned = false, isConditionnal = false) => {
  const style = {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'height': '100%',
    'white-space': 'normal',
    'line-height': '18px',
    'overflow': 'visible',
    'background-color': '#F4F4F4',
  }

  isConditionnal ? style['background-color'] = '#DFE4EC' : style['background-color'] = '#F4F4F4'

  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

export const targetCellStyle = (pinned = false) => {
  const style = {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'height': '100%',
    'white-space': 'normal',
    'line-height': '18px',
    'overflow': 'visible',
    'background-color': '#E8EAFD',
  }
  if (pinned) {
    style['border-left'] = 'solid 1px'
    style['border-left-color'] = '#dde2eb'
  }

  return style
}

const chooseRepetition = (repetition: any): any => {
  const repetitionTypes = [
    RepetitionType.sampling,
    RepetitionType.formula,
  ]

  for (const repetitionType of repetitionTypes) {
    if (repetition.includes(repetitionType))
      return repetitionType
  }

  return repetition[0]
}

export const computeSamplingByType = (step: any, startingColumnIndex: number, endColumnIndex: number, context_scope: ContextScopeViewModel, trigger: string, tasks?: any) => {
  const { frequency, sample, typeRepetition, formulas } = step

  const lookupTables = lookupStore().getLookups
  const lookupTablesByType = _.groupBy(lookupTables, 'type')
  const repetition = chooseRepetition(typeRepetition)
  let finalSampling = [] as any
  let finalAqlTags = {} as any
  let result = {} as any
  switch (repetition) {
    case RepetitionType.formula:
      result = computeFormulaSampling(formulas, startingColumnIndex, endColumnIndex, lookupTablesByType, context_scope)
      if (result) {
        finalSampling = result.sampling
        finalAqlTags = result.aqlTags
      }

      break
    case RepetitionType.sampling:
      finalSampling = computeSampling(frequency, sample, startingColumnIndex, endColumnIndex)
      break
    default:
      finalSampling = computeNoRepetition(endColumnIndex)
      break
  }
  return { sampling: finalSampling, aqlTags: finalAqlTags }
}

export const computeFormulaSampling = (formulas: string[], startingColumnIndex: number, endColumnIndex: number, lookups: any, context_scope: ContextScopeViewModel) => {
  if (!formulas.length)
    return

  const allSampling = [] as any
  const allAqlTags = [] as any
  formulas.forEach((object: any) => {
    const escapedFormula = object?.formula?.replaceAll('/n', '\n')
    const additionalFilter = object.additionalFilter
    const samplingFunction = new Function('lookups', 'startingColumnIndex', 'endColumnIndex', 'context_scope', 'additionalFilter', escapedFormula)

    const resolved_sampling = samplingFunction(lookups, startingColumnIndex, endColumnIndex, context_scope, additionalFilter)
    if (resolved_sampling.length) {
      allSampling.push(resolved_sampling)
    }
    else {
      // todo : run it once const { resolved_sampling, ...aqlTags } = samplingFunction(lookups, startingColumnIndex, endColumnIndex, context_scope, additionalFilter)
      const { sampling, ...aqlTags } = samplingFunction(lookups, startingColumnIndex, endColumnIndex, context_scope, additionalFilter)
      if (Object.keys(aqlTags).length)
        allAqlTags.push(aqlTags)

      allSampling.push(sampling)
    }
  })

  const maxSize = Math.max(...allSampling.map(arr => arr.length))
  const newsamplingArray = allSampling.map((arr) => {
    // If the current array is smaller than maxSize, complete it
    if (arr.length < maxSize)
      return [...arr, ...Array(maxSize - arr.length).fill(false)]

    // Otherwise, return the array as is
    return arr
  })
  const globalSampling = mergeSampling(newsamplingArray)
  return { sampling: globalSampling, aqlTags: allAqlTags[0] }
}

const mergeSampling = (sampling) => {
  return _.reduce(sampling, (mergedSampling, sampling) => {
    return _.map(mergedSampling, (element, index) => {
      return element || sampling[index]
    })
  })
}

export const computeSampling = (frequency: number, sample: number, startingColumnIndex: number, endColumnIndex: number): any[] => {
  const samplingResult: boolean[] = []

  for (let currentCol = startingColumnIndex; currentCol < endColumnIndex; currentCol++) {
    const rest = currentCol % frequency

    const isActivated = rest >= 0 && rest <= Number(sample) - 1
    samplingResult.push(isActivated)
  }
  return samplingResult
}

export const computeGlobalsampling = (allStepsSamplingAreas: any[]) => {
  const mergedSampling = []
  for (let i = 0; i < allStepsSamplingAreas.flat()?.[0]?.length; i++) {
    const columns = allStepsSamplingAreas.flat()?.map(t => t && t[i])
    mergedSampling.push(columns?.some(x => x === true))
  }
  return mergedSampling
}

export const computeNoRepetition = (endColumnIndex: number): any[] => {
  const samplingAreas = Array(Number(endColumnIndex))

  samplingAreas.fill(false, 0, endColumnIndex)
  samplingAreas[0] = true

  return samplingAreas
}

export const getHeaderComponentParams = (steps: any[],
  colIndex: number,
  previousLastColumnIndex: number,
  currentLastColumnIndex: number,
  gridHeader: string,
  docTrigger: string,
  displayCheckboxes = false,
  maxIndexUsed: number,
  isHistory = false,
  alerts: AlertViewModel[] = [],
  role = RoleGrid.editor) => {
  const currentColumnState = getColumnState(steps, colIndex, currentLastColumnIndex === colIndex, maxIndexUsed, isHistory, role)
  const currentColAlerts = alerts.filter(documentAlert => documentAlert.erroredSteps.some(erroredStep => erroredStep.answer.colId === colIndex))
  return {
    name: getColumnId(colIndex, gridHeader),
    lastUpdateOnColumn: getLastActionUpdateOnColumn(steps, colIndex),
    columnState: currentColumnState,
    docTrigger,
    lastColumnIndex: previousLastColumnIndex,
    colIndex,
    displayCheckboxes,
    alerts: currentColAlerts,
    alert: currentColAlerts[0],
  }
}

export const getColumnId = (index: number, gridHeader: string) => {
  const newGridHeader = Array.isArray(gridHeader) ? gridHeader[index % (gridHeader.length)] : gridHeader
  const colId = Array.isArray(gridHeader) ? (Math.floor(index / gridHeader.length) + 1) : index + 1

  return `${newGridHeader || t('report.control')} ${colId}`
}

export const getLastActionUpdateOnColumn = (steps: any[], colIndex: number) => {
  const stepAnswers = steps
    .map((step: JStep) => {
      return step.answers
    }).map((answers: any) => {
      for (const answer of answers) {
        if (answer?.col_id === colIndex)
          return answer
      }
    })
    .filter(Boolean)

  const recentAnswers = _.orderBy(stepAnswers, 'update_date', 'desc')
  return { ...recentAnswers[0] }
}

export const getLastActionUpdateOnGrid = (steps: any[]) => {
  const stepAnswers = steps
    .map((step: JStep) => {
      return step.answers.sort((a, b) => b.update_date - a.update_date)[0]
    })
    .filter(Boolean)

  const recentAnswers = stepAnswers.sort((a, b) => b.update_date - a.update_date)
  return { ...recentAnswers[0] }
}

export const isEditable = (params: any, columnIndexOffShift: number, userId: string, allInputData: any[], currentDocument: any, role: string, currentReport: any, disableCustomComponent: boolean, hasNoReportPermission: boolean) => {
  let editable = true
  const invalidType = ![StepType.Measure, StepType.Text, StepType.Number].includes(params.data.type) && disableCustomComponent

  if (params.data.isDisabled)
    return false

  if (role === RoleGrid.preview)
    return !invalidType

  const lastCellAnswer = allInputData?.find(e => e.row_id === params?.rowIndex && e.col_id === columnIndexOffShift)
  const isMyCell = (!lastCellAnswer || lastCellAnswer?.updated_by === userId)
  const isExpired = lastCellAnswer && addHours(new Date(lastCellAnswer?.update_date), 10) < new Date()

  const isHistory = params?.data?.isHistory

  const colIndex = params.colDef.index - params.data?.shiftIndex

  if (!isMyCell
    || isExpired
    || hasNoReportPermission
    || isHistory
    || invalidType
    || currentReport.status >= ReportStatus.finished)
    editable = false

  if (checkIsCellDisabled(params.data?.colIdsToDisable, colIndex, params?.data?.parentFrequency, params?.data?.isDisabled))
    return false

  return editable
}

export const getClosestElementLessThanIndex = (array, index) => {
  const closestElements = _.filter(array, element => element <= index) || array[0]
  return _.max(closestElements)
}

export const getCellStyle = (params: any, isHistory: boolean, columnIndexOffShift: number, highlightedCols: number[], pinnedColumnIndex = null as number | null) => {
  const isDynamiqueCol = highlightedCols?.find(e => e === columnIndexOffShift) !== undefined
  let style: any
  let editable = true
  const colIndex = params.colDef.index - params.data?.shiftIndex

  editable = params?.colDef?.editable(params, false)
  let isPinned = false
  if (pinnedColumnIndex)
    isPinned = params.colDef?.index === pinnedColumnIndex
  if (checkIsCellDisabled(params.data?.colIdsToDisable, colIndex, params?.data?.parentFrequency, params?.data?.isDisabled))
    style = disableCellStyle(isPinned, true)
  else if (isHistory)
    style = isDynamiqueCol ? targetCellStyle(isPinned) : cellStyle(isPinned)
  else if (isDynamiqueCol)
    style = targetCellStyle(isPinned)
  else
    style = editable ? cellStyle(isPinned) : disableCellStyle(isPinned)

  return style
}

export const getCellEditorSelector = (params: any, isHistory: boolean) => {
  if (isHistory)
    return

  if ([StepType.Measure, StepType.Number].includes(params.data.type)) {
    return { component: NumericCellEditor }
  }
  else if (params.data.type === StepType.Text) {
    return {
      component: TextAreaCellEditor,
    }
  }
}

export const refreshSampling = (latestSamplingArea: any, frequency: number, sample: number, startingColumnIndex: number, endingColIndex: number, shiftIndex: number, gridSize = 0) => {
  if (latestSamplingArea) {
    if (!frequency && !sample) {
      latestSamplingArea[startingColumnIndex - shiftIndex] = true
      return latestSamplingArea
    }

    const newSamplingArea = computeSampling(frequency, sample, 0, endingColIndex - startingColumnIndex)
    const clonedSampling = _.cloneDeep(latestSamplingArea)

    const mergedSampling = _.take(clonedSampling, startingColumnIndex - shiftIndex)
    newSamplingArea.forEach(p => mergedSampling.length < gridSize && mergedSampling.push(p))
    return mergedSampling
  }
}

export const addSampling = (latestSamplingArea: any, frequency: number, sample: number, startingColumnIndex: number, endingColIndex: number, shiftColumnIndex: number) => {
  if (latestSamplingArea) {
    const newSamplingArea = computeSampling(frequency, sample, startingColumnIndex - shiftColumnIndex, endingColIndex - shiftColumnIndex)
    const mergedSampling = _.cloneDeep(latestSamplingArea)

    newSamplingArea.forEach(p => mergedSampling.push(p))
    return mergedSampling
  }
}

export const setNotificationTime = (documentFrequency, index) => {
  const [hours, minutes] = documentFrequency.startingAt.split(':').map(Number)
  const startDateTime = new Date()

  startDateTime.setHours(hours, minutes, 0)

  let every = Number.parseInt(documentFrequency.every)

  if (documentFrequency.frequency?.type === 'day')
    every *= 3600
  if (documentFrequency.frequency?.type === 'hour')
    every *= 60

  const newDateTime = new Date(startDateTime.getTime() + (every * 60) * index * 1000)

  return newDateTime.getTime()
}

export const triggerNotification = async ({ document, report, reportPath, shiftIndex, nbColumns }) => {
  if (!document.frequency.enabled || !document.frequency.enableNotification)
    return

  await NotificationController.createNotification({
    id: '',
    path: reportPath,
    type: NotificationType.REMINDER,
    assigneeId: usersStore().user.id || '',
    assigneeIds: [],
    cells: Array.from(Array(nbColumns).keys()).map((index) => {
      return {
        date: setNotificationTime(document.frequency, index),
        cellInfo: {
          col: index,
          status: NotificationState.NEW,
        },
      }
    }),
    reportInfo: {
      id: report.id,
      name: document.name,
    },
    status: {
      mode: NotificationMode.IN_APP,
      state: NotificationState.NEW,
    },
  })
}

export const hideRows = (steps: JStep[], branchings: StepBranching[], rows: any[], data: any, gridApi: any, columnDef: any, colId: number, rowIndex: number, currentValueBranchings: StepBranching[], previousValueBranchings: StepBranching[]) => {
  const listAnswers = _.uniqBy(steps[rowIndex]?.answers, obj => `${obj.col_id}-${obj.row_id}`) || []
  const stepIdsToDisplay = branchings.flatMap(b => b.action_details?.steps_to_display)
  const branchingSteps = steps[rowIndex].branching?.flatMap(b => b?.action_details?.steps_to_display)

  const currentValueStepIds = currentValueBranchings.flatMap(b => b.action_details?.steps_to_display)
  const previousValueStepIds = previousValueBranchings.flatMap(b => b.action_details?.steps_to_display)

  const answeredStepIds = listAnswers?.flatMap((answer) => {
    return data?.branching?.find(e => e.trigger.conditions.some(condition => evaluateCondition(condition.operator, condition.value, answer.value)))?.action_details?.steps_to_display || []
  }) || []

  const stepIdsToHide = _.difference(branchingSteps, stepIdsToDisplay)
  const stepIdsIndexToRemove = _.difference(previousValueStepIds, currentValueStepIds)
  const stepsIndexToRemove = steps.filter(step => stepIdsIndexToRemove.includes(step.id))

  stepsIndexToRemove.forEach((step) => {
    if (step.col_ids_to_disable?.length) {
      step.col_ids_to_disable = step?.col_ids_to_disable.filter(s => s !== colId)
      step.disabled = !step.col_ids_to_disable?.length ? true : step.disabled
      const rowNode = gridApi.getRowNode((step.num_step - 1).toString() as string)
      if (rowNode) {
        rowNode.data.isDisabled = step.disabled
        rowNode.data.colIdsToDisable = step.colIdsToDisable
      }
    }
  })

  const stepsToHide = steps.filter(step => stepIdsToHide.includes(step.id))
  stepsToHide.forEach((step) => {
    step.hidden = true
    const rowNode = gridApi.getRowNode((step.num_step - 1).toString() as string)
    if (rowNode)
      rowNode.data.hidden = true
  })
  disableRows(stepsToHide, stepIdsToHide, answeredStepIds, columnDef, gridApi, colId, stepIdsToDisplay, Number(data.frequency), steps)
}

export const disableRows = (stepsToHide: JStep[], stepIdsToHide: string[], answeredStepIds: any[], columnDef: any, gridApi: any, colId: number, stepIdsToDisplay: any[], frequency: number, steps: JStep[]) => {
  const stepIdsToBeGrayed = stepsToHide.reduce((acc, e) => {
    if (e.answers.length) {
      _.pull(stepIdsToHide, e.id) // no need to hide this step anymore as we are going to disable it
      if (!answeredStepIds.includes(e.id)) {
        acc.push(e.id) // step to be greyed
        e.disabled = true
        e.hidden = false
        const rowNode = gridApi.getRowNode((e.num_step - 1).toString() as string)
        if (rowNode) {
          rowNode.data.hidden = false
          rowNode.data.isDisabled = true
        }
      }
    }
    return acc
  }, [] as any)

  const columnDefinitions = columnDef.map((e, i) => {
    e.disabledStepIds = stepIdsToBeGrayed
    return e
  })

  gridApi.setColumnDefs(columnDefinitions)
}

export const displayRows = (steps: JStep[], branchings: StepBranching[], rows: any[], allRows: any[], columnDef: any, gridApi: any, colId: number, currentValueBranchings: StepBranching[]) => {
  const stepsToDisplaySet = new Set(branchings.flatMap(b => b.action_details?.steps_to_display))
  const rowsToDisplay = allRows.filter(row => stepsToDisplaySet.has(row.stepId))
  const currentValueStepIds = currentValueBranchings.flatMap(b => b.action_details?.steps_to_display)

  rows = _.uniqBy(rows.concat(rowsToDisplay), 'id')
  steps.forEach((step) => {
    if (stepsToDisplaySet.has(step.id)) {
      const rowNode = gridApi.getRowNode((step.num_step - 1).toString() as string)
      if (currentValueBranchings.length && currentValueStepIds.includes(step.id)) {
        step.col_ids_to_disable = step.col_ids_to_disable ? _.uniq([...step.col_ids_to_disable, colId]) : [colId]
        if (rowNode) {
          rowNode.data.colIdsToDisable = step.col_ids_to_disable
          rowNode.data.isDisabled = false
        }
      }
      step.hidden = false
      step.disabled = false
      if (rowNode)
        rowNode.data.hidden = false
      columnDef.forEach((e, i) => {
        if (e.headerComponentParams)
          _.pull(e.disabledStepIds, step.id)
      })
    }
  })
  gridApi.setColumnDefs(columnDef)
  rows = _.orderBy(rows, 'id')
}

export const handleConditionalSteps = (data: any, allRows: any[], rowsDefinition: any[], colId: number, steps: JStep[], gridApi: any, columnDef: any[], role: RoleGrid, rowIndex: number, value: string) => {
  const rows = _.clone(rowsDefinition)
  let values = _.uniqBy(steps[rowIndex]?.answers, obj => `${obj.col_id}-${obj.row_id}`)

  values = values.flatMap(e => e.value)
  // find every branching matching the values we have in the step
  const branchings = data?.branching?.filter(e => e.trigger.conditions.some(condition => values.some(v => evaluateCondition(condition.operator, condition.value, v)))) || []

  const currentValueBranchings = data?.branching?.filter(e => e.trigger.conditions.some(condition => evaluateCondition(condition.operator, condition.value, value))) || []
  const previousCellValues = steps[rowIndex].answers?.filter(answer => answer.col_id === (colId))
  const previousCellValue = previousCellValues.length > 1 ? previousCellValues[1].value : []
  const previousCellValueBranching = data?.branching?.filter(e => e.trigger.conditions.some(condition => evaluateCondition(condition.operator, condition.value, previousCellValue))) || []

  if (data.type === StepType.List && data.branching) {
    displayRows(steps, branchings, rows, allRows, columnDef, gridApi, colId, currentValueBranchings)
    hideRows(steps, branchings, rows, data, gridApi, columnDef, colId, rowIndex, currentValueBranchings, previousCellValueBranching)
  }
}

export const evaluateCondition = (operator: string, conditionValue: string, value: string | string[]) => {
  switch (operator) {
    case '===':
      return Array.isArray(value) ? value.includes(conditionValue) : conditionValue === value
    case '>':
      return Array.isArray(value) ? value.some(v => v > conditionValue) : conditionValue > value
    case '<':
      return Array.isArray(value) ? value.some(v => v < conditionValue) : conditionValue < value
    // add other cases here as needed
    default:
      throw new Error(`Unsupported operator: ${operator}`)
  }
}
