import { defineStore } from 'pinia'

// Todo => Keep it for now as we don't use it for tasks anymore
export const tasksStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'tasks',
  // a function that returns a fresh state
  state: () => ({
    selectedColName: '',
    historyColId: { rowId: 0, colId: 0 },
    selectedColumnsIndex: [] as any,
  }),
  // optional getters
  getters: {
    getSelectedColumnsIndex(): any {
      return this.selectedColumnsIndex
    },
    getHistoryCellToDisplay(): any {
      return this.historyColId
    },
  },
  // optional actions
  actions: {
    async setSelectedColumns(colIndex: number) {
      if (colIndex === -1) {
        this.selectedColumnsIndex = []
        return
      }
      const index = this.selectedColumnsIndex.indexOf(colIndex)
      if (index > -1) {
        this.selectedColumnsIndex.splice(index, 1)
        return
      }
      this.selectedColumnsIndex = [colIndex]
    },
    setSelectedColumnName(colName: string) {
      this.selectedColName = colName
    },
    setHistoryCellToDisplay(historyCell: { rowId: number; colId: number }) {
      this.historyColId = historyCell
    },
  },
})
