<script setup lang="ts">
import { Icons } from '~/models/Icon'

const props = defineProps({
  modalShowing: {
    type: Boolean,
    required: false,
    default: false,
  },
  noMargin: {
    type: Boolean,
    required: false,
    default: false,
  },
  params: {
    type: Object,
    required: true,
    default: {} as any,
  },
  small: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const emit = defineEmits<{
  (e: 'isDrawerOpen', val): void
}>()

const state = reactive({
  isAttachmentVisible: false,
  isShowingDocument: false,
  modalAttachment: null,
})

const displayModalAttachment = () => {
  state.isShowingDocument = true
}

if (props.params?.data.file !== undefined)
  state.isAttachmentVisible = true

watch(
  () => state.isShowingDocument, (newVal) => {
    emit('isDrawerOpen', newVal)
  },
)
</script>

<template>
  <div
    data-cy="renderer-file"
    class="flex flex-col"
  >
    <!-- flex flex-col self-start mt-2 ml-2 -->
    <div
      v-if="state.isAttachmentVisible"
      v-can.any="['read-report', 'write-report', 'change-status-report']"
      :class="{
        'flex w-full h-full items-center': props.onGrid,
      }"
    >
      <div
        class="bg-secondary p-1 justify-center rounded cursor-pointer border border-primary"
        @click="displayModalAttachment"
      >
        <component
          :is="Icons.DOCUMENT_ATTACHMENT"
          :class="`${props.small ? 'h-4 w-4' : 'h-5 w-5'}`"
        />
      </div>
    </div>
  </div>
  <o-drawer
    v-if="!props.modalShowing"
    v-model="state.isShowingDocument"
    :title="props.params.data?.file?.name"
    :show-attachment="true"
    display-file
    @close="state.isShowingDocument = false"
  >
    <template #content>
      <file-reader
        :file-name="props.params.data?.file?.name"
        :file-url="props.params.data?.file?.path"
      />
    </template>
    <template #footer>
      <div class="flex justify-end p-6 ">
        <o-button
          data-cy="renderer-file-close-btn"
          :left-icon="{
            name: Icons.CLOSE,
          }"
          @click="state.isShowingDocument = false"
        >
          {{ $t('global.close') }}
        </o-button>
      </div>
    </template>
  </o-drawer>
  <o-modal
    v-if="props.modalShowing"
    :open="state.isShowingDocument"
    class="z-50"
    @close="state.isShowingDocument = false"
  >
    <template #content>
      <div
        class="w-auto"
        style="height: 80vh"
      >
        <file-reader
          :file-name="props.params?.data?.file?.name"
          :file-url="props.params?.data?.file?.path"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center">
        <button
          ref="cancelButtonRef"
          type="button"
          class="mt-3 w-2/5 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-white text-base font-medium sm:mt-0 sm:col-start-1 sm:text-lg"
          @click="state.isShowingDocument = false"
        >
          {{ $t('global.close') }}
        </button>
      </div>
    </template>
  </o-modal>
</template>
