<script setup lang="ts">
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { getLastUpdatedByOnCell, isMultipleAnswers, onHistoryClick } from '~/helpers/UtilsHelper'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const state = reactive({
  valString: props.params.value,
  isActivated: true,
  displayHistoryModal: true,
  colIndex:
    props.params.colDef.index - props.params?.data?.details?.initialShiftIndex,
})

const setAccurateSeparatorDecimal = () => {
  if (
    props.params.data.separatorDecimal === ','
    && state.valString
    && state.valString
  )
    state.valString = state.valString?.replace('.', ',')
}

const isTargetIconVisible = (colIndex: number) => {
  if (
    props.params?.colDef?.targetColIds?.find(e => e === colIndex)
      !== undefined
    && props.params.data?.details?.is_dynamic
    && state.valString
  )
    return true
  if (
    props.params.data?.details?.last_targets?.find(
      e => e.init_col_id === colIndex,
    ) !== undefined
    && props.params.data?.details?.is_dynamic
  )
    return true
  return false
}

state.isActivated = setValueOrNA(state.valString, props.params)
setAccurateSeparatorDecimal()
</script>

<template>
  <div
    v-if="!props.params.data.hidden"
    :data-cy-column="
      props.params.colDef.index - props.params?.data?.details?.initialShiftIndex
    "
    class="_data-cy-ag-grid-string"
    :class="[
      `_data-cy-column-${state.colIndex}`,
      `_data-cy-row-${props.params.rowIndex}`,
      `_data-cy-input-type-${props.params.data.type.toLowerCase()}`,
    ]"
  >
    <div
      v-if="state.isActivated"
      data-cy="string"
    >
      <div
        v-if="
          isTargetIconVisible(
            props.params.colDef.index
              - props.params?.data?.details?.initialShiftIndex,
          )
        "
        class="absolute top-1"
        :class="`${
          isMultipleAnswers(props.params)
          && props.params?.data?.isHistoryEnabled
            ? 'right-8'
            : 'right-1'
        }`"
      >
        <carbon:center-circle class="text-grey-500" />
      </div>
      <div
        v-if="isMultipleAnswers(props.params) && props.params?.data?.isHistoryEnabled"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click="onHistoryClick(props.params)"
      >
        <component
          :is="Icons.DATA_BACKUP"
          class="text-grey-500 w-5 h-5 cursor-pointer"
        />
      </div>
      <div
        v-if="props.params?.colDef?.answers?.some(a => a.row_id === props.params?.rowIndex)"
        v-can.any="['read-report', 'write-report']"
        class="absolute right-1 bottom-1"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          hide-full-name
          display-amount
          is-enable-modal
        />
      </div>
      <p v-html="state.valString?.replaceAll('\n', '<br />')" />
    </div>
    <div
      v-else
      class="bg-[#EEEEEE] text-[#161616] rounded px-4 py-1.5"
    >
      N/A
    </div>
  </div>
</template>
